import { PlusOutlined } from '@ant-design/icons';
import { useWavesurfer } from '@wavesurfer/react';
import { Button, Card } from 'antd';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FaPause, FaPlay } from 'react-icons/fa';
import AppLayout from '../layouts/app.layout';
import { colors } from '../theme/colors';

const dummy_voice_library = [
	{
		id: 1,
		tag: 'Educational Guru',
		name: 'Professor Lex',
		genera: 'British Male Accent',
		description: 'An artificial intelligence voice ready to assist with tech queries.',
		sample_recording: '/audio/recording.wav',
		tags: ['Podcasts', 'Documentaries'],
	},
	{
		id: 2,
		tag: 'Adventure Explorer',
		name: 'Captain Riley',
		genera: 'Pirate Accent',
		description: 'A daring voice full of seafaring tales and excitement.',
		sample_recording: '/audio/recording.wav',
		tags: ['Storytelling', 'Virtual Tours'],
	},
	{
		id: 3,
		tag: 'Tech Enthusiast',
		name: 'AI Companion',
		genera: 'Robotic Voice',
		description: 'An artificial intelligence voice ready to assist with tech queries.',
		sample_recording: '/audio/recording.wav',
		tags: ['Tech Demos', 'AI Assistants'],
	},
	{
		id: 4,
		tag: 'Historical Narrator',
		name: 'Lady Victoria',
		genera: 'British Female Accent',
		description: 'A refined voice transporting you to historical eras with elegance.',
		sample_recording: '/audio/recording.wav',
		tags: ['Historical Documentaries'],
	},
	{
		id: 5,
		tag: 'Sci-Fi Futurist',
		name: 'Dr. Nova',
		genera: 'Futuristic AI Voice',
		description: 'A voice from the future, guiding you through realms of science fiction.',
		sample_recording: '/audio/recording.wav',
		tags: ['Sci-Fi Films', 'Space Documentaries'],
	},
	{
		id: 6,
		tag: 'Artistic Muse',
		name: 'Isabella Song',
		genera: 'Soothing Female Voice',
		description: 'An enchanting voice to inspire creativity and calm the soul.',
		sample_recording: '/audio/recording.wav',
		tags: ['Relaxation Apps', 'Poetry Readings'],
	},
	{
		id: 7,
		tag: 'Sports Enthusiast',
		name: 'Coach Thunder',
		genera: 'Energetic Male Voice',
		description: 'A dynamic voice ready to motivate and lead you to victory!',
		sample_recording: '/audio/recording.wav',
		tags: ['Sports Podcasts', 'Motivational Speeches'],
	},
	{
		id: 8,
		tag: 'Mystery Detective',
		name: 'Inspector Noir',
		genera: 'Film Noir Style Voice',
		description: 'A voice straight out of a detective novel, uncovering mysteries in the shadows.',
		sample_recording: '/audio/recording.wav',
		tags: ['Crime Podcasts', 'Interactive Mystery Games'],
	},
];

type VoiceLibraryCardProps = {
	item: {
		id: number;
		tag: string;
		name: string;
		genera: string;
		description: string;
		sample_recording: string;
		tags: string[];
	};
	activeAudio: number | null;
	setActiveAudio: (id: number) => void;
};

const VoiceLibrary = () => {
	const [activeAudio, setActiveAudio] = useState<number | null>(null);
	return (
		<>
			<AppLayout
				title="Voice Library"
				subtitle="Discover and create generative voices for your projects."
			>
				<div className="h-[calc(100dvh-9rem)] overflow-hidden">
					<div className="h-full overflow-y-auto">
						<div className="flex items-center justify-end">
							<Button
								type="primary"
								icon={<PlusOutlined />}
							>
								Add Generative Voice
							</Button>
						</div>

						<h2 className="pl-1 pt-4 font-semibold text-gray-500">Weekly Top Voices</h2>
						<div
							className="grid gap-4 pt-2"
							style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))' }}
						>
							{dummy_voice_library.slice(0, 4).map((item, index) => (
								<VoiceLibraryCard
									key={index}
									item={item}
									activeAudio={activeAudio}
									setActiveAudio={setActiveAudio}
								/>
							))}
						</div>

						<h2 className="pl-1 pt-6 font-semibold text-gray-500">Discover Voices from the Community</h2>
						<div
							className="grid gap-4 pt-2"
							style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))' }}
						>
							{dummy_voice_library.reverse().map((item, index) => (
								<VoiceLibraryCard
									key={index}
									item={item}
									activeAudio={activeAudio}
									setActiveAudio={setActiveAudio}
								/>
							))}
						</div>
					</div>
				</div>
			</AppLayout>
		</>
	);
};

export default VoiceLibrary;

const VoiceLibraryCard: FC<VoiceLibraryCardProps> = ({ item, activeAudio, setActiveAudio }) => {
	const wavesurferRef = useRef(null);

	// wavesurfer audio player
	const { wavesurfer, isPlaying } = useWavesurfer({
		container: wavesurferRef,
		height: 50,
		waveColor: colors.primary,
		progressColor: '#0f172a',
		url: item.sample_recording,
	});

	// play/pause audio
	const onPlayPause = useCallback(async () => {
		if (wavesurfer) {
			await wavesurfer.playPause();
			setActiveAudio(item.id);
		}
	}, [item.id, setActiveAudio, wavesurfer]);

	// pause audio when another audio is played
	useEffect(() => {
		if (activeAudio !== item.id && wavesurfer) {
			wavesurfer.seekTo(0);
			wavesurfer.pause();
		}
	}, [activeAudio, item.id, wavesurfer]);

	return (
		<Card styles={{ body: { padding: 18 } }}>
			<div className="space-y-2">
				<div className="flex items-start justify-between">
					<div>
						<h2 className="text-xl font-medium">{item.name}</h2>
						<p className="font-medium text-gray-500">{item.genera}</p>
					</div>
					<div className="mt-2 rounded-full bg-pink-500 px-3 py-1.5 text-xs leading-none text-white">{item.tag}</div>
				</div>
			</div>
			<div className="flex items-center gap-4 pt-3">
				<div
					className={'flex size-8 items-center justify-center rounded-full bg-gray-400 p-2 text-white'}
					onClick={onPlayPause}
					role="button"
				>
					{isPlaying ? <FaPause size={15} /> : <FaPlay size={15} />}
				</div>
				<div
					ref={wavesurferRef}
					className="w-full"
				/>
			</div>
			<div className="pt-3">
				<div className="text-base text-gray-600">{item.description}</div>

				<div className="flex flex-wrap items-center gap-3 pt-2">
					{item.tags.map((tag, index) => (
						<div
							key={index}
							className="inline-block rounded-md text-xs font-medium"
						>
							{tag}
						</div>
					))}
				</div>
			</div>
		</Card>
	);
};
