import { FC } from 'react';

type FormLabelProps = {
	label: string;
	icon: JSX.Element;
};

const FormLabel: FC<FormLabelProps> = (props) => {
	return (
		<label
			htmlFor={props.label.toLowerCase()}
			className="flex items-center gap-2"
		>
			<div>{props.icon}</div>
			<div className="text-sm font-bold">{props.label}</div>
		</label>
	);
};

export default FormLabel;
