import { Card } from 'antd';
import { FC, useState } from 'react';
import toast from 'react-hot-toast';
import { FaRegCopy } from 'react-icons/fa6';
import { GoCheckCircleFill } from 'react-icons/go';
import { cn } from '../../config/cn';
import { ChatLog } from '../../views/chat-logs';

type Props = {
	history: ChatLog;
};

const ChatInformationCard: FC<Props> = ({ history }) => {
	const [copied, setCopied] = useState<boolean>(false);
	const [copiedId, setCopiedId] = useState<string | null>(null);

	return (
		<Card styles={{ body: { padding: 16 } }}>
			<h2 className="text-xl font-semibold text-black-7 dark:text-white">Chat Information</h2>

			<div className="pt-3" />

			<div className="space-y-3">
				{[
					{
						label: 'Chat ID',
						value: history.chat_id,
					},
					{
						label: 'Agent ID',
						value: history.assistant_id,
					},
					{
						label: 'Assistant Name',
						value: history.assistant_name,
					},
				].map((item, i) => (
					<div
						key={i}
						className="space-y-0.5"
					>
						<div className="text-base font-medium text-black-7 dark:text-white">{item.label}</div>
						<div className="flex items-center gap-2">
							<div
								className={cn('text-sm font-medium text-black-3', {
									capitalize: item.label === 'Assistant Name',
								})}
							>
								{item.value}
							</div>

							{['Chat ID', 'Agent ID'].includes(item.label as string) ? (
								<div
									onClick={() => {
										navigator.clipboard.writeText(item.value as string);
										setCopied(true);
										setCopiedId(item.value as string);
										toast.success('Copied to clipboard.');
									}}
									role="button"
								>
									<div className="text-sm text-gray-500">
										{copied && copiedId === item.value ? <GoCheckCircleFill color="green" /> : <FaRegCopy />}
									</div>
								</div>
							) : null}
						</div>
					</div>
				))}
			</div>
		</Card>
	);
};

export default ChatInformationCard;
