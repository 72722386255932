import { EventEmitter } from 'eventemitter3';
import WebSocket from 'isomorphic-ws';
import ErrorEvent = WebSocket.ErrorEvent;

const baseEndpoint = import.meta.env.PROD ? 'wss://core.puretalk.ai/web/call/' : 'wss://core.puretalk.ai/web/call/';
// const baseEndpoint = import.meta.env.PROD ? 'wss://puretalk-ai-agent-server-hllad.ondigitalocean.app/web/call/' : 'ws://localhost:3000/web/call/';

export interface AudioWsConfig {
	callId: string;
	enableUpdate?: boolean;
	customEndpoint?: string;
}

export class AudioWsClient extends EventEmitter {
	private ws: WebSocket;
	private pingTimeout: ReturnType<typeof setTimeout> | null = null;
	private pingInterval: ReturnType<typeof setInterval> | null = null;
	private wasDisconnected: boolean = false;
	private pingIntervalTime: number = 5000;
	private isAgentTalking: boolean = true;

	private recordingBuffer: Uint8Array = new Uint8Array();

	constructor(audioWsConfig: AudioWsConfig) {
		super();

		let endpoint = (audioWsConfig.customEndpoint || baseEndpoint) + audioWsConfig.callId;

		if (audioWsConfig.enableUpdate) {
			endpoint += '?enable_update=true';
		}

		this.ws = new WebSocket(endpoint);
		this.ws.binaryType = 'arraybuffer';

		this.ws.onopen = () => {
			this.emit('open');
			this.startPingPong();
		};

		this.ws.onmessage = (event: MessageEvent) => {
			if (typeof event.data === 'string') {
				if (event.data === 'pong') {
					if (this.wasDisconnected) {
						this.emit('reconnect');
						this.wasDisconnected = false;
					}
					this.adjustPingFrequency(5000); // Reset ping frequency to 5 seconds
				} else if (event.data === 'clear') {
					this.emit('clear');
				} else {
					// Handle json update data
					try {
						const eventData = JSON.parse(event.data);
						if (eventData.event_type === 'update') {
							this.emit('update', eventData);
						} else if (eventData.event_type === 'metadata') {
							this.emit('metadata', eventData);
						}
					} catch (err) {
						console.log(err);
					}
				}
			} else if (event.data instanceof ArrayBuffer) {
				// Handle binary data (ArrayBuffer)
				const audio = new Uint8Array(event.data);
				this.updateRecordingBuffer(audio);
				this.emit('audio', audio);
			} else {
				console.log('error', 'Got unknown message from server.');
			}
		};
		this.ws.onclose = (event: CloseEvent) => {
			this.stopPingPong();
			this.emit('close', event.code, event.reason);
			this.sendRecordingToServer(); // Send the recording when the call ends
		};
		this.ws.onerror = (event: ErrorEvent) => {
			this.stopPingPong();
			this.emit('error', event.error);
		};
	}

	startPingPong() {
		this.pingInterval = setInterval(() => this.sendPing(), this.pingIntervalTime);
		this.resetPingTimeout();
	}

	sendPing() {
		if (this.ws.readyState === WebSocket.OPEN) {
			this.ws.send('ping');
		}
	}

	adjustPingFrequency(newInterval: number) {
		if (this.pingIntervalTime !== newInterval) {
			if (this.pingInterval != null) {
				clearInterval(this.pingInterval);
			}
			this.pingIntervalTime = newInterval;
			this.startPingPong();
		}
	}

	resetPingTimeout() {
		if (this.pingTimeout != null) {
			clearTimeout(this.pingTimeout);
		}
		this.pingTimeout = setTimeout(() => {
			if (this.pingIntervalTime === 5000) {
				this.adjustPingFrequency(1000);
				this.pingTimeout = setTimeout(() => {
					this.emit('disconnect');
					this.wasDisconnected = true;
				}, 3000);
			}
		}, this.pingIntervalTime);
	}

	stopPingPong() {
		if (this.pingInterval != null) {
			clearInterval(this.pingInterval);
		}
		if (this.pingTimeout != null) {
			clearTimeout(this.pingTimeout);
		}
	}

	send(audio: Uint8Array) {
		if (this.ws.readyState === 1) {
			// this.updateRecordingBuffer(audio);
			// this.ws.send(audio);
			console.log(this.isAgentTalking);

			if (this.isAgentTalking) {
				// console.log('Agent is talking');
			} else {
				// console.log('Agent is not talking');
				this.updateRecordingBuffer(audio);
			}

			// Add a unique identifier for regular audio data
			const message = new Uint8Array(audio.length + 1);
			message[0] = 0x01; // Identifier for regular audio data
			message.set(audio, 1);

			this.ws.send(message);
		}
	}

	//todo: add explanations
	agentStartTalking() {
		this.isAgentTalking = true;
		if (this.ws.readyState === 1) {
			this.isAgentTalking = true;
			this.ws.send('agentStartTalking');
		}
	}

	//todo: add explanations
	agentStopTalking() {
		this.isAgentTalking = false;
		if (this.ws.readyState === 1) {
			console.log('Agent is not talking');
			this.isAgentTalking = false;
			this.ws.send('agentStopTalking');
		}
	}

	close() {
		console.log('Closing WebSocket: ', this.recordingBuffer);
		this.sendRecordingToServer();
		this.ws.close();
	}

	private updateRecordingBuffer(audio: Uint8Array) {
		const newBuffer = new Uint8Array(this.recordingBuffer.length + audio.length);
		newBuffer.set(this.recordingBuffer, 0);
		newBuffer.set(audio, this.recordingBuffer.length);
		this.recordingBuffer = newBuffer;
	}

	private sendRecordingToServer() {
		console.log('Sending recording to server');
		// Send the recording buffer to the server
		if (this.ws.readyState === WebSocket.OPEN) {
			// console.log('Sending recording to server: ', this.recordingBuffer);
			// this.ws.send(this.recordingBuffer);

			console.log('Sending recording to server: ', this.recordingBuffer);

			// Add a unique identifier for the recording buffer data
			const message = new Uint8Array(this.recordingBuffer.length + 1);
			message[0] = 0x02; // Identifier for recording buffer data
			message.set(this.recordingBuffer, 1);

			this.ws.send(message);
		}
	}
}

export function convertUint8ToFloat32(array: Uint8Array): Float32Array {
	const targetArray = new Float32Array(array.byteLength / 2);

	// A DataView is used to read our 16-bit little-endian samples out of the Uint8Array buffer
	const sourceDataView = new DataView(array.buffer);

	// Loop through, get values, and divide by 32,768
	for (let i = 0; i < targetArray.length; i++) {
		targetArray[i] = sourceDataView.getInt16(i * 2, true) / Math.pow(2, 16 - 1);
	}
	return targetArray;
}

export function convertFloat32ToUint8(array: Float32Array): Uint8Array {
	const buffer = new ArrayBuffer(array.length * 2);
	const view = new DataView(buffer);

	for (let i = 0; i < array.length; i++) {
		const value = (array[i] as number) * 32768;
		view.setInt16(i * 2, value, true); // true for little-endian
	}

	return new Uint8Array(buffer);
}
