import { Button, Card, Input, Space } from 'antd';
import { FC } from 'react';
import { Tool, useToolsStore } from '../../../store/tools';

type Props = {
	tool: Tool;
};

const EndCall: FC<Props> = ({ tool }) => {
	const { deleteTool, updateTool } = useToolsStore();

	return (
		<Card styles={{ body: { padding: 12 } }}>
			<div className="flex items-center justify-between gap-2">
				<h2 className="pb-2 text-lg font-bold text-black-7 dark:text-white">End Call</h2>

				<Space>
					<Button
						icon={
							<img
								src="/images/ai-assistant/delete-icon.svg"
								alt="delete-icon"
							/>
						}
						onClick={() => {
							deleteTool(tool.id);
						}}
					/>
				</Space>
			</div>

			<div>
				<div>
					<div className="pb-2 text-base font-medium text-black-7 dark:text-white">Name</div>

					<Input
						size="large"
						defaultValue={tool.function.name}
						onChange={(e) => {
							updateTool(tool.id, {
								...tool,
								function: {
									...tool.function,
									name: e.target.value,
								},
							});
						}}
						className="bg-input-bg dark:bg-[#1b1827]"
					/>
				</div>

				<div className="pt-5" />

				<div>
					<div className="pb-2 text-base font-medium text-black-7 dark:text-white">Description</div>

					<Input
						defaultValue={tool.function.description}
						size="large"
						onChange={(e) => {
							updateTool(tool.id, {
								...tool,
								function: {
									...tool.function,
									description: e.target.value,
								},
							});
						}}
						className="bg-input-bg dark:bg-[#1b1827]"
					/>
				</div>
			</div>
		</Card>
	);
};

export default EndCall;
