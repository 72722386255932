import { Button, Input } from 'antd';
import { FC, Fragment, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { MdOutlineCallEnd } from 'react-icons/md';
import axios from '../../config/axios.ts';
import { cn } from '../../config/cn.ts';
import { PuretalkWebClient } from '../../temp-puretalk-js-sdk';
import { AIAssistant } from '../../types/ai-assistant.types.ts';

const webClient = new PuretalkWebClient();

type WebCallProps = {
	assistantId: string;
	variables: AIAssistant['variables'];
	setStep: (step: number) => void;
};

export const WebCall: FC<WebCallProps> = (props) => {
	const [isCalling, setIsCalling] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [variables, setVariables] = useState<
		{
			var_name: string;
			var_value: string;
		}[]
	>([]);

	// Initialize the SDK
	useEffect(() => {
		// Setup event listeners
		webClient.on('conversationStarted', () => {
			console.log('conversationStarted');
		});

		webClient.on('conversationEnded', ({ code, reason }) => {
			console.log('Closed with code:', code, ', reason:', reason);
			setIsCalling(false); // Update button to "Start" when conversation ends
		});

		webClient.on('error', (error) => {
			console.error('An error occurred:', error);
			setIsCalling(false); // Update button to "Start" in case of error
		});

		webClient.on('update', (update) => {
			// Print live transcript as needed
			console.log('update', update);
		});
	}, []);

	const startCall = async () => {
		setLoading(true);

		try {
			// Get permission to use the microphone
			await navigator.mediaDevices.getUserMedia({ audio: true });

			// Start the conversation
			const response = await axios.post(
				'/calls/web/register',
				{
					assistant_id: props.assistantId,
					variables,
				},
				{ withCredentials: true },
			);

			await webClient.startConversation({
				callId: response.data.results.call_id,
				sampleRate: 16000,
			});

			setIsCalling(true); // Update button to "End" when conversation starts
		} catch (error) {
			if (error instanceof Error && error.name === 'NotAllowedError') {
				toast.error('Permission denied to use the microphone');
			} else {
				toast.error("Couldn't start the call. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
	};

	const endCall = () => {
		props.setStep(1);
		webClient.stopConversation();

		setIsCalling(false); // Update button to "Start" when conversation ends
	};

	return (
		<section>
			{props.variables.length > 0 && (
				<Fragment>
					<h2 className="text-lg font-semibold text-black-7 dark:text-white">Enter Variables</h2>

					<div className="pb-3 font-medium text-black-3">Add variables to personalize the call. These variables are optional.</div>

					<div className="flex flex-col gap-3">
						{props.variables.map((variable) => (
							<div
								key={variable}
								className="flex items-center gap-2"
							>
								<div className="w-max text-base font-semibold">{`{{${variable}}}`}</div>
								<Input
									placeholder="Enter variable value"
									onChange={(e) =>
										setVariables((prevVariables) => {
											const variables = prevVariables.filter((v) => v.var_name !== variable || e.target.value);
											const index = variables.findIndex((v) => v.var_name === variable);

											if (index === -1 && e.target.value) {
												variables.push({ var_name: variable, var_value: e.target.value });
											} else if (index !== -1) {
												variables[index].var_value = e.target.value;
											}

											return variables;
										})
									}
								/>
							</div>
						))}
					</div>
				</Fragment>
			)}
			<div
				className={cn('', {
					'flex h-40 items-center justify-center rounded-lg border dark:border-[#2d2b38]': props.variables.length === 0,
					'pt-5': props.variables.length > 0,
				})}
			>
				<Button
					size={'large'}
					type={'primary'}
					block={props.variables.length > 0}
					icon={
						isCalling ? (
							<MdOutlineCallEnd size={20} />
						) : (
							<img
								src="/images/phone-numbers/make-outbound-call.svg"
								alt="make-outbound-call"
							/>
						)
					}
					danger={isCalling}
					loading={loading}
					onClick={isCalling ? endCall : startCall}
					className="text-lg font-medium"
				>
					{isCalling ? 'End Web Call' : 'Start Web Call'}
				</Button>
			</div>
		</section>
	);
};
