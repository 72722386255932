import { Button, Modal } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, Fragment, useState } from 'react';
import { FaGlobe } from 'react-icons/fa6';
import { MdOutlineRadioButtonChecked } from 'react-icons/md';
import { SiTwilio } from 'react-icons/si';
import { Link } from 'react-router-dom';
import { AIAssistant } from '../../types/ai-assistant.types';
import MakeOutboundCall from '../outbound-call-modal/make-outbound-call';
import { WebCall } from '../web-call/web-call';

type Props = {
	open: boolean;
	close: () => void;
	assistant: AIAssistant;
};

const MakeCallModal: FC<Props> = (props) => {
	const [step, setStep] = useState<number>(1);
	const [selectedProtocol, setSelectedProtocol] = useState<'web' | 'voip'>('web');

	return (
		<Modal
			title={step === 1 ? 'Select Protocol' : 'Make a Call'}
			open={props.open}
			footer={null}
			onCancel={() => {
				if (step === 1) {
					props.close();
				} else {
					setStep(1);
				}
			}}
		>
			<div className="pt-2" />

			<AnimatePresence mode="wait">
				{step === 1 && (
					<motion.div
						key={step}
						initial={{ y: 10, opacity: 0 }}
						animate={{ y: 0, opacity: 1 }}
						exit={{ y: -10, opacity: 0 }}
						transition={{ duration: 0.2 }}
						className="flex w-full flex-col gap-3.5"
					>
						<div
							className="flex items-center justify-between rounded-lg border p-3 dark:border-[#2d2b38]"
							role="button"
							onClick={() => {
								setSelectedProtocol('web');
							}}
						>
							<div className="flex items-center gap-2">
								<FaGlobe size={20} />
								<div className="text-lg font-bold">Web</div>
							</div>

							{selectedProtocol === 'web' && <MdOutlineRadioButtonChecked size={20} />}
						</div>

						<div
							className="flex items-center justify-between rounded-lg border p-3 dark:border-[#2d2b38]"
							role="button"
							onClick={() => {
								setSelectedProtocol('voip');
							}}
						>
							<div className="flex items-center gap-2">
								<SiTwilio size={20} />
								<div className="text-lg font-bold">VOIP</div>
							</div>

							{selectedProtocol === 'voip' && <MdOutlineRadioButtonChecked size={20} />}
						</div>

						<Button
							type="primary"
							size="large"
							onClick={() => {
								setStep(2);
							}}
						>
							Next
						</Button>
					</motion.div>
				)}

				{step === 2 && selectedProtocol === 'web' && (
					<WebCall
						assistantId={props.assistant.assistant_id}
						variables={props.assistant.variables}
						setStep={setStep}
					/>
				)}

				{step === 2 && selectedProtocol === 'voip' && (
					<Fragment>
						{props.assistant.phone_number ? (
							<MakeOutboundCall
								from_number={props.assistant.phone_number}
								close={props.close}
								variables={props.assistant.variables}
							/>
						) : (
							<div className="flex h-40 items-center justify-center rounded-lg border px-4 dark:border-[#2d2b38]">
								<div className="text-base font-medium">
									You need to assign a phone number to this assistant first to make a call. Click{' '}
									<Link
										to="/phone-numbers"
										className="text-primary underline"
									>
										here
									</Link>{' '}
									to assign a phone number.
								</div>
							</div>
						)}
					</Fragment>
				)}
			</AnimatePresence>
		</Modal>
	);
};

export default MakeCallModal;
