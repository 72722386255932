import WavesurferPlayer from '@wavesurfer/react';
import { Progress } from 'antd';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useWindowSize } from 'react-use';
import axios from '../../config/axios.ts';
import { cn } from '../../config/cn.ts';
import dayjs from '../../config/dayjs.ts';
import { useThemeStore } from '../../store/theme.ts';
import { colors } from '../../theme/colors.ts';
import { CallLog } from '../../views/call-logs.tsx';

type Props = {
	history: CallLog;
	wavePercent: number;
	setWavePercent: Dispatch<SetStateAction<number>>;
};

const LogDetailsTop: FC<Props> = (props) => {
	const [wavesurfer, setWavesurfer] = useState<unknown>();
	const [isPlaying, setIsPlaying] = useState(false);
	const [downloading, setDownloading] = useState(false);
	const { width } = useWindowSize();
	const { is_dark_mode } = useThemeStore();

	// wavesurfer player
	const onReady = (ws: unknown) => {
		setWavesurfer(ws);
		setIsPlaying(false);
	};

	// play pause audio
	const onPlayPause = () => {
		if (wavesurfer) {
			(wavesurfer as { playPause: () => void }).playPause();
		}
	};

	const downloadFile = async (url: string) => {
		if (downloading) return;
		setDownloading(true);
		// Extract the last part of the URL after the last ':'
		const filename = (url.split(':').pop() || 'file') + '.mp3';

		const response = await axios.get(url, {
			responseType: 'blob', // Important
		});

		const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = urlBlob;
		link.setAttribute('download', filename);
		document.body.appendChild(link);
		link.click();
		link.remove();
		setDownloading(false);
	};

	return (
		<div className="flex flex-wrap items-center justify-between gap-4">
			<section>
				<h2 className="text-xl font-bold dark:text-white">
					{['twilio', 'telnyx'].includes(props.history.protocol) ? 'VOIP Call' : 'Web Call'}
				</h2>

				<div className="text-sm font-medium text-black-3">{dayjs(props.history.start_time).format('YYYY-MM-DD hh:mm')}</div>
			</section>

			<section>
				{props.history.call_recording_url && (
					<div className="flex flex-wrap items-center gap-4">
						<div
							className={cn({
								'pointer-events-none opacity-50': props.wavePercent < 100,
							})}
							onClick={onPlayPause}
							role="button"
							title={isPlaying ? 'Pause' : 'Play'}
						>
							{isPlaying ? (
								<img
									src="/images/call-logs/pause-icon.svg"
									alt="pause-icon"
								/>
							) : (
								<img
									src="/images/call-logs/play-icon.svg"
									alt="play-icon"
								/>
							)}
						</div>

						{props.wavePercent < 100 && (
							<Progress
								percent={props.wavePercent}
								style={{
									width: width < 768 ? 200 : 300,
									marginRight: -16,
								}}
								status="active"
								strokeColor={colors.primary}
							/>
						)}

						<WavesurferPlayer
							height={40}
							width={width < 768 ? 200 : 300}
							waveColor={colors.primary}
							url={props.history.call_recording_url}
							onReady={onReady}
							onPlay={() => setIsPlaying(true)}
							onPause={() => setIsPlaying(false)}
							progressColor={is_dark_mode ? '#fff' : '#0f172a'}
							barWidth={2}
							barGap={1}
							barRadius={2}
							onLoading={(_, percent) => {
								props.setWavePercent(percent);
							}}
						/>

						<a
							href={props.history.call_recording_url}
							onClick={async (e) => {
								e.preventDefault();
								await downloadFile(props.history.call_recording_url);
							}}
							aria-label="download"
							className={cn({
								'pointer-events-none opacity-50': downloading || props.wavePercent < 100,
							})}
						>
							{downloading ? (
								<div className="rounded-md border p-2">
									<AiOutlineLoading3Quarters
										className={'animate-spin text-primary'}
										size={22}
									/>
								</div>
							) : (
								<img
									src={is_dark_mode ? '/images/call-logs/download-icon-dark.svg' : '/images/call-logs/download-icon.svg'}
									alt="download-icon"
								/>
							)}
						</a>
					</div>
				)}
			</section>
		</div>
	);
};

export default LogDetailsTop;
