import { Drawer, Input } from 'antd';
import { FC, useState } from 'react';
import { useReactFlow } from 'reactflow';
import { useThemeStore } from '../../store/theme';
import { LlmStates } from '../../types/ai-assistant.types';

type CustomEdgeDrawerProps = {
	open: boolean;
	close: () => void;
	id: string;
	data: LlmStates;
	description: string;
	setDescription: (description: string) => void;
};

const CustomEdgeDrawer: FC<CustomEdgeDrawerProps> = (props) => {
	const { setNodes, setEdges } = useReactFlow();
	const { is_dark_mode } = useThemeStore();

	const [parameters, setParameters] = useState<unknown>(() => props.data.parameters);

	const handleDeleteEdge = () => {
		setEdges((edges) => edges.filter((edge) => edge.id !== props.id));
	};

	const examples = [
		{
			name: 'Example 1',
			data: {
				type: 'object',
				properties: {
					appointment_available_ts: {
						type: 'string',
						description: 'The timestamp of the appointment that is available for booking.',
					},
				},
				required: ['appointment_available_ts'],
			},
		},
		{
			name: 'Example 2',
			data: {
				type: 'object',
				properties: {
					city: {
						type: 'string',
						description: 'The city for which the weather is to be fetched.',
					},
				},
				required: ['city'],
			},
		},
		{
			name: 'Example 3',
			data: {
				type: 'object',
				properties: {
					appointment_available_ts: {
						type: 'string',
						description: 'The timestamp of the appointment that is available for booking.',
					},
					doctor_name: {
						type: 'string',
						description: 'An optional field to specify the name of the doctor.',
					},
				},
				required: ['appointment_available_ts'],
			},
		},
	];

	return (
		<Drawer
			title={
				<div className="flex items-center justify-between gap-3 px-1">
					<h3 className="text-lg font-medium">Edge</h3>

					<img
						src={is_dark_mode ? '/images/ai-assistant/delete-icon-dark.svg' : '/images/ai-assistant/delete-icon.svg'}
						alt="delete-icon"
						title="Delete Edge"
						onClick={() => {
							handleDeleteEdge();
						}}
						role="button"
					/>
				</div>
			}
			onClose={props.close}
			open={props.open}
			destroyOnClose
			closeIcon={
				<img
					src={
						is_dark_mode
							? '/images/ai-assistant/multi-task-tree-drawer-back-icon-dark.svg'
							: '/images/ai-assistant/multi-task-tree-drawer-back-icon-light.svg'
					}
					alt="close"
				/>
			}
			size="large"
		>
			<>
				<h2 className="text-base font-semibold">Path Condition</h2>

				<p className="pb-2 font-medium text-black-3">Describe the condition that triggers the transition to the next state</p>
				<Input
					value={props.description}
					onChange={(e) => {
						props.setDescription(e.target.value);

						setNodes((prev) => {
							const newNodes = prev.map((node) => {
								if (node.id === props.data.state_id) {
									return {
										...node,
										data: {
											...node.data,
											item: {
												...node.data.item,
												description: e.target.value,
											},
										},
									};
								}
								return node;
							});
							return newNodes;
						});
					}}
					size="large"
				/>

				<div className="pt-5" />

				<h2 className="text-base font-semibold">Store Call Info as Variables (Optional)</h2>

				<p className="pb-2 font-medium text-black-3">
					Convert information from the previous node into variables for use in future nodes. This should be the JSON schema that defines the
					format in which the LLM will return.
				</p>

				<Input.TextArea
					rows={10}
					value={parameters ? JSON.stringify(parameters, null, 2) : ''}
					size="large"
					className="bg-gray-800 text-white hover:bg-gray-800 focus:bg-gray-800"
					onChange={(e) => {
						try {
							const value = JSON.parse(e.target.value);
							setParameters(value);

							setNodes((prev) => {
								const newNodes = prev.map((node) => {
									if (node.id === props.data.state_id) {
										return {
											...node,
											data: {
												...node.data,
												item: {
													...node.data.item,
													parameters: value,
												},
											},
										};
									}
									return node;
								});
								return newNodes;
							});
						} catch (error) {
							console.error(error);
						}
					}}
					styles={{
						textarea: {
							scrollbarWidth: 'thin',
						},
					}}
				/>

				<div className="pt-4" />

				<div className="flex flex-wrap items-center gap-3">
					{examples.map((example, index) => (
						<div
							key={index}
							className="rounded-full bg-gray-200 px-3 py-1 dark:bg-gray-800"
							role="button"
							onClick={() => {
								setParameters(example.data);

								setNodes((prev) => {
									const newNodes = prev.map((node) => {
										if (node.id === props.data.state_id) {
											return {
												...node,
												data: {
													...node.data,
													item: {
														...node.data.item,
														parameters: example.data,
													},
												},
											};
										}
										return node;
									});
									return newNodes;
								});
							}}
						>
							<h3 className="text-sm font-semibold">{example.name}</h3>
						</div>
					))}
				</div>

				<div className="pt-3" />

				<div className="font-medium text-black-3">Changes will be saved automatically.</div>
			</>
		</Drawer>
	);
};

export default CustomEdgeDrawer;
