import { Button, Card, Modal } from 'antd';
import { FC, useState } from 'react';
import { GoPlus } from 'react-icons/go';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import { MdOutlineClose } from 'react-icons/md';
import { cn } from '../../config/cn';
import { AssistantType, Template, assistantTypes, templates } from './create-ai-assistant-data';
import CreateAIAssistantWidget from './create-ai-assistant-widget';
import { useThemeStore } from '../../store/theme';

type CreateAIAssistantModalProps = {
	open: boolean;
	close: () => void;
	refetch: () => void;
};

const CreateAIAssistantModal: FC<CreateAIAssistantModalProps> = (props) => {
	const { is_dark_mode } = useThemeStore();
	const [step, setStep] = useState(1);
	const [selectedTemplate, setSelectedTemplate] = useState<Template>(() => templates[0]);
	const [selectedAssistantType, setSelectedAssistantType] = useState<AssistantType>(() => assistantTypes[0]);

	const handleClose = () => {
		props.close();
		setStep(1);
	};

	return (
		<Modal
			open={props.open}
			onCancel={handleClose}
			onClose={handleClose}
			footer={null}
			centered
			width={800}
			destroyOnClose
			closable={false}
			className="relative"
		>
			{/* close icon */}
			<div className="absolute -right-1 -top-3 flex items-center justify-center rounded-full border bg-white p-1 shadow-lg dark:border-[#1f1f1f] dark:bg-dark-bg">
				<button onClick={handleClose}>
					<MdOutlineClose
						size={16}
						color="red"
					/>
				</button>
			</div>

			{/* step one UI */}
			{step === 1 && (
				<div>
					<div className="text-lg font-semibold">Select LLM of your assistant</div>

					<div className="pt-6" />

					<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
						{assistantTypes.map((item, index) => (
							<div
								key={index}
								onClick={() => {
									setSelectedAssistantType(item);
								}}
								role="button"
								className="relative rounded-lg border p-5 dark:border-[#322f3d]"
							>
								<div className="grid w-full place-items-center">
									<img
										alt={item.title}
										src={is_dark_mode ? item.imageDark : item.imageLight}
										// className='size-40'
									/>

									{selectedAssistantType && selectedAssistantType.value === item.value && (
										<div className="absolute right-2 top-2">
											<IoCheckmarkCircleSharp
												size={24}
												color="#db2777"
											/>
										</div>
									)}
								</div>

								<div className="pt-5" />

								<div className="flex items-center gap-2">
									<p className={cn('text-lg font-semibold dark:text-white')}>{item.title}</p>
									{item.extra && (
										<div className="flex items-center gap-1">
											<div className="rounded bg-purple-100 px-2 py-0.5 dark:bg-gray-800">
												<div className="text-xs font-medium text-gray-700 dark:text-white">{item.extra}</div>
											</div>
										</div>
									)}
								</div>

								<div className="pt-2" />

								<div className="font-medium text-gray-500 dark:text-[#8d8b93]">{item.description}</div>
							</div>
						))}
					</div>

					<div className="pt-6" />

					<Button
						size="large"
						type="primary"
						block
						onClick={() => {
							setStep(2);
						}}
					>
						Continue
					</Button>
				</div>
			)}

			{/* step 2 ui */}
			{step === 2 && (
				<div>
					<div className="flex flex-col gap-3">
						<div>
							<h2 className="text-lg font-semibold">Let's create a new assistant!</h2>
							<p className="text-gray-600 dark:text-gray-400">Here's a few templates to get you started: </p>
						</div>

						<div className="grid grid-cols-2 gap-4 lg:grid-cols-4">
							{templates.map((item, index) => (
								<Card
									key={index}
									hoverable
									styles={{ body: { padding: 10 } }}
									cover={
										<div className="relative">
											{item.avatar ? (
												<div className="relative">
													<img
														alt={item.name as string}
														src={item.avatar}
														className="h-40 w-full object-cover rounded-t-lg"
													/>
												</div>
											) : (
												<div className="h-40 w-full object-cover">
													<div className="flex h-full w-full items-center justify-center rounded-t-lg bg-gray-400 dark:bg-gray-800">
														<GoPlus
															size={30}
															color="white"
														/>
													</div>
												</div>
											)}

											{selectedTemplate && selectedTemplate.key === item.key && (
												<div className="absolute right-1 top-1">
													<IoCheckmarkCircleSharp
														size={24}
														color="#db2777"
													/>
												</div>
											)}
										</div>
									}
									onClick={() => {
										setSelectedTemplate(item);
									}}
								>
									<p className={cn('text-center font-medium')}>{item.name ?? 'Blank Template'}</p>
								</Card>
							))}
						</div>

						{selectedTemplate && <p className="text-base font-medium text-gray-600 dark:text-gray-300">{selectedTemplate.description}</p>}
					</div>

					<div className="flex w-full justify-end pt-3">
						<Button
							type="primary"
							className="px-8"
							onClick={() => {
								setStep(3);
							}}
						>
							Continue
						</Button>
					</div>
				</div>
			)}

			{/* step 3 ui */}

			{step === 3 && (
				<CreateAIAssistantWidget
					selectedTemplate={selectedTemplate}
					selectedAssistantType={selectedAssistantType}
					refetch={props.refetch}
					close={props.close}
				/>
			)}
		</Modal>
	);
};

export default CreateAIAssistantModal;
