import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Table } from 'antd';
import { Fragment, useState } from 'react';
import axios from '../../config/axios';
import { cn } from '../../config/cn';
import dayjs from '../../config/dayjs';
import Loader from '../Loader';

export type Invoice = {
	payment_method: PaymentMethod;
	amount: string;
	status: string;
	payment_intent_id: string;
	confirm_payment_intent_id: string;
	created_at: Date;
	updated_at: Date;
};

export type PaymentMethod = {
	id: string;
	object: string;
	allow_redisplay: string;
	billing_details: BillingDetails;
	card: Card;
	created: number;
	customer: string;
	livemode: boolean;
	metadata: Metadata;
	type: string;
};

export type BillingDetails = {
	address: Address;
	email: string;
	name: string;
	phone: null;
};

export type Address = {
	city: null;
	country: string;
	line1: null;
	line2: null;
	postal_code: null;
	state: null;
};

export type Card = {
	brand: string;
	checks: Checks;
	country: string;
	display_brand: string;
	exp_month: number;
	exp_year: number;
	fingerprint: string;
	funding: string;
	generated_from: null;
	last4: string;
	networks: Networks;
	three_d_secure_usage: ThreeDSecureUsage;
	wallet: null;
};

export type Checks = {
	address_line1_check: null;
	address_postal_code_check: null;
	cvc_check: string;
};

export type Networks = {
	available: string[];
	preferred: null;
};

export type ThreeDSecureUsage = {
	supported: boolean;
};

export type Metadata = object;

const BillingHistory = () => {
	const [page, setPage] = useState<number>(1);

	// get all billing history
	const { data, isLoading } = useQuery<{
		count: number;
		results: Invoice[];
	}>({
		queryKey: ['billing-history', page],
		queryFn: async () => {
			const { data } = await axios.get(`/billing/invoices`, {
				withCredentials: true,
				params: {
					page,
					limit: 10,
				},
			});
			return data;
		},
		placeholderData: keepPreviousData,
	});

	return (
		<Fragment>
			{isLoading && (
				<div className="flex h-[calc(100dvh-20rem)] items-center justify-center">
					<Loader />
				</div>
			)}

			{data && (
				<Table
					dataSource={data.results}
					bordered
					pagination={{
						current: page,
						onChange: (page) => setPage(page),
						total: data.count,
						pageSize: 10,
						showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`,
					}}
					size="middle"
					rowKey={(record) => record.payment_intent_id}
					title={() => <div className="text-xl font-bold">Invoices</div>}
					columns={[
						{
							title: 'Date',
							dataIndex: 'created_at',
							key: 'created_at',
							render: (created_at: Date) => <div className="font-medium">{dayjs(created_at).format('DD MMM YYYY HH:mm')}</div>,
						},
						{
							title: 'Payment Method',
							dataIndex: 'payment_method',
							key: 'payment_method',
							render: (payment_method: PaymentMethod) => (
								<div
									className={cn('font-medium', {
										uppercase: payment_method.type,
									})}
								>
									{payment_method.type ?? 'Pending Payment'}
								</div>
							),
						},
						{
							title: 'Card Type',
							dataIndex: 'payment_method',
							key: 'payment_method',
							render: (payment_method: PaymentMethod) => (
								<div
									className={cn('font-medium', {
										uppercase: payment_method.card?.display_brand,
									})}
								>
									{payment_method.card?.display_brand ?? 'N/A'}
								</div>
							),
							responsive: ['md'],
						},
						{
							title: 'Last 4 Digits',
							dataIndex: 'payment_method',
							key: 'payment_method',
							render: (payment_method: PaymentMethod) => (
								<div
									className={cn('font-medium', {
										uppercase: payment_method.card?.last4,
									})}
								>
									{payment_method.card?.last4 ?? 'N/A'}
								</div>
							),
							responsive: ['md'],
						},
						{
							title: 'Amount',
							dataIndex: 'amount',
							key: 'amount',
							render: (amount: string) => <div className="font-medium">${amount}</div>,
						},
						{
							title: 'Status',
							dataIndex: 'status',
							key: 'status',
							render: (status: string) => (
								<div
									className={cn('w-fit rounded-full border px-4 py-1 font-medium capitalize', {
										'border-green-300 bg-green-100 text-green-700': status === 'paid',
										'border-red-300 bg-red-100 text-red-700': status === 'failed',
										'border-orange-300 bg-orange-100 text-orange-700': status === 'pending',
									})}
								>
									{status}
								</div>
							),
						},
					]}
				/>
			)}
		</Fragment>
	);
};

export default BillingHistory;
