import { Badge, Card } from 'antd';
import { FC } from 'react';
import { CallLog } from '../../views/call-logs.tsx';
import { colorBasedOnSentiment } from './utils.tsx';

type Props = {
	history: CallLog;
};

const CallAnalysisCard: FC<Props> = ({ history }) => {
	return history.call_analysis ? (
		<Card styles={{ body: { padding: 16 } }}>
			<div className="flex items-center justify-between gap-4">
				<h2 className="text-xl font-semibold text-black-7 dark:text-white">Call Analysis</h2>

				<div className="flex items-center gap-2">
					<div className="text-base font-medium text-black-7 dark:text-white">Sentiment</div>

					<Badge
						color={colorBasedOnSentiment(history.sentiment as string)}
						className={'font-medium capitalize *:!text-black-3'}
						{...(history.sentiment === null
							? {
									status: 'processing',
									text: 'Generating',
								}
							: {
									text: history.sentiment as string,
								})}
					/>
				</div>
			</div>

			<div className="pt-3" />

			<div className="space-y-3">
				{/* <div className="space-y-0.5">
															<div className="text-base font-medium text-black-7 dark:text-white">Call Completion</div>

															<Badge
																color={getBadgeColor(history.call_analysis.call_completion)}
																text={history.call_analysis.call_completion ?? 'N/A'}
																className={'font-medium capitalize *:!text-black-3'}
															/>
														</div> */}

				{/* <div className="space-y-0.5">
															<div className="text-base font-medium text-black-7 dark:text-white">Task Completion</div>

															<Badge
																color={getBadgeColor(history.call_analysis.task_completion)}
																text={history.call_analysis.task_completion ?? 'N/A'}
																className={'font-medium capitalize *:!text-black-3'}
															/>
														</div> */}

				{[
					{
						label: 'Call Summary',
						value: history.call_analysis.summary ?? 'N/A',
					},
					// {
					// 	label: 'Call Completion Summary',
					// 	value: history.call_analysis.call_completion_summary ?? 'N/A',
					// },
					// {
					// 	label: 'Task Completion Summary',
					// 	value: history.call_analysis.task_completion_summary ?? 'N/A',
					// },
				].map((item, i) => (
					<div
						key={i}
						className="space-y-0.5"
					>
						<div className="text-base font-medium text-black-7 dark:text-white">{item.label}</div>
						<div className="text-sm font-medium text-black-3">{item.value}</div>
					</div>
				))}

				{history.call_analysis.post_analysis_data && (
					<div className="space-y-0.5">
						<div className="text-base font-medium text-black-7 dark:text-white">Post Call Analysis</div>

						{history.call_analysis.post_analysis_data.map((item, i) => (
							<div
								key={i}
								className="flex items-center gap-1"
							>
								<div className="mt-0.5 size-1.5 rounded-full bg-black-3" />
								<div className="text-sm font-medium text-black-3">{item.name}:</div>
								<div className="text-sm font-medium text-black-3">{item.value === true ? 'True' : item.value}</div>
							</div>
						))}
					</div>
				)}
			</div>
		</Card>
	) : null;
};

export default CallAnalysisCard;
