import Icon, { InfoCircleOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Drawer, Form, Input, Modal, Popover, Skeleton, Tooltip } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import { AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { IoMdClose } from 'react-icons/io';
import { IoSaveOutline } from 'react-icons/io5';
import { LuSend } from 'react-icons/lu';
import { RiEditLine } from 'react-icons/ri';
import { useClickAway, useWindowSize } from 'react-use';
import axios from '../../config/axios.ts';
import { cn } from '../../config/cn.ts';
import { AIAssistant } from '../../types/ai-assistant.types';

type Props = {
	open: boolean;
	close: () => void;
	assistant: AIAssistant;
};

type Payload = {
	assistant_id: string;
	name: string;
	chat_session: string;
};

type Session = {
	name: string;
	session_id: string;
	chat_session: string;
	created_at: Date;
	updated_at: Date;
};

type Message = {
	role: string;
	content: string;
};

const TestLLMDrawer: FC<Props> = (props) => {
	// destructuring assistant for some ease
	const { assistant } = props;

	const [modal, contextHolder] = Modal.useModal();
	const [send_data_form] = Form.useForm();
	const [saved, setSaved] = useState(false);
	const [selectedSession, setSelectedSession] = useState<Session | null>(null);
	const [assistantMessageStream, setAssistantMessageStream] = useState<string>('');
	const websocketRef = useRef<WebSocket | null>(null);
	const isInitialMount = useRef(true);
	const [socketStatus, setSocketStatus] = useState<'loading' | 'open' | 'closed'>('loading');
	const [messages, setMessages] = useState<Message[]>([]);
	const { width } = useWindowSize();
	const [chatSessionID, setChatSessionID] = useState<string | null>(null);
	const [sessionName, setSessionName] = useState<string>('New Conversation');
	const [editSessionName, setEditSessionName] = useState<boolean>(false);
	const [openSessions, setOpenSessions] = useState<boolean>(false);

	const name_ref = useRef(null);
	useClickAway(name_ref, () => {
		setEditSessionName(false);
	});

	//websocket
	useEffect(() => {
		if (!props.open) return;

		let messageStream = '';

		const openWebSocket = () => {
			const websocket = new WebSocket(
				import.meta.env.VITE_ENV === 'production'
					? `wss://core.puretalk.ai/chatbot/${assistant.assistant_id}${chatSessionID ? `?chat_session=${chatSessionID}` : ''}`
					: `wss://puretalk-ai-agent-server-hllad.ondigitalocean.app/chatbot/${assistant.assistant_id}${chatSessionID ? `?chat_session=${chatSessionID}` : ''}`,
			);

			// Handle WebSocket open event
			websocket.onopen = () => {
				setSocketStatus('open');
			};

			// Handle incoming messages
			websocket.onmessage = (event) => {
				const message = JSON.parse(event.data);

				if (message.event === 'chatSessionId') {
					setChatSessionID(message.data);
					return;
				}

				if (message.event === 'previousMessages') {
					// parse messages
					const messages = JSON.parse(message.data);

					// set initial message length
					initialMessageLength.current = messages.length;

					setMessages(messages);
					return;
				}

				if (message.data !== null) {
					setAssistantMessageStream((assistantMessageStream) => assistantMessageStream + message.data);
					messageStream += message.data;
				} else {
					const msg = messageStream;
					setMessages((prev) => [
						...prev,
						{
							role: 'assistant',
							content: msg,
						},
					]);
					setAssistantMessageStream('');
					messageStream = '';
				}
			};

			websocketRef.current = websocket;
		};

		// Clean up the WebSocket connection when the component unmounts or chatSessionID changes
		const cleanupWebSocket = () => {
			if (websocketRef.current && websocketRef.current.readyState !== WebSocket.CLOSED) {
				websocketRef.current.close();
				websocketRef.current = null;
				setSocketStatus('loading');
			}
		};

		// Skip cleanup on initial mount
		if (isInitialMount.current) {
			isInitialMount.current = false;
		} else {
			cleanupWebSocket();
		}

		openWebSocket();

		return cleanupWebSocket;
	}, [assistant.assistant_id, props.open, chatSessionID]);

	// send message to ws server
	const sendMessage = (msg: string) => {
		if (websocketRef.current) {
			websocketRef.current.send(msg);
		}
	};

	// set an initial message length and check if there are unsaved changes
	const initialMessageLength = useRef(messages.length);

	// scroll to bottom when messages change
	const messagesEndRef = useRef<HTMLDivElement | null>(null);
	useEffect(() => {
		setTimeout(() => {
			if (messagesEndRef.current) {
				messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
			}
		}, 0); // Timeout set to 0 will execute after any running script is finished
	}, [messages, props.open]);

	// get llm test sessions
	const {
		data: sessions,
		refetch: refetchSessions,
		isLoading,
	} = useQuery<Session[]>({
		queryKey: ['llm-test-sessions', assistant.assistant_id],
		enabled: props.open,
		queryFn: async () => {
			const { data } = await axios.get(`/ai-assistants/playground/llm/test-sessions`, {
				withCredentials: true,
				params: {
					assistant_id: assistant.assistant_id,
				},
			});

			// set first session as selected session
			if (data.results.length > 0) {
				setSelectedSession(data.results[0]);
				setChatSessionID(data.results[0].chat_session);
				setSessionName(data.results[0].name);
			}

			return data.results;
		},
	});

	// save llm test session mutation
	const { mutate: saveLLMTestSession, isPending: saveLLMTestSessionPending } = useMutation({
		mutationFn: async (payload: Payload) => {
			await axios.post(`/ai-assistants/playground/llm/test-sessions`, payload, { withCredentials: true });
		},
		onSuccess: async () => {
			await refetchSessions();
			setSaved(true);
			setTimeout(() => {
				setSaved(false);
			}, 2000);

			// 	update initial message length
			initialMessageLength.current = messages.length;
		},
	});

	// update llm test session mutation
	const { mutate: updateLLMTestSession, isPending: updateLLMTestSessionPending } = useMutation({
		mutationFn: async (payload: Payload) => {
			await axios.patch(`/ai-assistants/playground/llm/test-sessions/${selectedSession?.session_id}`, payload, { withCredentials: true });
		},
		onSuccess: async () => {
			await refetchSessions();
			setSaved(true);
			setTimeout(() => {
				setSaved(false);
			}, 2000);

			// 	update initial message length
			initialMessageLength.current = messages.length;
		},
	});

	// delete llm test session mutation
	const { mutate: deleteLLMTestSession } = useMutation({
		mutationFn: async () => {
			await axios.delete(`/ai-assistants/playground/llm/test-sessions/${selectedSession?.session_id}`, { withCredentials: true });
		},
		onSuccess: async () => {
			await refetchSessions();
			setSelectedSession(null);
			setChatSessionID(null);
			setOpenSessions(false);
			setSessionName('New Conversation');
			setEditSessionName(false);
			setMessages([]);
		},
	});

	// handle save or update
	const handleSaveOrUpdate = () => {
		if (selectedSession) {
			// update session
			updateLLMTestSession({
				assistant_id: assistant.assistant_id,
				name: selectedSession.name,
				chat_session: selectedSession.chat_session,
			});
		} else {
			// save new session
			saveLLMTestSession({
				assistant_id: assistant.assistant_id,
				name: sessionName,
				chat_session: chatSessionID as string,
			});
		}
	};

	// handle unsaved changes
	const handleUnsavedChanges = () => {
		if (messages.length > initialMessageLength.current) {
			return modal.confirm({
				title: 'Unsaved Changes',
				content: `You have changes that haven't been saved yet. If you close this conversation now, you'll lose those changes. Are you sure you want to proceed?`,
				onOk: async () => {
					props.close();

					// 	remove all unsaved messages
					setMessages(messages.slice(0, initialMessageLength.current));

					// reset all states
					setSelectedSession(null);
					setChatSessionID(null);
					setOpenSessions(false);
					setSessionName('New Conversation');
					setEditSessionName(false);
				},
				okButtonProps: {
					danger: true,
				},
				okText: 'Yes, close',
				centered: true,
			});
		}

		props.close();
	};

	// check if there are unsaved changes when switching sessions
	const handleSwitchSession = (session: Session) => {
		const hasUnsavedMessages = messages.length > initialMessageLength.current;
		const hasUnsavedNameChange = selectedSession && selectedSession.name !== sessionName;

		if (hasUnsavedMessages || hasUnsavedNameChange) {
			return modal.confirm({
				title: 'Unsaved Changes',
				content: `You have changes that haven't been saved yet. If you switch to another conversation now, you'll lose those changes. Are you sure you want to proceed?`,
				onOk: async () => {
					setSelectedSession(session);
					setChatSessionID(session.chat_session);
					setOpenSessions(false);
					setSessionName(session.name);
					setEditSessionName(false);
					setMessages([]);
				},
				okButtonProps: {
					danger: true,
				},
				okText: 'Yes, switch',
				centered: true,
			});
		}

		setSelectedSession(session);
		setChatSessionID(session.chat_session);
		setOpenSessions(false);
		setSessionName(session.name);
		setEditSessionName(false);
		setMessages([]);
	};

	return (
		<Drawer
			destroyOnClose
			onClose={handleUnsavedChanges}
			title={
				<div className={'flex flex-wrap items-center justify-between gap-3'}>
					{isLoading && <div>Loading...</div>}

					{!isLoading && sessions && sessions.length === 0 && (
						<div className="flex items-center gap-1">
							{editSessionName ? (
								<div ref={name_ref}>
									<Input
										defaultValue={sessionName}
										onChange={(e) => setSessionName(e.target.value)}
										onPressEnter={() => setEditSessionName(false)}
										autoFocus
										variant="filled"
										suffix={
											<Tooltip
												title="Please press enter or click outside to save"
												arrow={false}
											>
												<InfoCircleOutlined />
											</Tooltip>
										}
									/>
								</div>
							) : (
								<div className="flex items-center gap-2">
									<div>{sessionName}</div>
									<div
										role="button"
										onClick={() => setEditSessionName(true)}
									>
										<RiEditLine />
									</div>
								</div>
							)}
						</div>
					)}

					{!isLoading && sessions && sessions.length > 0 && (
						<>
							<div className="flex items-center gap-2">
								<Popover
									overlayInnerStyle={{ padding: 0, overflow: 'hidden' }}
									content={
										<>
											<div
												className="flex items-center gap-1 border-b px-4 py-2.5 hover:bg-gray-200 dark:border-dark-border dark:hover:bg-gray-800"
												role="button"
												onClick={() => {
													setSelectedSession(null);
													setChatSessionID(null);
													setOpenSessions(false);
													setSessionName('New Conversation');
													setEditSessionName(false);
													setMessages([]);
												}}
											>
												<div>
													<AiOutlinePlus />
												</div>
												<div>New Conversation</div>
											</div>
											<div className="flex flex-col">
												{sessions.map((session, index) => (
													<div
														key={index}
														className={cn('px-4 py-2.5 hover:bg-gray-200 dark:hover:bg-gray-800', {
															'border-b dark:border-dark-border': index !== sessions.length - 1,
														})}
														role="button"
														onClick={() => {
															handleSwitchSession(session);
														}}
													>
														<div>{session.name}</div>
													</div>
												))}
											</div>
										</>
									}
									trigger={['click']}
									arrow={false}
									placement="bottomLeft"
									open={openSessions}
									onOpenChange={(visible) => setOpenSessions(visible)}
								>
									<div role="button">
										<HiOutlineChevronDown />
									</div>
								</Popover>

								<div className="flex items-center gap-1">
									{editSessionName ? (
										<div ref={name_ref}>
											<Input
												defaultValue={sessionName}
												onChange={(e) => {
													if (selectedSession) {
														setSelectedSession({
															...selectedSession,
															name: e.target.value,
														});
													} else {
														setSessionName(e.target.value);
													}
												}}
												onPressEnter={() => setEditSessionName(false)}
												autoFocus
												variant="filled"
												suffix={
													<Tooltip
														title="Please press enter or click outside to save"
														arrow={false}
													>
														<InfoCircleOutlined />
													</Tooltip>
												}
											/>
										</div>
									) : (
										<>
											{selectedSession ? (
												<div className="flex items-center gap-2">
													<div>{selectedSession.name}</div>
													<div
														role="button"
														onClick={() => setEditSessionName(true)}
													>
														<RiEditLine />
													</div>
												</div>
											) : (
												<div className="flex items-center gap-2">
													<div>{sessionName}</div>
													<div
														role="button"
														onClick={() => setEditSessionName(true)}
													>
														<RiEditLine />
													</div>
												</div>
											)}
										</>
									)}
								</div>
							</div>
						</>
					)}

					<div className={'flex items-center gap-3'}>
						{saveLLMTestSessionPending || updateLLMTestSessionPending ? <div>Saving...</div> : saved ? <div>Saved</div> : null}

						{selectedSession ? (
							<button
								className="rounded-lg bg-gray-200 p-2 dark:bg-gray-800"
								onClick={() => {
									deleteLLMTestSession();
								}}
								title={'Delete'}
							>
								<AiOutlineDelete size={18} />
							</button>
						) : null}

						<button
							className="rounded-lg bg-gray-200 p-2 dark:bg-gray-800"
							onClick={handleSaveOrUpdate}
							title={'Save'}
						>
							<IoSaveOutline size={18} />
						</button>
						<button
							className="rounded-lg bg-gray-200 p-2 dark:bg-gray-800"
							onClick={handleUnsavedChanges}
						>
							<IoMdClose size={18} />
						</button>
					</div>
				</div>
			}
			open={props.open}
			width={width > 768 ? '50%' : '100%'}
			closable={false}
			styles={{
				body: {
					padding: 0,
				},
			}}
		>
			{contextHolder}
			<div className="h-full overflow-y-auto">
				<div className="flex h-full flex-col gap-2">
					<div className="h-dvh overflow-hidden px-6">
						<div
							className="scrollbar-hidden h-full overflow-y-auto"
							ref={messagesEndRef}
						>
							<div className="space-y-3 py-3">
								{messages.map((message, index) => (
									<div
										key={index}
										className={`flex gap-2 ${message.role === 'user' ? 'justify-end pt-2' : 'justify-start'}`}
									>
										<div className="space-y-1.5">
											<div className="text-xs capitalize text-gray-400">
												{message.role === 'assistant' ? 'assistant' : null}
											</div>
											<div
												className={`rounded-lg p-2 ${message.role === 'user' ? 'bg-primary text-white' : 'bg-gray-200 dark:bg-gray-800'}`}
											>
												{message.content}
											</div>
										</div>
									</div>
								))}
								{assistantMessageStream && (
									<div className={`flex justify-start gap-2`}>
										<div className="space-y-1.5">
											<div className="text-xs capitalize text-gray-400">Assistant</div>
											<div className={`rounded-lg bg-gray-200 p-2 dark:bg-gray-800`}>{assistantMessageStream}</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>

					<div className="mt-auto">
						<div className="border-b shadow dark:border-b-[#2d2b38]" />

						{socketStatus === 'loading' && (
							<div className="p-6">
								<Skeleton.Input
									active
									size="large"
									block
								/>
							</div>
						)}

						{socketStatus === 'closed' && (
							<div className="p-6 text-center text-base text-red-500">
								Chat is closed due to inactivity. Please save and close the conversation and start a new one.
							</div>
						)}

						{socketStatus === 'open' && (
							<Form
								onFinish={(values) => {
									if (!values.message) return;
									setMessages((prev) => [
										...prev,
										{
											role: 'user',
											content: values.message,
										},
									]);

									// send to socket
									sendMessage(values.message);

									// reset form
									send_data_form.resetFields();
								}}
								className="w-full p-6"
								form={send_data_form}
							>
								<div className="flex items-center justify-between gap-4">
									<div className="flex-1">
										<Form.Item
											name="message"
											className="mb-0"
										>
											<Input
												placeholder="Enter your message here"
												className="w-full"
												size="large"
												autoFocus
											/>
										</Form.Item>
									</div>

									<Form.Item className="mb-0">
										<Button
											type="primary"
											htmlType="submit"
											icon={<Icon component={LuSend} />}
											size="large"
										>
											Send
										</Button>
									</Form.Item>
								</div>
							</Form>
						)}
					</div>
				</div>
			</div>
		</Drawer>
	);
};

export default TestLLMDrawer;
