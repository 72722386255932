import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Form, Input, message } from 'antd';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import axios from '../../config/axios';
import { useRuthConnectStore } from '../../store/ruth-connect';
import { Integration } from './ruth-connect-types';

const CalendlyConnectUI = () => {
	const { setOpenModalId } = useRuthConnectStore();

	const { refetch } = useQuery<Integration[]>({
		queryKey: ['connected-apps'],
	});

	// connect calendly mutation
	const { mutate, isPending } = useMutation({
		mutationFn: async (payload: unknown) => {
			await axios.post('/ruth-connect/integrations', payload, { withCredentials: true });
		},
		onSuccess: () => {
			message.success('Calendly connected successfully.');
			setOpenModalId(null);
			refetch();
		},
		onError: (error) => {
			if (error instanceof AxiosError && error.response) {
				return toast.error(error.response.data.msg);
			}
			toast.error('An error occurred. Please try again.');
		},
	});

	return (
		<Form
			layout="vertical"
			onFinish={(values) => {
				const payload = {
					app_name: 'calendly',
					friendly_app_name: 'calendly',
					logo: 'https://puretalk-dashboard-rh5d8.ondigitalocean.app/images/calendly.svg',
					data: {
						api_key: values.api_key,
					},
					tools: ['calendly_check_calendar_availabilty', 'calendly_book_meeting'],
				};

				// mutate integration
				mutate(payload);
			}}
		>
			<Form.Item
				label="Calendly API Key"
				name="api_key"
				className='mb-4'
			>
				<Input.TextArea
					autoSize
					size="large"
					required
				/>
			</Form.Item>

			<Form.Item className="mb-0">
				<Button
					type="primary"
					htmlType="submit"
					block
					loading={isPending}
					disabled={isPending}
					className="font-medium"
					size='large'
				>
					Connect
				</Button>
			</Form.Item>
		</Form>
	);
};

export default CalendlyConnectUI;
