import { useMutation, useQuery } from '@tanstack/react-query';
import { Card, Input, Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { LuEye, LuEyeOff, LuRefreshCw } from 'react-icons/lu';
import { MdOutlineContentCopy } from 'react-icons/md';
import LoadingIcon from '../components/loading-icon/loading-icon';
import axios from '../config/axios';
import dayjs from '../config/dayjs';
import AppLayout from '../layouts/app.layout';

const APIKeys = () => {
	const [type, setType] = useState<'text' | 'password'>('password');
	const [copied, setCopied] = useState<boolean>(false);
	const [success, setSuccess] = useState<boolean>(false);

	// get api keys
	const {
		data: keys,
		isLoading: keysLoading,
		isError: keysError,
		isSuccess: keysSuccess,
		refetch: refetchKeys,
	} = useQuery<{
		api_key: string;
		last_generated: Date;
	}>({
		queryKey: ['api-keys'],
		queryFn: async () => {
			const { data } = await axios.get('/users/get-api-key', { withCredentials: true });
			return data.results;
		},
		retry: false,
	});

	// generate api key
	const { mutate: generateKey, isPending: generateKeyPending } = useMutation({
		mutationKey: ['generate-api-key'],
		mutationFn: async () => {
			await axios.post('/users/generate-api-key', {}, { withCredentials: true });
			return true;
		},
		onSuccess: () => {
			refetchKeys();
			setSuccess(true);
			toast.success('API key generated successfully!');
		},
	});

	// reset success state after 3 seconds
	useEffect(() => {
		if (success) {
			const timer = setTimeout(() => {
				setSuccess(false);
			}, 3000);

			return () => clearTimeout(timer);
		}
	}, [success]);

	return (
		<AppLayout
			title="API Keys"
			subtitle="Ensure your API key remains confidential."
		>
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
				{keysLoading && (
					<Card
						styles={{
							body: {
								padding: 20,
							},
						}}
					>
						<Skeleton
							title={false}
							active
						/>

						<div className="pt-5" />

						<Skeleton.Input
							active
							block
						/>

						<div className="pt-3" />

						<Skeleton.Button
							active
							style={{
								width: 130,
							}}
						/>

						<div className="pt-3" />

						<Skeleton
							paragraph={false}
							active
						/>
					</Card>
				)}

				{keysError && (
					<Card
						styles={{
							body: {
								padding: 20,
							},
						}}
					>
						<h2 className="text-lg font-medium">No API keys found.</h2>

						<p className="text-md text-gray-500">You have not generated any API keys yet.</p>

						<div className="pt-3" />

						<button
							type="button"
							className="flex items-center gap-2 rounded-md bg-primary px-4 py-2 font-semibold text-white"
							disabled={generateKeyPending}
							onClick={() => generateKey()}
						>
							{generateKeyPending && <LoadingIcon />}

							<span>Generate API Key</span>
						</button>
					</Card>
				)}

				{keysSuccess && keys && (
					<Card
						styles={{
							body: {
								padding: 20,
							},
						}}
					>
						<h2 className="text-lg font-medium">Your API keys</h2>

						<p className="text-md text-gray-500">You can always regenerate your API keys.</p>

						<div className="pt-3" />

						<Input
							readOnly
							value={keys.api_key}
							type={type}
							suffix={
								<div className="flex items-center gap-2">
									{type === 'password' ? (
										<LuEye
											size={18}
											role="button"
											onClick={() => setType('text')}
										/>
									) : (
										<LuEyeOff
											size={18}
											role="button"
											onClick={() => setType('password')}
										/>
									)}

									{copied ? (
										<IoCheckmarkCircle
											size={18}
											color="green"
										/>
									) : (
										<MdOutlineContentCopy
											size={18}
											role="button"
											onClick={() => {
												navigator.clipboard.writeText(keys.api_key);
												setCopied(true);
												toast.success('API key copied to clipboard!');

												// Reset the copied state after 3 seconds
												setTimeout(() => {
													setCopied(false);
												}, 3000);
											}}
										/>
									)}
								</div>
							}
						/>

						<div className="pt-3" />

						<button
							type="button"
							className="flex items-center gap-2 rounded-md bg-primary px-4 py-2 font-semibold text-white"
							disabled={generateKeyPending}
							onClick={() => generateKey()}
						>
							{generateKeyPending && <LoadingIcon />}

							{success && <IoCheckmarkCircle size={18} />}

							{!generateKeyPending && !success && <LuRefreshCw size={18} />}

							<span>Regenerate</span>
						</button>

						<div className="pt-2" />

						<p className="text-md text-gray-500">
							Last generated on <span className="font-semibold">{dayjs(keys.last_generated).format('MMMM D, YYYY HH:mm A')}</span>
						</p>
					</Card>
				)}
			</div>
		</AppLayout>
	);
};

export default APIKeys;
