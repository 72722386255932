import { Button, Modal } from 'antd';
import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FaRegCopy } from 'react-icons/fa6';
import { cn } from '../../config/cn';
import { colors } from '../../theme/colors';
import { AIAssistant } from '../../types/ai-assistant.types';
import { GoCheckCircleFill } from 'react-icons/go';

type Props = {
	open: boolean;
	close: () => void;
	base_url: string;
	url: string;
	assistant: AIAssistant;
};

const ChatBotEmbedModal: FC<Props> = (props) => {
	const [selectedEmbedType, setSelectedEmbedType] = useState<string>('iframe');
	const [copied, setCopied] = useState(false);

	// iframe and script codes
	const iframeCode = `<iframe
  src="${props.url}"
  style="width: 100%; height: 100%; min-height: calc(100dvh - 1.5rem); border: none;"
  allow="microphone">
</iframe>`;

	const scriptCode = `<script>
  window.puretalkChatbotConfig = {
  token: "${props.assistant.assistant_id}",
 }
</script>
<script
  src="${props.base_url}/scripts/embed.min.js"
  id="${props.assistant.assistant_id}"
  defer>
</script>`;

	// reset copied state after 2 seconds
	useEffect(() => {
		if (copied) {
			const timeout = setTimeout(() => {
				setCopied(false);
			}, 2000);

			return () => clearTimeout(timeout);
		}
	}, [copied]);

	return (
		<Modal
			title="Embed On Your Website"
			open={props.open}
			onCancel={props.close}
			centered
			footer={null}
			width={700}
		>
			<div className="pt-2" />

			<h2 className="font-semibold text-gray-600 dark:text-gray-300">Choose the way to embed chat app to your website</h2>

			<div className="pt-4" />

			<div className="grid grid-cols-2 gap-4">
				<div
					className={cn({
						'rounded-lg border border-primary': selectedEmbedType === 'iframe',
					})}
					onClick={() => {
						setSelectedEmbedType('iframe');
						setCopied(false);
					}}
					role="button"
				>
					<img
						src="/images/embed-iframe.svg"
						alt="embed-iframe"
						className="w-full object-cover"
					/>
				</div>

				<div
					className={cn({
						'rounded-lg border border-primary': selectedEmbedType === 'script',
					})}
					onClick={() => {
						setSelectedEmbedType('script');
						setCopied(false);
					}}
					role="button"
				>
					<img
						src="/images/embed-script.svg"
						alt="embed-iframe"
						className="w-full object-cover"
					/>
				</div>
			</div>

			<div className="pt-6" />

			{selectedEmbedType === 'iframe' && (
				<div className="rounded-lg border p-4 dark:border-[#2d2b38]">
					<h2 className="font-medium text-gray-600 dark:text-gray-300">
						To add the chat app anywhere on your website, add this iframe to your html code.
					</h2>

					<div className="pt-3" />

					<pre className="relative overflow-hidden rounded-lg bg-gray-200 p-2 dark:bg-[#161422]">
						<code>{iframeCode}</code>

						<div className="pt-2" />

						<Button
							icon={copied ? <GoCheckCircleFill color="green" /> : <FaRegCopy color={colors.primary} />}
							className="absolute right-2 top-2 z-50"
							onClick={async () => {
								await navigator.clipboard.writeText(iframeCode);
								setCopied(true);
								toast.success('Copied to clipboard');
							}}
						/>
					</pre>
				</div>
			)}

			{selectedEmbedType === 'script' && (
				<div className="rounded-lg border p-4 dark:border-[#2d2b38]">
					<h2 className="font-medium text-gray-600 dark:text-gray-300">
						To add a chat app to the bottom right of your website add this code to your html.
					</h2>

					<div className="pt-3" />

					<pre className="relative overflow-hidden rounded-lg bg-gray-200 p-2 dark:bg-[#161422]">
						<code>{scriptCode}</code>

						<div className="pt-2" />

						<Button
							icon={copied ? <GoCheckCircleFill color="green" /> : <FaRegCopy color={colors.primary} />}
							className="absolute right-2 top-2 z-50"
							onClick={async () => {
								await navigator.clipboard.writeText(scriptCode);
								setCopied(true);
								toast.success('Copied to clipboard');
							}}
						/>
					</pre>
				</div>
			)}
		</Modal>
	);
};

export default ChatBotEmbedModal;
