import { useGoogleLogin } from '@react-oauth/google';
import { useMutation } from '@tanstack/react-query';
import { Alert, Button, Checkbox, Divider, Form, Input } from 'antd';
import { AxiosError } from 'axios';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FaGithub } from 'react-icons/fa6';
import { FcGoogle } from 'react-icons/fc';
import { Link, useLocation } from 'react-router-dom';
import axios from '../config/axios';
import { AuthContext } from '../context/auth.context.tsx';
import AuthLayout from '../layouts/auth.layout';

type FieldType = {
	email: string;
	password: string;
	remember: string;
};

const Login = () => {
	const { setUser, setIsAuthenticated } = useContext(AuthContext);
	const [alertMessage, setAlertMessage] = useState<string | null>(null);
	const location = useLocation();

	// login with Google
	const loginWithGoogle = useGoogleLogin({
		flow: 'auth-code',
		ux_mode: 'redirect',
		redirect_uri:
			import.meta.env.VITE_ENV === 'production'
				? 'https://app.puretalk.ai/redirect/'
				: 'https://puretalk-dashboard-rh5d8.ondigitalocean.app/redirect/',
	});

	// login with GitHub
	const loginWithGithub = () => {
		window.location.href =
			import.meta.env.VITE_ENV === 'production'
				? 'https://github.com/login/oauth/authorize?scope=user:email+read:user&client_id=Ov23li7qrngDDJ2TV8IW'
				: 'https://github.com/login/oauth/authorize?scope=user:email+read:user&client_id=9aeb4641c3dc541fa2c2';
	};

	// login with email mutation
	const { mutate: loginWithEmail, isPending: loginWithEmailPending } = useMutation({
		mutationFn: async (values: FieldType) => {
			const { data } = await axios.post('/users/email-login', values);
			return data;
		},
		onSuccess: (data) => {
			window.localStorage.setItem('device_id', data.access_token);
			toast.success('Login successful');
			setUser(data.user);
			setIsAuthenticated(true);
		},
		onError: (error: AxiosError<{ msg: string }>) => {
			if (error.response) {
				setAlertMessage(error.response.data.msg);
			}
		},
	});

	// hide the error message after 3 seconds
	useEffect(() => {
		const timer = setTimeout(() => {
			setAlertMessage(null);
		}, 3000);
		return () => clearTimeout(timer);
	}, [alertMessage]);

	// get message from navigate state
	const state_message = location.state && location.state.message;

	return (
		<AuthLayout>
			<div className="h-full">
				<div className="flex flex-col items-center justify-center">
					<Link to={'/'}>
						<img
							src="/images/logo.png"
							alt="logo"
							className="h-12"
						/>
					</Link>

					<div className="pt-10" />

					<h1 className="text-center text-4xl font-bold text-black-7 dark:text-white">Welcome Back</h1>

					<div className="pt-4" />

					<p className="text-base font-medium text-gray-400 dark:text-gray-200">Just pop your email and password in here </p>

					<div className="pt-6" />

					<div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2">
						<Button
							block
							size="large"
							className="h-11 px-12 font-bold text-black-7 dark:text-white"
							icon={<FcGoogle size={20} />}
							onClick={() => loginWithGoogle()}
							shape="round"
						>
							Sign in with Google
						</Button>

						<Button
							block
							size="large"
							className="h-11 px-12 font-bold text-black-7 dark:text-white"
							icon={<FaGithub size={20} />}
							onClick={() => loginWithGithub()}
							shape="round"
						>
							Sign in with Github
						</Button>
					</div>

					<Divider>
						<span className="font-medium text-black-7 dark:text-white">Or</span>
					</Divider>

					{alertMessage ? (
						<Alert
							message={alertMessage}
							type={alertMessage.includes('successful') ? 'success' : 'error'}
							showIcon
							className="w-full"
						/>
					) : state_message ? (
						<Alert
							message={state_message}
							type={state_message.includes('successful') ? 'success' : 'error'}
							showIcon
							className="w-full"
						/>
					) : null}

					<Form
						initialValues={{ remember: true }}
						onFinish={(values) => {
							loginWithEmail(values);
						}}
						layout="vertical"
						className="w-full pt-2"
						requiredMark={false}
					>
						<Form.Item<FieldType>
							label={<div className="pl-1 text-base font-medium text-black-7 dark:text-white">Email</div>}
							name="email"
						>
							<Input
								size="large"
								placeholder="Enter your email"
								required
								type="email"
								className="h-11"
							/>
						</Form.Item>

						<Form.Item<FieldType>
							label={<div className="pl-1 text-base font-medium text-black-7 dark:text-white">Password</div>}
							name="password"
						>
							<Input.Password
								size="large"
								placeholder="Enter your password"
								required
								type="password"
								className="h-11"
							/>
						</Form.Item>

						<div className="flex items-center justify-between pb-3.5">
							<Form.Item<FieldType>
								name="remember"
								valuePropName="checked"
								className="mb-2"
							>
								<Checkbox>
									<span className="text-dark-7 text-base font-medium">Remember me</span>
								</Checkbox>
							</Form.Item>
							<Link to={'/forgot-password'}>
								<span className="text-base font-medium text-[#6E5DED]">Forgot password?</span>
							</Link>
						</div>

						<Form.Item className="mb-2">
							<Button
								htmlType="submit"
								block
								size="large"
								shape="round"
								className="h-11 font-bold"
								type="primary"
								loading={loginWithEmailPending}
								disabled={loginWithEmailPending}
							>
								Sign in
							</Button>
						</Form.Item>
					</Form>

					<div className="pt-3" />

					<div className="text-center">
						<span className="txt-base text-gray-400 dark:text-gray-200">Don't have an account?</span>
						<Link
							to={'/register'}
							className="ml-1 font-medium text-[#6E5DED]"
						>
							Sign Up
						</Link>
					</div>
				</div>
			</div>
		</AuthLayout>
	);
};

export default Login;
