import { useQuery } from '@tanstack/react-query';
import { Button, Card, Popover, Tag } from 'antd';
import { useContext } from 'react';
import { GrCircleInformation } from 'react-icons/gr';
import { HiOutlineLightningBolt } from 'react-icons/hi';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../config/axios';
import { cn } from '../config/cn';
import { AuthContext } from '../context/auth.context.tsx';
import { SidebarContext } from '../context/sidebar.context.tsx';
import { formatNumber } from '../utils/helpers';
import { mainFeatures, settings } from './menu-items';
import RenderMenu from './render-menu';

type Balance = {
	total_minutes: number;
	balance: number;
	rate_per_minute: number;
	minutes_for_balance: number;
	subscription_minutes: number;
};

const SidebarBottom = () => {
	const navigate = useNavigate();
	const { isCollapsed } = useContext(SidebarContext);
	const { setUser, setIsAuthenticated, user } = useContext(AuthContext);

	// get users available balances
	const { data: balance, isLoading: balanceLoading } = useQuery<Balance>({
		queryKey: ['balances'],
		queryFn: async () => {
			const { data } = await axios.get('/billing/available-minutes', { withCredentials: true });
			return data.results;
		},
	});

	const handleLogout = () => {
		localStorage.removeItem('device_id');
		setIsAuthenticated(false);
		setUser(null);
		navigate('/login');
	};

	return (
		<div
			className={cn(
				'scrollbar-hidden dark:bg-black flex h-[calc(100dvh-4.6rem)] flex-col overflow-x-auto min-[1100px]:h-[calc(100dvh-6.1rem)] dark:text-white',
				{
					'w-24': isCollapsed,
					'w-72': !isCollapsed,
				},
			)}
		>
			<div className="p-5">
				{!isCollapsed && (
					<div className="rounded-lg border p-2 text-base font-semibold text-gray-600 dark:border-[#FFFFFF0D] dark:bg-[#1F1D2B] dark:text-white">
						Current Balance: ${formatNumber(user?.balance ?? 0)}
					</div>
				)}

				<div className="pt-4" />

				<Link
					to={'/dashboard'}
					title="Dashboard"
				>
					<div
						className={cn('flex items-center gap-2.5 rounded-lg px-2.5 py-2 transition-all duration-300', {
							'justify-center': isCollapsed,
							'bg-[#B18EED1A] text-link-active': location.pathname.includes('/dashboard'),
							'text-black-7 dark:text-white': !location.pathname.includes('/dashboard'),
						})}
					>
						<img
							src="/images/layout/dashboard-active-icon.svg"
							className={cn('size-6', {
								hidden: !location.pathname.includes('/dashboard'),
							})}
							alt="active-icon"
						/>
						<img
							src="/images/layout/dashboard-inactive-icon.svg"
							className={cn('size-6', {
								hidden: location.pathname.includes('/dashboard'),
							})}
							alt="inactive-icon"
						/>
						{!isCollapsed && <div className={cn('truncate text-base font-semibold')}>Dashboard</div>}
					</div>
				</Link>

				<div className="pt-4" />

				{!isCollapsed && <div className="pb-2 pl-3 text-base font-bold text-gray-500">Main feature</div>}

				<RenderMenu items={mainFeatures} />
			</div>

			<div className="p-5">
				{!isCollapsed && <div className="pb-2 pl-3 text-base font-bold text-gray-500">Settings</div>}

				<RenderMenu items={settings} />
			</div>

			<div className="mt-auto px-5">
				<Card
					styles={{ body: { padding: 8 } }}
					className="bg-inherit"
				>
					<div
						className={cn('flex items-center justify-between text-sm', {
							hidden: isCollapsed,
						})}
					>
						{balanceLoading ? (
							<div className="font-semibold">Retrieving...</div>
						) : balance ? (
							<div className="flex items-center gap-1">
								<div className="font-semibold">
									{balance.total_minutes >= 1000
										? `${(balance.total_minutes / 1000).toFixed(1)}k`
										: balance.total_minutes.toFixed(2)}{' '}
									MIN Remain
								</div>
								<Popover
									arrow={false}
									content={
										<div>
											<div>
												<strong>Total Minutes:</strong> {formatNumber(balance.total_minutes.toFixed(2))}
											</div>
											<div>
												<strong>Balance:</strong> ${formatNumber(balance.balance.toFixed(2))}
											</div>
											<div>
												<strong>Rate per Minute:</strong> ${formatNumber(balance.rate_per_minute.toFixed(2))}
											</div>
											<div>
												<strong>Minutes for Balance:</strong> {formatNumber(balance.minutes_for_balance.toFixed(2))}
											</div>
											<div>
												<strong>Subscription Minutes:</strong> {formatNumber(balance.subscription_minutes.toFixed(2))}
											</div>
										</div>
									}
									title="Balance Breakdown"
								>
									<GrCircleInformation role="button" />
								</Popover>
							</div>
						) : null}

						{user?.current_subscription && (
							<Tag
								color="#f50"
								className="mr-0 capitalize"
							>
								{user.current_subscription.includes('yearly')
									? `${user.current_subscription.replace('_yearly', '')}/Yr`
									: user.current_subscription}
							</Tag>
						)}
					</div>

					<div
						className={cn('pt-3', {
							hidden: isCollapsed,
						})}
					/>

					<Link to="/billing">
						<Button
							block
							icon={<HiOutlineLightningBolt size={18} />}
							className="font-semibold"
							title="Upgrade"
						>
							<span
								className={cn({
									'!hidden': isCollapsed,
								})}
							>
								Upgrade
							</span>
						</Button>
					</Link>
				</Card>

				<div className="pt-2" />

				<div
					className={cn('flex items-center gap-2.5 rounded-lg px-2.5 py-2 pb-6 transition-all duration-300', {
						'justify-center': isCollapsed,
					})}
					role="button"
					onClick={handleLogout}
					title="Logout"
				>
					<img
						src="/images/layout/logout-icon.svg"
						className="size-6"
						alt="logout-icon"
					/>
					{!isCollapsed && <div className={cn('truncate text-base font-semibold text-black-7 dark:text-white')}>Logout</div>}
				</div>
			</div>
		</div>
	);
};

export default SidebarBottom;
