import Icon, { PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Button, Dropdown } from 'antd';
import { FC, useState } from 'react';
import { SiCalendly } from 'react-icons/si';
import { Link } from 'react-router-dom';
import { useReactFlow } from 'reactflow';
import axios from '../../config/axios';
import { Tool } from '../../store/tools';
import { LlmStates } from '../../types/ai-assistant.types';
import { bookAppointmentParameters, checkCalendarAvailabilityParameters } from '../ai-assistants/parameters';
import { Integration } from '../ruth-connect/ruth-connect-types';
import BookOnCalendar from './calendly-book-on-calendar';
import CheckCalendarAvailability from './calendly-check-calendar-availability';
import EndCall from './end-call';
import CallTransfer from './call-transfer';
import { HiPhoneMissedCall } from 'react-icons/hi';
import { VscCallOutgoing } from 'react-icons/vsc';
import { useThemeStore } from '../../store/theme';
import CalBookOnCalendar from './cal-book-on-calender';

type LLMStatesToolsProps = {
	llm_states: LlmStates;
};

type Tab = {
	label: string;
	icon: JSX.Element;
	functionId: string;
};

const LLMStatesTools: FC<LLMStatesToolsProps> = (props) => {
	const [activeTab, setActiveTab] = useState<string[]>(() => (props.llm_states.tools ? props.llm_states.tools.map((tool) => tool.functionId) : []));

	const { is_dark_mode } = useThemeStore();
	const { setNodes } = useReactFlow();

	const [tabs, setTabs] = useState<Tab[]>([]);

	const addToolIfNotExists = (prev: Tab[], tool: Tab) => {
		const exists = prev.some((tab) => tab.functionId === tool.functionId);
		if (!exists) {
			prev.push(tool);
		}
	};

	const { data: connectedApps } = useQuery<Integration[]>({
		queryKey: ['connected-apps'],
		queryFn: async () => {
			const { data } = await axios.get('/ruth-connect/integrations', { withCredentials: true });

			setTabs((prev) => {
				addToolIfNotExists(prev, {
					label: 'End Call',
					icon: <HiPhoneMissedCall size={20} />,
					functionId: 'end_call',
				});

				addToolIfNotExists(prev, {
					label: 'Call Transfer',
					icon: <VscCallOutgoing size={20} />,
					functionId: 'transfer_call',
				});

				if (data.results.length > 0) {
					for (const item of data.results) {
						if (item.app_name === 'calendly') {
							addToolIfNotExists(prev, {
								label: 'Book on Calendar (Calendly)',
								icon: <SiCalendly size={20} />,
								functionId: 'calendly_create_calendar_event',
							});

							addToolIfNotExists(prev, {
								label: 'Check Calendar Availability (Calendly)',
								icon: <SiCalendly size={20} />,
								functionId: 'calendly_get_user_busy_times',
							});
						}

						if (item.app_name === 'cal.com') {
							addToolIfNotExists(prev, {
								label: 'Book on Calendar (Cal.com)',
								icon: (
									<Icon
										component={() => (
											<img
												src={is_dark_mode ? '/images/ruth-connect/cal-logo-dark.svg' : '/images/ruth-connect/cal-logo.svg'}
												alt="cal.com"
												className="size-6"
											/>
										)}
									/>
								),
								functionId: 'cal_dot_com_book_on_calendar',
							});
						}
					}
				}

				return [...prev];
			});

			return data.results;
		},
	});

	const handleInitialNodes = (functionId: string) => {
		setNodes((prev) => {
			const newNodes = prev.map((node) => {
				if (node.id === props.llm_states.state_id) {
					const tools = node.data.item.tools || [];

					const toolIndex = tools.findIndex((tool: Tool) => tool.functionId === functionId);

					if (toolIndex !== -1) {
						// Update existing tool
						tools[toolIndex] = {
							...tools[toolIndex],
							function: {
								...tools[toolIndex].function,
								name: tools[toolIndex].function.name,
								description: tools[toolIndex].function.description,
							},
						};
					} else {
						// Add new tool
						let newTool;
						switch (functionId) {
							case 'calendly_create_calendar_event':
								newTool = {
									type: 'function',
									functionId: 'calendly_create_calendar_event',
									function: {
										name: 'book_appointment',
										description:
											'Book an appointment in the calendar. This function will book an appointment in the calendar for the given date and time.',
									},
									parameters: bookAppointmentParameters,
								};
								break;
							case 'calendly_get_user_busy_times':
								newTool = {
									type: 'function',
									functionId: 'calendly_get_user_busy_times',
									function: {
										name: 'check_calendar_availability',
										description:
											'Get get busy time from the calendar. This function will return the busy time from the calendar for the given date range. Date range should not exceed 7 days.',
									},
									parameters: checkCalendarAvailabilityParameters,
								};
								break;

							case 'cal_dot_com_book_on_calendar':
								newTool = {
									type: 'function',
									functionId: 'cal_dot_com_book_on_calendar',
									function: {
										name: 'cal_dot_com_book_on_calendar',
										description: 'Create a booking on Cal.com calendar',
										parameters: {
											type: 'object',
											properties: {
												name: {
													type: 'string',
													description: 'Name of the customer. ask customer to provide name if you do not have it already',
												},
												email: {
													type: 'string',
													description: 'Email of the customer. ask user to provide email if you do not have it already',
												},
												timezone: {
													type: 'string',
													description: 'Timezone of the ai assistant',
												},
												start: {
													type: 'string',
													description: 'Start time of the booking. should be always in future. example: 2024-09-25T20:45:00',
												},
												puretalkCallId: {
													type: 'string',
													description: 'Puretalk Call id of the current call',
												},
											},
											required: ['name', 'email', 'timezone', 'start', 'puretalkCallId'],
										},
									},
								};
								break;
							case 'end_call':
								newTool = {
									type: 'function',
									functionId: 'end_call',
									function: {
										name: 'end_call',
										description: 'End the call when user has to leave (like says bye) or you are instructed to do so.',
									},
									parameters: {
										type: 'object',
										properties: {
											provider_call_id: {
												type: 'string',
												description: 'Provider call id of the current call',
											},
											protocol: {
												type: 'string',
												description: 'Protocol of the call',
												enum: ['telnyx', 'twilio', 'web'],
											},
										},
										required: ['provider_call_id', 'protocol'],
									},
								};
								break;
							case 'transfer_call':
								newTool = {
									type: 'function',
									functionId: 'transfer_call',
									function: {
										name: 'transfer_call',
										description: 'When user is angry or requests a human agent, transfer the call to a human.',
									},
									parameters: {
										type: 'object',
										properties: {
											transfer_to: {
												type: 'string',
												description: 'Phone number of the human agent to transfer the call to.',
											},
										},
										required: ['transfer_to'],
									},
								};
								break;
							default:
								return node;
						}

						tools.push(newTool);
					}

					return {
						...node,
						data: {
							...node.data,
							item: {
								...node.data.item,
								tools: tools,
							},
						},
					};
				}
				return node;
			});
			return newNodes;
		});
	};

	// handle delete tool
	const handleDeleteTool = (functionId: string) => {
		setNodes((prev) => {
			const newNodes = prev.map((node) => {
				if (node.id === props.llm_states.state_id) {
					const tools = node.data.item.tools || [];

					const newTools = tools.filter((tool: Tool) => tool.functionId !== functionId);

					return {
						...node,
						data: {
							...node.data,
							item: {
								...node.data.item,
								tools: newTools,
							},
						},
					};
				}
				return node;
			});
			return newNodes;
		});

		// remove from active tab
		setActiveTab((prev) => prev && prev.filter((tab) => tab !== functionId));
	};

	return (
		<div className="min-h-80">
			<div className="flex items-center justify-between">
				<div className="space-y-1">
					<h2 className="text-lg font-semibold text-black-7 dark:text-white">Tools (Optional)</h2>
					<p className="w-4/5 text-sm font-medium text-black-3">
						Enable your agent with capabilities such as calendar bookings, call termination, or your own custom functions.
					</p>
				</div>

				<Dropdown
					menu={{
						items: tabs.map(({ label, icon, functionId }, index) => ({
							key: index,
							icon: icon,
							label: label,
							onClick: () => {
								setActiveTab((prev) => (prev && prev.concat(functionId)) ?? [functionId]);
								handleInitialNodes(functionId);
							},
						})),
					}}
					trigger={['click']}
					disabled={tabs.length === 0}
				>
					<Button
						size="large"
						type="primary"
						icon={<PlusOutlined />}
					>
						Add Tool
					</Button>
				</Dropdown>
			</div>

			<div className="py-2" />

			{connectedApps && connectedApps.length === 0 && props.llm_states.tools.length === 0 && (
				<div className="flex flex-wrap items-center justify-center space-x-2 pt-20 text-center text-gray-500 sm:flex-nowrap">
					<span>You have not connected any app yet. Connect an app from the</span>{' '}
					<Link
						to="/ruth-connect"
						className="text-primary"
						target="_blank"
					>
						Ruth Connect
					</Link>{' '}
					<span>page to add tools.</span>
				</div>
			)}

			<div className="space-y-4">
				{activeTab && activeTab.includes('calendly_create_calendar_event') && (
					<BookOnCalendar
						llm_states={props.llm_states}
						handleDeleteTool={handleDeleteTool}
					/>
				)}

				{activeTab && activeTab.includes('calendly_get_user_busy_times') && (
					<CheckCalendarAvailability
						llm_states={props.llm_states}
						handleDeleteTool={handleDeleteTool}
					/>
				)}

				{activeTab && activeTab.includes('end_call') && (
					<EndCall
						llm_states={props.llm_states}
						handleDeleteTool={handleDeleteTool}
					/>
				)}

				{activeTab && activeTab.includes('transfer_call') && (
					<CallTransfer
						llm_states={props.llm_states}
						handleDeleteTool={handleDeleteTool}
					/>
				)}

				{activeTab && activeTab.includes('cal_dot_com_book_on_calendar') && (
					<CalBookOnCalendar
						llm_states={props.llm_states}
						handleDeleteTool={handleDeleteTool}
					/>
				)}
			</div>
		</div>
	);
};

export default LLMStatesTools;
