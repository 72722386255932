import { FC } from 'react';

const Loader: FC = () => {
	return (
		<div
			className="inline-block size-7 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] text-primary "
			role="status"
		/>
	);
};

export default Loader;
