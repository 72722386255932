// get color based on sentiment
export const colorBasedOnSentiment = (sentiment: string) => {
	switch (sentiment) {
		case 'positive':
			return 'green';
		case 'negative':
			return 'red';
		case 'neutral':
			return 'blue';
		case 'n/a':
			return 'pink';
		default:
			return 'gray';
	}
};

// capitalize first letter
export const capitalize = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

// get badge color based on status
export const getBadgeColor = (status: string) => {
	switch (status) {
		case 'incomplete':
			return 'orange';
		case 'partial':
			return 'gold';
		case 'complete':
			return 'green';
		default:
			return 'orange';
	}
};
