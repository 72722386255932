import { Card, Modal, Progress } from 'antd';
import { AxiosError } from 'axios';
import { Dispatch, FC, Fragment, useState } from 'react';
import toast from 'react-hot-toast';
import { IoIosPause } from 'react-icons/io';
import { IoCheckmark, IoSettingsOutline } from 'react-icons/io5';
import { MdDeleteOutline } from 'react-icons/md';
import { RxUpdate } from 'react-icons/rx';
import axios from '../../config/axios';
import { cn } from '../../config/cn';
import dayjs from '../../config/dayjs';
import { Campaign } from '../../views/campaigns';
import CampaignDrawer from './campaign-drawer';

type Props = {
	campaign: Campaign;
	setRefresh: Dispatch<React.SetStateAction<number>>;
};

const CampaignCard: FC<Props> = (props) => {
	const { campaign } = props;
	const [campaignDrawerOpen, setCampaignDrawerOpen] = useState<boolean>(false);
	const [, setRefreshUI] = useState<number>(0);
	const [statusUpdateLoading, setStatusUpdateLoading] = useState<boolean>(false);
	const [modal, contextHolder] = Modal.useModal();

	// get background color based on campaign status
	const getTextColor = () => {
		switch (campaign.status) {
			case 'active':
				return 'text-green-600';
			case 'paused':
				return 'text-yellow-600';
			case 'completed':
				return 'text-blue-600';
			case 'terminated':
				return 'text-red-600';
			default:
				return 'text-gray-600';
		}
	};

	return (
		<>
			{contextHolder}
			<Card styles={{ body: { padding: 16 } }}>
				<div className="flex items-center justify-between gap-1">
					<div className="text-lg font-medium text-black-7 dark:text-white">{campaign.name}</div>
					{campaign.status !== 'terminated' && (
						<div
							role="button"
							className="text-black-7 dark:text-white"
							onClick={() => setCampaignDrawerOpen(true)}
						>
							<IoSettingsOutline size={20} />
						</div>
					)}
				</div>

				<div className={cn('w-fit text-sm font-semibold capitalize leading-none text-white', getTextColor())}>
					{statusUpdateLoading ? 'Updating...' : campaign.status}
				</div>

				<div className="pt-4" />

				<div className="space-y-1">
					{[
						{
							label: 'Start Date',
							value: dayjs(campaign.start_date).format('DD MMM, YYYY'),
						},
					].map((item, index) => (
						<div
							key={index}
							className="space-y-px"
						>
							<div className="text-sm font-medium text-black-7 dark:text-white">{item.label}</div>
							<div className="text-xs font-medium text-black-3">{item.value}</div>
						</div>
					))}
				</div>

				<div className="pt-4" />

				<div className="flex border-y dark:border-y-[#2d2b38]">
					{[
						{
							label: 'Call Remaining',
							value: campaign.pending_tasks,
						},
						{
							label: 'Call Completed',
							value: campaign.completed_tasks,
						},
						{
							label: 'Total Contact',
							value: campaign.total_tasks,
						},
					].map((item, index) => (
						<Fragment key={index}>
							<div className="flex flex-1 flex-col items-center justify-center py-1">
								<div className="text-base font-medium text-black-7 dark:text-white">{item.label}</div>
								<div className="text-sm font-medium text-black-3">{item.value}</div>
							</div>

							{index !== 2 && <div className="border-r dark:border-r-[#2d2b38]" />}
						</Fragment>
					))}
				</div>

				<div className="pt-3" />

				<section>
					<div className="text-sm font-medium text-black-7 dark:text-white">
						Completed Calls: {campaign.completed_tasks}/{campaign.total_tasks}
					</div>

					<Progress
						percent={campaign.total_tasks > 0 ? (campaign.completed_tasks / campaign.total_tasks) * 100 : 0}
						showInfo={false}
					/>
				</section>

				<div className="pt-3" />

				{campaign.status !== 'terminated' ? (
					<div className="flex justify-between gap-2">
						{[
							{
								label: 'Resume',
								value: 'active',
								color: 'text-green-500',
								icon: <RxUpdate size={18} />,
							},
							{
								label: 'Pause',
								value: 'paused',
								color: 'text-yellow-500',
								icon: <IoIosPause size={20} />,
							},
							{
								label: 'Mark Complete',
								value: 'completed',
								color: 'text-blue-500',
								icon: <IoCheckmark size={20} />,
							},
							{
								label: 'Terminate',
								value: 'terminated',
								color: 'text-red-500',
								icon: <MdDeleteOutline size={20} />,
							},
						]
							.filter((status) => status.value !== campaign.status)
							.map((status, index) => (
								<div
									key={index}
									className={cn('flex items-center gap-1 font-medium', status.color)}
									role="button"
									onClick={async () => {
										try {
											if (status.value === 'terminated') {
												modal.confirm({
													title: 'Are you sure?',
													content: 'You are about to terminate this campaign. This action cannot be undone.',
													onOk: async () => {
														setStatusUpdateLoading(true);
														await axios.patch(
															`/campaigns/${campaign.campaign_id}`,
															{ status: status.value },
															{ withCredentials: true },
														);

														// update campaign status UI
														campaign.status = status.value as Campaign['status'];
														setRefreshUI((prev) => prev + 1);

														setStatusUpdateLoading(false);
													},
													centered: true,
												});
											} else {
												setStatusUpdateLoading(true);
												await axios.patch(
													`/campaigns/${campaign.campaign_id}`,
													{ status: status.value },
													{ withCredentials: true },
												);

												// update campaign status UI
												campaign.status = status.value as Campaign['status'];
												setRefreshUI((prev) => prev + 1);
											}
										} catch (error) {
											if (error instanceof AxiosError && error.response) {
												toast.error(error.response.data.msg);
											} else {
												toast.error('Failed to update campaign status.');
											}
										} finally {
											setStatusUpdateLoading(false);
										}
									}}
								>
									<div>{status.icon}</div>
									<div>{status.label}</div>
								</div>
							))}
					</div>
				) : (
					<div className="text-red-500">This campaign has been terminated.</div>
				)}
			</Card>

			{/* campaign drawer */}
			<CampaignDrawer
				open={campaignDrawerOpen}
				close={() => setCampaignDrawerOpen(false)}
				campaign={campaign}
				setRefresh={props.setRefresh}
			/>
		</>
	);
};

export default CampaignCard;
