import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, DatePicker, Divider, Drawer, Form, Input, Select } from 'antd';
import { AxiosError } from 'axios';
import { Dispatch, FC } from 'react';
import toast from 'react-hot-toast';
import axios from '../../config/axios';
import dayjs from '../../config/dayjs';
import { AIAssistant } from '../../types/ai-assistant.types';
import { Campaign } from '../../views/campaigns';
import CampaignSchedules from './campaign-schedules';
import CampaignTasks from './campaign-tasks';

type Props = {
	open: boolean;
	close: () => void;
	campaign: Campaign;
	setRefresh: Dispatch<React.SetStateAction<number>>;
};

export type CampaignTask = {
	to_number: string;
	is_completed: boolean;
	completed_at: null;
	variables: {
		[key: string]: string;
	}[];
};

const CampaignDrawer: FC<Props> = (props) => {
	// get all ai assistants for select
	const { data: assistants, isLoading: aiAssistantsLoading } = useQuery({
		queryKey: ['ai-assistants-select'],
		queryFn: async () => {
			const { data } = await axios.get('/ai-assistants', { withCredentials: true, params: { limit: 20 } });

			const assistants = data.results.map((assistant: AIAssistant) => {
				return {
					label: assistant.name,
					value: assistant.assistant_id,
				};
			});

			return assistants;
		},
	});

	// update campaign mutation
	const { mutate: updateCampaign, isPending: updateCampaignPending } = useMutation({
		mutationFn: async (values: unknown) => {
			await axios.patch(`/campaigns/${props.campaign.campaign_id}`, values, { withCredentials: true });
			return true;
		},
		onSuccess: () => {
			toast.success('Campaign updated successfully');
		},
		onError: (error: Error) => {
			if (error instanceof AxiosError && error.response) {
				return toast.error(error.response.data.msg);
			}
			if (error instanceof AxiosError && error.response && error.response.status === 500) {
				return toast.error(error.response.data.detail);
			}
			toast.error('Failed to update campaign');
		},
	});

	return (
		<Drawer
			title={props.campaign.name}
			onClose={() => {
				props.close();
			}}
			open={props.open}
			destroyOnClose
			size="large"
		>
			<div className="text-lg font-semibold text-black-7 dark:text-white">Update Campaign</div>

			<div className="pt-3" />

			<Form
				layout="vertical"
				onFinish={(values) => {
					values.start_date = dayjs(values.start_date).format('YYYY-MM-DD');
					values.end_date = dayjs(values.end_date).format('YYYY-MM-DD');

					updateCampaign(values);
				}}
				initialValues={{
					name: props.campaign.name,
					ai_assistant_id: props.campaign.ai_assistant.assistant_id,
					start_date: dayjs(props.campaign.start_date),
					end_date: dayjs(props.campaign.end_date),
				}}
			>
				<div className="grid grid-cols-12 gap-x-5 gap-y-4">
					<div className="col-span-12">
						<Form.Item
							name="name"
							label={<div className="text-sm font-medium">Name</div>}
							className="mb-0"
						>
							<Input
								required
								placeholder="Name"
								className="h-9"
							/>
						</Form.Item>
					</div>

					<div className="col-span-full">
						<Form.Item
							name="ai_assistant_id"
							label={<div className="text-sm font-medium">AI Assistant</div>}
							className="mb-0"
						>
							<Select
								options={assistants || []}
								placeholder="Select AI Assistant"
								loading={aiAssistantsLoading}
								virtual={false}
								className="h-9"
							/>
						</Form.Item>
					</div>

					<div className="col-span-6">
						<Form.Item
							name="start_date"
							label={<div className="text-sm font-medium">Start Date</div>}
							className="mb-0"
						>
							<DatePicker
								required
								inputReadOnly
								placeholder="Start Date"
								className="h-9 w-full"
								allowClear={false}
							/>
						</Form.Item>
					</div>

					<div className="col-span-6">
						<Form.Item
							name="end_date"
							label={<div className="text-sm font-medium">End Date</div>}
							className="mb-0"
						>
							<DatePicker
								required
								inputReadOnly
								placeholder="End Date"
								allowClear={false}
								className="h-9 w-full"
							/>
						</Form.Item>
					</div>

					<div className="col-span-12">
						<Form.Item className="mb-0">
							<Button
								type="primary"
								htmlType="submit"
								className="h-9 text-base font-medium"
								loading={updateCampaignPending}
								disabled={updateCampaignPending}
							>
								Save Changes
							</Button>
						</Form.Item>
					</div>
				</div>
			</Form>

			<Divider />

			<CampaignSchedules
				campaign={props.campaign}
				setRefresh={props.setRefresh}
			/>

			<Divider />

			<CampaignTasks
				open={props.open}
				campaign={props.campaign}
			/>
		</Drawer>
	);
};

export default CampaignDrawer;
