import { AnimatePresence, motion } from 'framer-motion';
import { useContext, useState } from 'react';
import BillingHistory from '../components/billing/billing-history.tsx';
import ChargeHistory from '../components/billing/charge-history.tsx';
import PaymentMethods from '../components/billing/payment-methods.tsx';
import { cn } from '../config/cn.ts';
import { AuthContext } from '../context/auth.context.tsx';
import AppLayout from '../layouts/app.layout.tsx';
import { formatNumber } from '../utils/helpers.ts';
import Subscriptions from './subscriptions.tsx';

type Tab = {
	label: string;
	activeIcon: string;
	inactiveIcon: string;
};

const tabs: Tab[] = [
	{
		label: 'Subscription',
		activeIcon: '/images/billing/subscriptions-active-icon.svg',
		inactiveIcon: '/images/billing/subscriptions-inactive-icon.svg',
	},
	{
		label: 'Payment Methods',
		activeIcon: '/images/billing/payment-methods-active-icon.svg',
		inactiveIcon: '/images/billing/payment-methods-inactive-icon.svg',
	},
	{
		label: 'Invoices',
		activeIcon: '/images/billing/billing-history-active-icon.svg',
		inactiveIcon: '/images/billing/billing-history-inactive-icon.svg',
	},
	{
		label: 'Charge History',
		activeIcon: '/images/billing/charge-history-active-icon.svg',
		inactiveIcon: '/images/billing/charge-history-inactive-icon.svg',
	},
];

const Billing = () => {
	const [activeTab, setActiveTab] = useState<Tab>(tabs[0]);
	const { user } = useContext(AuthContext);

	return (
		<AppLayout
			title="Billing"
			subtitle="Manage your card and billing information."
		>
			<div>
				<div className="text-base font-bold text-[#888888] dark:text-[#8a8990]">Balance</div>
				<div className="pt-1.5" />
				<div className="text-3xl font-medium text-black-7 dark:text-white">${formatNumber(user?.balance ?? 0)}</div>
			</div>

			<div className="pt-6" />

			<div className="scrollbar-hidden w-[calc(100dvw-2.5rem)] overflow-x-scroll md:w-full">
				<div className="inline-flex items-center gap-3 border-b-2 min-[523px]:flex dark:border-b-[#2d2b38]">
					{tabs.map((tab, index) => (
						<div
							key={index}
							className={cn('relative flex items-center justify-between gap-4 rounded-md rounded-b-none px-3 py-2', {
								'transition-all duration-500': activeTab.label === tab.label,
							})}
							onClick={() => setActiveTab(tab)}
							role="button"
						>
							<div className="flex min-w-max items-center gap-2">
								{activeTab.label === tab.label ? (
									<img
										src={tab.activeIcon}
										alt={tab.label}
										className="size-6"
									/>
								) : (
									<img
										src={tab.inactiveIcon}
										alt={tab.label}
										className="size-6"
									/>
								)}
								<div
									className={cn('text-base font-bold', {
										'text-link-active': activeTab.label === tab.label,
										'text-[#888888] dark:text-[#8a8990]': activeTab.label !== tab.label,
									})}
								>
									{tab.label}
								</div>
							</div>

							{tab.label === activeTab.label && (
								<motion.div
									className="absolute -bottom-0.5 left-0 right-0 h-0.5 bg-link-active"
									layoutId="underline"
								/>
							)}
						</div>
					))}
				</div>
			</div>

			<AnimatePresence mode="wait">
				<motion.div
					key={activeTab ? activeTab.label : 'empty'}
					initial={{ y: 10, opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					exit={{ y: -10, opacity: 0 }}
					transition={{ duration: 0.2 }}
					className="pt-6"
				>
					{activeTab.label === 'Subscription' && <Subscriptions />}

					{activeTab.label === 'Payment Methods' && <PaymentMethods />}

					{activeTab.label === 'Invoices' && <BillingHistory />}

					{activeTab.label === 'Charge History' && <ChargeHistory />}
				</motion.div>
			</AnimatePresence>
		</AppLayout>
	);
};

export default Billing;
