import { MdOutlineTextFields } from 'react-icons/md';
import { TbListNumbers } from 'react-icons/tb';
import { VscSymbolBoolean, VscSymbolNumeric } from 'react-icons/vsc';
import { PostAnalysis } from '../../types/ai-assistant.types';

export const getTitle = (type: PostAnalysis['type']) => {
	switch (type) {
		case 'string':
			return (
				<div className="flex items-center gap-2">
					<MdOutlineTextFields size={18} />
					<p className="text-lg font-medium">Text</p>
				</div>
			);
		case 'enum':
			return (
				<div className="flex items-center gap-2">
					<TbListNumbers size={18} />
					<p className="text-lg font-medium">Selector</p>
				</div>
			);
		case 'boolean':
			return (
				<div className="flex items-center gap-2">
					<VscSymbolBoolean size={18} />
					<p className="text-lg font-medium">Boolean</p>
				</div>
			);
		case 'number':
			return (
				<div className="flex items-center gap-2">
					<VscSymbolNumeric size={16} />
					<p className="text-lg font-medium">Number</p>
				</div>
			);
	}
};

export const getNamePlaceholder = (type: PostAnalysis['type']) => {
	switch (type) {
		case 'string':
			return 'detailed_call_summary';
		case 'enum':
			return 'customer_type';
		case 'boolean':
			return 'appointment_scheduled';
		case 'number':
			return 'user_age';
	}
};

export const getDescriptionPlaceholder = (type: PostAnalysis['type']) => {
	switch (type) {
		case 'string':
			return 'Detailed summary of the call before you transfer the call to a human agent so that the human agent can understand the context of the call';
		case 'enum':
			return 'What type of customer is the user?';
		case 'boolean':
			return 'Did the user book an appointment?';
		case 'number':
			return `What is the user's age?`;
	}
};
