import { BsCloudCheck } from 'react-icons/bs';
import { HiOutlineFaceSmile } from 'react-icons/hi2';
import { IoDocumentAttachOutline } from 'react-icons/io5';
import { RxAvatar } from 'react-icons/rx';
import { TfiBoltAlt } from 'react-icons/tfi';

export type Tab = {
	icon: JSX.Element;
	label: string;
};

export const tabs: Tab[] = [
	{
		icon: <RxAvatar size={22} />,
		label: 'Identity',
	},
	{
		icon: <HiOutlineFaceSmile size={22} />,
		label: 'Behavior',
	},
	{
		icon: <IoDocumentAttachOutline size={22} />,
		label: 'Knowledge',
	},
	{
		icon: <TfiBoltAlt size={22} />,
		label: 'Actions',
	},
	{
		icon: <BsCloudCheck size={22} />,
		label: 'Deploy',
	},
];
