import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Table } from 'antd';
import { Fragment, useState } from 'react';
import axios from '../../config/axios';
import dayjs from '../../config/dayjs';
import Loader from '../Loader';

export type ChargeHistory = {
	id: number;
	amount: string;
	description: string;
	created_at: Date;
};

const ChargeHistory = () => {
	const [page, setPage] = useState<number>(1);

	// get all billing history
	const { data, isLoading } = useQuery<{
		count: number;
		results: ChargeHistory[];
	}>({
		queryKey: ['charge-history', page],
		queryFn: async () => {
			const { data } = await axios.get(`/billing/charge-histories`, {
				withCredentials: true,
				params: {
					page,
					limit: 10,
				},
			});
			return data;
		},
		placeholderData: keepPreviousData,
	});

	return (
		<Fragment>
			{isLoading && (
				<div className="flex h-[calc(100dvh-20rem)] items-center justify-center">
					<Loader />
				</div>
			)}

			{data && (
				<Table
					dataSource={data.results}
					bordered
					pagination={{
						current: page,
						onChange: (page) => setPage(page),
						total: data.count,
						pageSize: 10,
						showSizeChanger: false,
						showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`,
					}}
					rowKey={(record) => record.id}
					size="middle"
					title={() => <div className="text-xl font-bold">Charge History</div>}
					columns={[
						{
							title: 'Date',
							dataIndex: 'created_at',
							key: 'created_at',
							render: (created_at: Date) => <div className="text-sm font-medium">{dayjs(created_at).format('DD MMM YYYY HH:mm')}</div>,
						},
						{
							title: 'Description',
							dataIndex: 'description',
							key: 'description',
							render: (description: string) => <div className="font-medium">{description}</div>,
						},
						{
							title: 'Amount',
							dataIndex: 'amount',
							key: 'amount',
							render: (amount: string) => <div className="font-medium">${amount}</div>,
						},
					]}
				/>
			)}
		</Fragment>
	);
};

export default ChargeHistory;
