import { PlusOutlined } from '@ant-design/icons';
import { Card as AntdCard, Button } from 'antd';
import { FC, useState } from 'react';
import { cardTypeToImage } from '../../data/data';
import AddFundModal from './add-fund-modal';
import { Card } from './payment-methods';

type PaymentCardProps = {
	card: Card;
};

const PaymentCard: FC<PaymentCardProps> = (props) => {
	const { card } = props;
	const [addFundModalOpen, setAddFundModalOpen] = useState<boolean>(false);

	return (
		<>
			<AntdCard
				styles={{ body: { padding: 14 } }}
				role="button"
			>
				<div className="flex items-center justify-between gap-2">
					<div className="flex items-center gap-4">
						<img
							src={cardTypeToImage(card.card.brand).logo}
							alt={cardTypeToImage(card.card.brand).alt}
							className="w-14"
						/>

						<div>
							<div className="text-base font-semibold leading-5">**** **** **** {card.card.last4}</div>
							<div className="text-sm font-medium">
								Expiry {card.card.exp_month}/{card.card.exp_year}
							</div>
						</div>
					</div>

					<Button
						type="primary"
						onClick={() => setAddFundModalOpen(true)}
						icon={<PlusOutlined />}
					>
						Add Fund
					</Button>
				</div>
			</AntdCard>

			{/* add fund modal */}
			<AddFundModal
				open={addFundModalOpen}
				close={() => setAddFundModalOpen(false)}
				card={card}
			/>
		</>
	);
};

export default PaymentCard;
