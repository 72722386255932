import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button, message } from 'antd';
import { FC, FormEvent } from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { stripePromise } from '../../lib/stripe';

type ConfirmPaymentIntentProps = {
	secret: string;
	closeModal: () => void;
};

const ConfirmPaymentIntent: FC<ConfirmPaymentIntentProps> = (props) => {
	return (
		<Elements
			options={{
				clientSecret: props.secret,
			}}
			stripe={stripePromise}
		>
			<ConfirmationForm
				secret={props.secret}
				closeModal={props.closeModal}
			/>
		</Elements>
	);
};

export default ConfirmPaymentIntent;

const ConfirmationForm = ({ secret, closeModal }: { secret: string; closeModal: () => void }) => {
	const stripe = useStripe();
	const elements = useElements();

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (!stripe || !elements) return;

		stripe.confirmCardPayment(secret, {}).then(() => {
			message.success('Payment successful');
			closeModal();
		});
	};

	return (
		<form
			onSubmit={handleSubmit}
			className="flex flex-col items-center justify-center"
		>
			<div>
				<IoCloseCircleOutline
					size={40}
					color="red"
				/>
			</div>

			<div className="pt-4" />

			<div className="text-center text-base font-medium">
				Sorry, We can't process your payment at the moment. We need an additional verification. Please proceed to complete this transaction.
			</div>

			<div className="pt-6" />

			<Button
				id={'submit'}
				htmlType="submit"
				block
				type="primary"
				size="large"
			>
				Proceed
			</Button>
		</form>
	);
};
