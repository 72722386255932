import { FC, useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/auth.context.tsx';

const AuthenticatedRoute: FC = () => {
	const location = useLocation();
	const { isAuthenticated } = useContext(AuthContext);

	return isAuthenticated ? (
		<Outlet />
	) : (
		<Navigate
			to="/login"
			state={{ from: location }}
			replace
		/>
	);
};

export default AuthenticatedRoute;
