import { create } from 'zustand';

type RuthConnectState = {
	openModalId: string | null;
	setOpenModalId: (id: string | null) => void;
};

export const useRuthConnectStore = create<RuthConnectState>()((set) => ({
	openModalId: null,
	setOpenModalId: (id) => set({ openModalId: id }),
}));
