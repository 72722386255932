export const bookAppointmentParameters = {
	type: 'object',
	properties: {
		calendar_event_name: {
			type: 'string',
			description: 'Name of the calendar event, e.g. "Meeting with John"',
		},
		duration: {
			type: 'number',
			description: 'Duration of the calendar event in minutes, e.g. 60',
		},
		start_date_time: {
			type: 'string',
			description: 'Start date and time of the calendar event in YYYY-MM-DDTHH:MM format, e.g. 2024-06-18T14:00',
		},
		end_date_time: {
			type: 'string',
			description: 'End date and time of the calendar event in YYYY-MM-DDTHH:MM format, e.g. 2024-06-18T15:00',
		},
	},
	required: ['calendar_event_name', 'duration', 'start_date_time', 'end_date_time'],
};

export const checkCalendarAvailabilityParameters = {
	type: 'object',
	properties: {
		start_time: {
			type: 'string',
			description: 'Availability check start date in YYYY-MM-DD format, e.g. 2024-06-18',
		},
		end_time: {
			type: 'string',
			description: 'Availability check end date in YYYY-MM-DD format, e.g. 2024-06-23',
		},
	},
	required: ['start_time', 'end_time'],
};
