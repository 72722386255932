import { FC, useEffect } from 'react';
import { useToolsStore } from '../../store/tools';
import { AIAssistant } from '../../types/ai-assistant.types';
import Tools from '../tools/base/tools';

type AssistantToolsProps = {
	assistant: AIAssistant;
};

const AssistantTools: FC<AssistantToolsProps> = (props) => {
	const { setTools } = useToolsStore();

	useEffect(() => {
		const tools = props.assistant.tools || [];
		const toolsWithId = tools.map((tool) => ({ ...tool, id: crypto.randomUUID() }));
		setTools(toolsWithId);
	}, [props.assistant, setTools]);

	return <Tools for="assistant" />;
};

export default AssistantTools;
