import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import axios from '../../config/axios.ts';
import { stripePromise } from '../../lib/stripe.tsx';

const AddPaymentMethod = () => {
	// refetch payment methods
	const { refetch } = useQuery({
		queryKey: ['payment-methods'],
	});

	const fetchClientSecret = useCallback(async () => {
		try {
			// Create a Checkout Session
			const { data } = await axios.post('/billing/payment-method/init', {}, { withCredentials: true });
			return data.results.client_secret;
		} catch (error) {
			console.error(error);
		} finally {
			refetch();
		}
	}, [refetch]);

	const options = { fetchClientSecret };

	return (
		<div id="checkout">
			<EmbeddedCheckoutProvider
				stripe={stripePromise}
				options={options}
			>
				<EmbeddedCheckout />
			</EmbeddedCheckoutProvider>
		</div>
	);
};

export default AddPaymentMethod;
