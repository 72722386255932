import { FC, Fragment, useState } from 'react';
import ReactPlayer from 'react-player/youtube';
import { Handle, NodeProps, Position } from 'reactflow';
import { cn } from '../../config/cn';
import CustomNodeDrawer from './custom-node-drawer';

const CustomNode: FC<NodeProps> = ({ data }) => {
	const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

	return (
		<Fragment>
			{data.item.static ? (
				<div className="rounded border bg-white p-2 dark:border-[#2d2b38] dark:bg-[#161422]">
					<h3 className="text-base font-medium">How to manage Task Tree</h3>
					<div className="pt-2" />
					<ReactPlayer
						url={data.item.url}
						width={300}
						height={200}
						controls
						loop
						playsinline
						playing
					/>
				</div>
			) : (
				<Fragment>
					<div
						className={cn('rounded border bg-white p-2.5 dark:border-[#2d2b38] dark:bg-[#161422]', {
							'border-rose-600': data.item.prompt === '',
						})}
						onClick={() => {
							setDrawerOpen(true);
						}}
					>
						{data.initial ? <div className={'pb-1.5 text-xs font-semibold text-primary'}>Starting State</div> : null}

						<div className={'flex items-center gap-1 text-gray-600'}>
							<img
								src="/images/ai-assistant/assistant-stars.svg"
								alt="assistant-stars"
							/>

							<div className={'font-medium leading-none text-black-7 dark:text-white'}>{data.item.name}</div>
						</div>

						<Handle
							type="target"
							position={Position.Top}
						/>
						<Handle
							type="source"
							position={Position.Bottom}
						/>
					</div>

					{data.item && (
						<CustomNodeDrawer
							open={drawerOpen}
							close={() => setDrawerOpen(false)}
							data={data.item}
							initial={data.initial}
						/>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export default CustomNode;
