import { Button, Switch, TimePicker } from 'antd';
import { Dispatch, FC, Fragment, useState } from 'react';
import toast from 'react-hot-toast';
import axios from '../../config/axios';
import dayjs from '../../config/dayjs';
import { Campaign, Schedule } from '../../views/campaigns';

type Props = {
	campaign: Campaign;
	setRefresh: Dispatch<React.SetStateAction<number>>;
};

const CampaignSchedules: FC<Props> = (props) => {
	const [campaignSchedules, setCampaignSchedules] = useState<Schedule[]>(() => props.campaign.schedules);
	const [loading, setLoading] = useState(false);

	const handleScheduleChange = async () => {
		const initial = props.campaign.schedules;
		const updated = campaignSchedules;

		// find added, updated and deleted schedules
		const addedSchedules = updated.filter((schedule) => !initial.some((initialSchedule) => initialSchedule.day === schedule.day));
		const updatedSchedules = updated.filter((schedule) =>
			initial.some(
				(initialSchedule) =>
					initialSchedule.day === schedule.day &&
					(initialSchedule.start_time !== schedule.start_time || initialSchedule.end_time !== schedule.end_time),
			),
		);
		const deletedSchedules = initial.filter((schedule) => !updated.some((updatedSchedule) => updatedSchedule.day === schedule.day));

		// if no changes, return toast message
		if ([addedSchedules, updatedSchedules, deletedSchedules].every((schedules) => schedules.length === 0)) {
			return toast.success('Found no changes in the schedules.');
		}

		try {
			setLoading(true);
			const promises = [];

			// Add schedules if any
			if (addedSchedules.length > 0) {
				const addPromises = addedSchedules.map((schedule) =>
					axios.post(`/campaigns/${props.campaign.campaign_id}/schedules`, schedule, { withCredentials: true }),
				);
				promises.push(...addPromises);
			}

			// Update schedules if any
			if (updatedSchedules.length > 0) {
				const updatePromises = updatedSchedules.map((schedule) =>
					axios.patch(`/campaigns/${props.campaign.campaign_id}/schedules/${schedule.campaign_schedule_id}`, schedule, {
						withCredentials: true,
					}),
				);
				promises.push(...updatePromises);
			}

			// Delete schedules if any
			if (deletedSchedules.length > 0) {
				const deletePromises = deletedSchedules.map((schedule) =>
					axios.delete(`/campaigns/${props.campaign.campaign_id}/schedules/${schedule.campaign_schedule_id}`, { withCredentials: true }),
				);
				promises.push(...deletePromises);
			}

			// Only proceed if there are promises to fulfill
			if (promises.length > 0) {
				await Promise.allSettled(promises);
				props.setRefresh((prev) => prev + 1);
				toast.success('Campaign schedules updated.');
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Fragment>
			<div className="text-lg font-semibold text-black-7 dark:text-white">Campaign Schedules</div>

			<div className="pt-3" />

			<section className="flex flex-col gap-5">
				{['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].map((day) => {
					const currentDaySchedule = campaignSchedules.find((schedule) => schedule.day === day);

					return (
						<div key={day}>
							<div className="flex items-center gap-2">
								<div className="text-base font-medium capitalize">{day}</div>
								<Switch
									defaultChecked={campaignSchedules.some((schedule) => schedule.day === day)}
									onChange={(checked) => {
										if (checked) {
											setCampaignSchedules([
												...campaignSchedules,
												{ campaign_schedule_id: crypto.randomUUID(), day, start_time: '09:00:00', end_time: '17:00:00' },
											]);
										} else {
											setCampaignSchedules(campaignSchedules.filter((schedule) => schedule.day !== day));
										}
									}}
									size="small"
								/>
							</div>

							<div className="pt-1" />

							<div className="flex gap-4">
								<div className="space-y-1">
									<div className="pl-0.5 font-medium text-black-7 dark:text-white">Start Time</div>
									<TimePicker
										use12Hours
										placeholder="Start Time"
										className="h-9 font-medium"
										value={currentDaySchedule ? dayjs(currentDaySchedule.start_time, 'HH:mm:ss') : dayjs('09:00:00', 'HH:mm:ss')}
										disabled={!currentDaySchedule}
										showSecond={false}
										allowClear={false}
										inputReadOnly
										minuteStep={5}
										onChange={(time) => {
											setCampaignSchedules((prev) => {
												return prev.map((schedule) => {
													if (schedule.day === day) {
														return {
															...schedule,
															start_time: dayjs(time).format('HH:mm:ss'),
														};
													}

													return schedule;
												});
											});
										}}
									/>
								</div>

								<div className="space-y-1">
									<div className="pl-0.5 font-medium text-black-7 dark:text-white">End Time</div>
									<TimePicker
										use12Hours
										placeholder="End Time"
										value={currentDaySchedule ? dayjs(currentDaySchedule.end_time, 'HH:mm:ss') : dayjs('17:00:00', 'HH:mm:ss')}
										disabled={!currentDaySchedule}
										showSecond={false}
										allowClear={false}
										inputReadOnly
										minuteStep={5}
										className="h-9 font-medium"
										onChange={(time) => {
											setCampaignSchedules((prev) => {
												return prev.map((schedule) => {
													if (schedule.day === day) {
														return {
															...schedule,
															end_time: dayjs(time).format('HH:mm:ss'),
														};
													}

													return schedule;
												});
											});
										}}
									/>
								</div>
							</div>
						</div>
					);
				})}
			</section>

			<div className="pt-6" />

			<Button
				type="primary"
				htmlType="submit"
				className="h-9 text-base font-medium"
				loading={loading}
				disabled={loading}
				onClick={handleScheduleChange}
			>
				Save Changes
			</Button>
		</Fragment>
	);
};

export default CampaignSchedules;
