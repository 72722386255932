import { Button, Modal } from 'antd';
import { FC, useRef, useState } from 'react';
import { IoPauseCircle, IoPlayCircle, IoSwapVertical } from 'react-icons/io5';
import { MdOutlineCheck } from 'react-icons/md';
import { useThemeStore } from '../../store/theme';
import { Voice } from '../../types/ai-assistant.types';

type VoiceModalProps = {
	open: boolean;
	close: () => void;
	voices: Voice[];
	voice: Voice | null;
	setVoice: (value: Voice) => void;
};

const VoiceModal: FC<VoiceModalProps> = (props) => {
	return (
		<Modal
			title={<div className="text-center text-lg font-semibold">Choose Agent Voice</div>}
			open={props.open}
			onCancel={props.close}
			centered
			footer={null}
		>
			<div className="flex flex-col gap-8 pt-4">
				{props.voices.map((voice, index) => (
					<div
						key={index}
						className="flex items-center justify-between"
					>
						<div className="flex items-center gap-3">
							<div className="relative">
								<img
									src={voice.avatar}
									alt="avatar"
									className="size-14 rounded-full object-cover"
								/>

								<VoiceAudioPlayer voice={voice} />
							</div>
							<div>
								<div className="text-lg font-bold">{voice.name} </div>
								<div className="flex items-center gap-1 text-xs">
									<div className="capitalize">{voice.gender}</div>
									<div>&middot;</div>
									<div>Conversational</div>
								</div>
							</div>
						</div>
						{props.voice && props.voice.voice_id === voice.voice_id ? (
							<div className="flex items-center gap-3">
								<div>
									<MdOutlineCheck size={22} />
								</div>
							</div>
						) : (
							<Button
								shape="round"
								size="large"
								icon={<IoSwapVertical />}
								iconPosition="end"
								type="primary"
								onClick={() => {
									props.setVoice(voice);
									props.close();
								}}
							>
								Choose
							</Button>
						)}
					</div>
				))}
			</div>
		</Modal>
	);
};

export default VoiceModal;

const VoiceAudioPlayer: FC<{ voice: Voice }> = ({ voice }) => {
	const audioRef = useRef<HTMLAudioElement | null>(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const { is_dark_mode } = useThemeStore();

	const handlePlay = () => {
		const audio = audioRef.current;
		if (audio) {
			audio.play();
			audio.onplaying = () => {
				setIsPlaying(true);
			};
			audio.onpause = () => {
				setIsPlaying(false);
			};
			audio.onended = () => {
				setIsPlaying(false);
			};
		}
	};

	const handlePause = () => {
		const audio = audioRef.current;
		if (audio) {
			audio.pause();
		}
	};

	return (
		<div className="relative">
			<div
				className="absolute -bottom-1 -left-1 rounded-full bg-white dark:bg-[#1f1f1f]"
				role="button"
			>
				{isPlaying ? (
					<IoPauseCircle
						size={25}
						color={is_dark_mode ? 'white' : 'black'}
						onClick={handlePause}
					/>
				) : (
					<IoPlayCircle
						size={25}
						color={is_dark_mode ? 'white' : 'black'}
						onClick={handlePlay}
					/>
				)}

				{voice.demo_audio_url && (
					<audio
						ref={audioRef}
						src={voice.demo_audio_url}
					/>
				)}
			</div>
		</div>
	);
};
