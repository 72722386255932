import { useMutation } from '@tanstack/react-query';
import { Button, ColorPicker, Input, Popover, Space, Spin } from 'antd';
import { AxiosError } from 'axios';
import { motion } from 'framer-motion';
import { ChangeEvent, FC, Fragment, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { BsBoxSeam } from 'react-icons/bs';
import { FaRegCopy } from 'react-icons/fa6';
import { FiPlus } from 'react-icons/fi';
import { GoCheckCircleFill } from 'react-icons/go';
import { GrRevert } from 'react-icons/gr';
import { ImEmbed2 } from 'react-icons/im';
import { IoInformationCircleOutline, IoRocketOutline } from 'react-icons/io5';
import { TfiBrush } from 'react-icons/tfi';
import { Link } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import axios from '../../config/axios';
import { cn } from '../../config/cn';
import { useThemeStore } from '../../store/theme';
import { colors } from '../../theme/colors';
import { AIAssistant } from '../../types/ai-assistant.types';
import ChatBotEmbedModal from './chatbot-embed-modal';

type AIAssistantChatBotProps = {
	assistant: AIAssistant;
	refetch: () => void;
	refetchAssistant: () => void;
};

type CustomizePayloadType = Pick<AIAssistant, 'chatbot_logo' | 'chatbot_accent_color' | 'chatbot_faq' | 'chatbot_begin_message'>;

const AIAssistantChatBot: FC<AIAssistantChatBotProps> = (props) => {
	// constructing the URL
	const base_url = import.meta.env.VITE_CLIENT_URL;
	const url = `${base_url}/chatbot/${props.assistant.assistant_id}`;

	// states
	const { is_dark_mode } = useThemeStore();
	const [embedModalOpen, setEmbedModalOpen] = useState<boolean>(false);
	const [copied, setCopied] = useState(false);
	const [activeView, setActiveView] = useState<'chatbot' | 'customize'>('chatbot');
	const [chatbotCustomize, setChatbotCustomize] = useState({
		chatbot_logo: props.assistant.chatbot_logo,
		chatbot_accent_color: props.assistant.chatbot_accent_color || colors.primary,
		chatbot_faq: props.assistant.chatbot_faq || [],
		chatbot_begin_message: props.assistant.chatbot_begin_message || null,
	});
	const [uploadLogoLoading, setUploadLogoLoading] = useState<boolean>(false);
	const [errors, setErrors] = useState([] as string[]);
	const { width } = useWindowSize();

	// reset errors after 2 seconds
	useEffect(() => {
		if (errors.length > 0) {
			const timeout = setTimeout(() => {
				setErrors([]);
			}, 2000);

			return () => clearTimeout(timeout);
		}
	}, [errors]);

	// reset copied state after 2 seconds
	useEffect(() => {
		if (copied) {
			const timeout = setTimeout(() => {
				setCopied(false);
			}, 2000);

			return () => clearTimeout(timeout);
		}
	}, [copied]);

	// handle upload chatbot logo
	const handleUploadLogo = async (e: ChangeEvent<HTMLInputElement>) => {
		try {
			setUploadLogoLoading(true);
			const file = e.target.files && e.target.files[0];
			const formData = new FormData();
			formData.append('image', file as File);

			// upload image
			const { data } = await axios.post('/commons/upload-image', formData, { withCredentials: true });

			// update dp
			setChatbotCustomize((prev) => ({ ...prev, chatbot_logo: data.results.secure_url }));
		} catch (error) {
			if (error instanceof AxiosError && error.response) {
				toast.error(error.response.data.msg);
			} else {
				toast.error('Failed to upload profile picture.');
			}
		} finally {
			setUploadLogoLoading(false);
		}
	};

	// chatbot update mutation
	const { mutate, isPending } = useMutation({
		mutationKey: ['update-chatbot', props.assistant.assistant_id],
		mutationFn: async (payload: CustomizePayloadType) => {
			const { data } = await axios.patch(`/ai-assistants/${props.assistant.assistant_id}`, payload, { withCredentials: true });
			return data;
		},
		onSuccess: () => {
			toast.success('Chatbot updated successfully');
			setActiveView('chatbot');
			props.refetchAssistant();
		},
		onError: (error: AxiosError<{ msg: string }>) => {
			if (error.response) {
				setErrors([error.response.data.msg]);
			}
		},
	});

	return (
		<Fragment>
			{activeView === 'chatbot' ? (
				<h2 className="text-lg font-semibold dark:text-white">We've got a chatbot for you! 🤖</h2>
			) : (
				<h2 className="text-lg font-semibold dark:text-white">Customize your chatbot to match your brand! 🎨</h2>
			)}

			<div className="pt-4" />

			{activeView === 'chatbot' && (
				<motion.div
					initial={{ y: 10, opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					exit={{ y: -10, opacity: 0 }}
					transition={{ duration: 0.2 }}
					className="grid grid-cols-1 gap-4 2xl:grid-cols-2"
				>
					<div className="rounded-lg border p-5 dark:border-[#2d2b38]">
						<div className="flex w-fit items-center gap-3">
							<div className="rounded-lg bg-pink-100 p-2">
								<BsBoxSeam size={30} />
							</div>
							<div className="space-y-0.5">
								<div className="text-lg font-semibold dark:text-white">{props.assistant.name}</div>
								<div className="text-sm text-gray-500 dark:text-gray-400">Ready-to-use AI Chatbot</div>
							</div>
						</div>

						<div className="pt-6" />

						<div>
							<label
								htmlFor="url"
								className="font-medium text-gray-600 dark:text-gray-400"
							>
								Public URL
							</label>

							<div className="pt-1" />

							<Input
								placeholder={url}
								value={url}
								size="large"
								readOnly
								suffix={
									<div
										role="button"
										onClick={() => {
											navigator.clipboard.writeText(url);
											setCopied(true);
											toast.success('Copied to clipboard');
										}}
									>
										{copied ? <GoCheckCircleFill color="green" /> : <FaRegCopy />}
									</div>
								}
							/>
						</div>

						<div className="pt-6" />

						<div className="flex flex-wrap gap-4">
							<Link
								to={url}
								target="_blank"
							>
								<Button
									icon={<IoRocketOutline />}
									className="font-medium"
								>
									Preview
								</Button>
							</Link>

							<Button
								icon={<TfiBrush />}
								onClick={() => {
									setActiveView('customize');
								}}
								className="font-medium"
							>
								Customize
							</Button>

							<Button
								icon={<ImEmbed2 />}
								onClick={() => setEmbedModalOpen(true)}
								className="font-medium"
							>
								Embedded
							</Button>
						</div>
					</div>
				</motion.div>
			)}

			{activeView === 'customize' && (
				<motion.div
					initial={{ y: 10, opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					exit={{ y: -10, opacity: 0 }}
					transition={{ duration: 0.2 }}
					className="grid grid-cols-1 gap-4 2xl:grid-cols-2"
				>
					<section>
						<div>
							<div className="flex items-center gap-1">
								<label
									htmlFor="chatbot_logo"
									className="font-medium text-black-7 dark:text-white"
								>
									Chatbot Logo
								</label>

								<Popover
									content={
										<div className="space-y-1">
											<div className="font-medium">1. Please upload logo with proper dimensions for better visibility.</div>
											<div className="font-medium">2. Check the preview to see how your logo looks on the chatbot.</div>
											<div className="font-medium">3. Crop the logo to a square shape for better visibility.</div>
											<div className="font-medium">4. Remove any white spaces around the logo for better visibility.</div>
										</div>
									}
									arrow={false}
									placement={width < 576 ? 'bottom' : 'bottomLeft'}
								>
									<IoInformationCircleOutline
										size={18}
										role="button"
										color={is_dark_mode ? 'white' : 'black'}
									/>
								</Popover>
							</div>

							<div className="pt-2" />

							<div className="relative w-60">
								<Spin
									spinning={uploadLogoLoading}
									size="small"
								>
									{chatbotCustomize.chatbot_logo ? (
										<div className="rounded-lg border p-1 dark:border-[#2d2b38]">
											<img
												src={chatbotCustomize.chatbot_logo}
												alt="logo"
												className="h-20 w-full rounded-lg object-contain"
											/>
										</div>
									) : (
										<div className="h-20 w-full rounded-lg bg-gray-200 dark:bg-[#313131]" />
									)}
								</Spin>

								<label
									className="absolute -bottom-1 -right-1 flex items-center justify-center rounded-full border bg-white p-1 dark:border-[#353050] dark:bg-[#161422]"
									role="button"
									htmlFor="chatbot_logo"
									title="Edit AI Assistant Avatar"
								>
									<img
										src={is_dark_mode ? '/images/ai-assistant/edit-icon-dark.svg' : '/images/ai-assistant/edit-icon.svg'}
										alt="edit-icon"
										className="size-4"
									/>
								</label>

								<input
									type="file"
									id={'chatbot_logo'}
									className={'hidden'}
									onChange={handleUploadLogo}
								/>
							</div>
						</div>

						<div className="pt-6" />

						<div>
							<label
								htmlFor="chatbot_accent_color"
								className="font-medium text-black-7 dark:text-white"
							>
								Chatbot Accent Color
							</label>

							<div className="pt-2" />

							<ColorPicker
								defaultValue={chatbotCustomize.chatbot_accent_color}
								showText
								format="hex"
								onChange={(color) => {
									setChatbotCustomize((prev) => ({ ...prev, chatbot_accent_color: color.toHexString() }));
								}}
							/>
						</div>

						<div className="pt-6" />

						<div>
							<label
								htmlFor="chatbot_begin_message"
								className="font-medium text-black-7 dark:text-white"
							>
								Chatbot Begin Message
							</label>

							<div className="pt-2" />

							<Input
								placeholder="Chatbot Begin Message"
								value={chatbotCustomize.chatbot_begin_message as string}
								onChange={(e) => {
									setChatbotCustomize((prev) => ({ ...prev, chatbot_begin_message: e.target.value }));
								}}
							/>
						</div>

						<div className="pt-6" />

						<div>
							<label
								htmlFor="chatbot_faq"
								className="font-medium text-black-7 dark:text-white"
							>
								Chatbot FAQ's
							</label>

							<div className="pt-2" />

							<div className="flex flex-col gap-3">
								{chatbotCustomize.chatbot_faq.map((faq, index) => (
									<div
										key={index}
										className="flex flex-col gap-2 rounded-lg border px-3 py-2 dark:border-[#2d2b38]"
									>
										<div className="flex flex-col gap-3">
											<div>
												<div className="dark:text-white">Question</div>

												<div className="pt-2" />

												<Input
													placeholder="Question"
													value={faq.question}
													onChange={(e) => {
														const newFaq = [...chatbotCustomize.chatbot_faq];
														newFaq[index].question = e.target.value;
														setChatbotCustomize((prev) => ({ ...prev, chatbot_faq: newFaq }));
													}}
												/>
											</div>

											<div>
												<div className="dark:text-white">Answer</div>

												<div className="pt-2" />

												<Input.TextArea
													placeholder="Answer"
													value={faq.answer}
													onChange={(e) => {
														const newFaq = [...chatbotCustomize.chatbot_faq];
														newFaq[index].answer = e.target.value;
														setChatbotCustomize((prev) => ({ ...prev, chatbot_faq: newFaq }));
													}}
												/>
											</div>
										</div>

										<div
											onClick={() => {
												const newFaq = [...chatbotCustomize.chatbot_faq];
												newFaq.splice(index, 1);
												setChatbotCustomize((prev) => ({ ...prev, chatbot_faq: newFaq }));
											}}
											className="w-fit pt-1 font-medium text-red-500 hover:text-red-600"
											role="button"
										>
											Delete
										</div>
									</div>
								))}
							</div>

							<div
								className={cn('pt-2', {
									'pt-4': chatbotCustomize.chatbot_faq.length > 0,
								})}
							/>

							<Button
								icon={<FiPlus />}
								type="primary"
								onClick={() => {
									setChatbotCustomize((prev) => ({
										...prev,
										chatbot_faq: [...prev.chatbot_faq, { question: '', answer: '' }],
									}));
								}}
							>
								Add FAQ
							</Button>
						</div>

						<div
							className={cn('pt-4', {
								'pt-0': errors.length === 0,
							})}
						>
							{errors.map((error, index) => (
								<div
									key={index}
									className="font-medium text-red-500"
								>
									{error}
								</div>
							))}
						</div>

						<div className="pt-6" />

						<Space size="middle">
							<Button
								icon={<GrRevert />}
								type="dashed"
								className="h-10 font-medium"
								onClick={() => setActiveView('chatbot')}
							>
								Back
							</Button>

							<Button
								icon={
									<img
										src="/images/ai-assistant/save-icon.svg"
										alt="save-icon"
									/>
								}
								type="primary"
								className="h-10 font-medium"
								loading={isPending}
								disabled={isPending}
								onClick={() => {
									const payload = chatbotCustomize;

									// validate
									if (!payload.chatbot_logo) {
										setErrors(['Chatbot logo is required.']);
										return;
									}

									// check if all faq's are filled
									const faqErrors = payload.chatbot_faq.filter((faq) => !faq.question || !faq.answer);
									if (faqErrors.length > 0) {
										setErrors(['Please fill in all FAQ fields.']);
										return;
									}

									// call mutation
									mutate(payload);
								}}
							>
								Save Changes
							</Button>
						</Space>
					</section>
				</motion.div>
			)}

			{/* embed modal */}
			<ChatBotEmbedModal
				open={embedModalOpen}
				close={() => setEmbedModalOpen(false)}
				base_url={base_url}
				url={url}
				assistant={props.assistant}
			/>
		</Fragment>
	);
};

export default AIAssistantChatBot;
