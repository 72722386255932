import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Space } from 'antd';
import { AxiosError } from 'axios';
import { FC, useState } from 'react';
import toast from 'react-hot-toast';
import axios from '../../config/axios';
import { AIAssistant, PostAnalysis } from '../../types/ai-assistant.types';
import { getDescriptionPlaceholder, getNamePlaceholder, getTitle } from './post-analysis-utils';

type Props = {
	open: boolean;
	close: () => void;
	assistant: AIAssistant;
	type: PostAnalysis['type'];
	refetchAssistant: () => void;
};

const AddPostAnalysisModal: FC<Props> = (props) => {
	const [loading, setLoading] = useState(false);

	return (
		<Modal
			open={props.open}
			onCancel={props.close}
			centered
			footer={null}
			title={getTitle(props.type)}
			destroyOnClose
		>
			<Form
				layout="vertical"
				onFinish={async (values) => {
					// prepare object
					const postAnalysis: PostAnalysis = {
						type: props.type,
						name: values.name,
						description: values.description,
						choices: values.choices,
					};

					if (props.type !== 'enum') delete postAnalysis.choices;

					// get the existing post analysis
					const existingPostAnalysis = props.assistant.post_analysis || [];

					// add the new post analysis
					const newPostAnalysis = [...existingPostAnalysis, postAnalysis];

					// update the assistant
					try {
						setLoading(true);
						await axios.patch(
							`/ai-assistants/${props.assistant.assistant_id}`,
							{
								post_analysis: newPostAnalysis,
							},
							{ withCredentials: true },
						);
						props.refetchAssistant();
						props.close();
					} catch (error) {
						if (error instanceof AxiosError && error.response) {
							toast.error(error.response.data.msg);
						} else {
							toast.error('Internal Server Error.');
						}
					} finally {
						setLoading(false);
					}
				}}
				requiredMark={false}
			>
				<Form.Item
					label={<div className="font-medium">Name</div>}
					name="name"
					hasFeedback
				>
					<Input
						required
						placeholder={getNamePlaceholder(props.type)}
						size="large"
					/>
				</Form.Item>

				<Form.Item
					label={<div className="font-medium">Description</div>}
					name="description"
					hasFeedback
				>
					<Input.TextArea
						required
						placeholder={getDescriptionPlaceholder(props.type)}
						rows={4}
					/>
				</Form.Item>

				{props.type === 'enum' && (
					<Form.Item
						label={<div className="font-medium">Choices</div>}
						name="choices"
						className="mb-0"
					>
						<Form.List
							name="choices"
							initialValue={['']}
						>
							{(fields, { add, remove }) => (
								<>
									{fields.map((field) => {
										const { key, ...restField } = field;
										return (
											<Form.Item
												required={false}
												key={key}
												className="mb-4 w-full"
											>
												<div className="flex items-center gap-3">
													<Form.Item
														{...restField}
														validateTrigger={['onChange', 'onBlur']}
														noStyle
														hasFeedback
													>
														<Input
															placeholder="first_time_customer"
															required
															size="large"
															className="w-full"
														/>
													</Form.Item>

													{fields.length > 1 ? (
														<DeleteOutlined
															className="dynamic-delete-button text-xl text-red-600"
															onClick={() => remove(field.name)}
														/>
													) : null}
												</div>
											</Form.Item>
										);
									})}
									<Form.Item>
										<Button
											onClick={() => add()}
											className="w-fit"
											icon={<PlusOutlined />}
										>
											Add
										</Button>
									</Form.Item>
								</>
							)}
						</Form.List>
					</Form.Item>
				)}

				<Form.Item className="mb-0 flex justify-end">
					<Space>
						<Button
							onClick={props.close}
							size="large"
						>
							Cancel
						</Button>
						<Button
							type="primary"
							htmlType="submit"
							size="large"
							loading={loading}
							disabled={loading}
						>
							Save
						</Button>
					</Space>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default AddPostAnalysisModal;
