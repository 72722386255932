import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { FC } from 'react';
import { HiSpeakerWave } from 'react-icons/hi2';
import { MdPhoneCallback } from 'react-icons/md';

type Props = {
	open: boolean;
	close: () => void;
};

const AssistantDescriptionModal: FC<Props> = (props) => {
	return (
		<Modal
			width={800}
			open={props.open}
			footer={null}
			onCancel={props.close}
			centered
		>
			<div className="pt-8" />

			<div className={'col-span-3 flex flex-wrap items-center justify-between gap-6 p-0'}>
				<div className={'relative flex items-center gap-2'}>
					<div className={'absolute bottom-0 rounded-full bg-primary p-1 text-white outline outline-white'}>
						<HiSpeakerWave size={10} />
					</div>
					<img
						src="https://avatar.iran.liara.run/public?username=sdf"
						className={'h-12 w-12'}
						alt=""
					/>
					<div className={'flex flex-col'}>
						<span className={'text-lg'}>Paul</span>
						<span className={'text-gray-400'}>Real Estate Agent</span>
					</div>
				</div>
				<div className={'flex items-center gap-2'}>
					<Button type={'dashed'}>
						<div className={'flex items-center gap-2'}>
							<MdPhoneCallback size={20} />
							<span>Give it a try</span>
						</div>
					</Button>
					<Button type={'primary'}>
						<div className={'flex items-center gap-2'}>
							<PlusOutlined size={20} />
							<span>Create Assistant</span>
						</div>
					</Button>
				</div>
			</div>

			<div className="pt-6" />

			<div className={'grid grid-cols-3 rounded-2xl border border-gray-300 dark:border-[#2d2b38]'}>
				<div className={'col-span-3 flex flex-col gap-2 p-6 pb-0 md:col-span-2 md:pb-6'}>
					<span>Description</span>
					<span className={'text-gray-400'}>
						With a commitment to personalized service, Paul engages potential buyers or renters with professionalism. Through active
						listening and direct questioning, he gathers essential information to understand your needs and preferences thoroughly.
					</span>
				</div>
				<div className="col-span-3 md:col-span-1">
					<ul className={'flex flex-col gap-4 border-gray-300 p-6 md:border-l dark:border-[#2d2b38]'}>
						<li className={'flex flex-col gap-1 border-b border-gray-200 pb-4 dark:border-[#2d2b38]'}>
							<span>AI Model</span>
							<span className={'text-gray-400'}>Puretalk Turbo</span>
						</li>
						<li className={'flex flex-col gap-1 border-b border-gray-200 pb-4 dark:border-[#2d2b38]'}>
							<span>Voice Synthesizer</span>
							<div className={'flex items-center gap-2'}>
								<img
									src="https://avatar.iran.liara.run/public?username=dsfdf"
									alt=""
									className={'h-5 w-5'}
								/>
								<span className={'text-gray-400'}>Puretalk TTS Gabby</span>
							</div>
						</li>
						<li className={'flex flex-col gap-1 pb-0'}>
							<span>Language</span>
							<span className={'text-gray-400'}>English - US</span>
						</li>
					</ul>
				</div>
			</div>
		</Modal>
	);
};

export default AssistantDescriptionModal;
