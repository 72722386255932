import { Button, Drawer, Input } from 'antd';
import { FC, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { useReactFlow } from 'reactflow';
import { useThemeStore } from '../../store/theme';
import { LlmStates } from '../../types/ai-assistant.types';
import LLMStatesTools from './llm-states-tools';
import { cn } from '../../config/cn';

type Props = {
	open: boolean;
	close: () => void;
	data: LlmStates;
	initial: boolean;
};

const CustomNodeDrawer: FC<Props> = (props) => {
	const [showUpdateName, setShowUpdateName] = useState<boolean>(false);
	const [name, setName] = useState(() => props.data.name);
	const [prompt, setPrompt] = useState(() => props.data.prompt);
	const [nameError, setNameError] = useState<string | null>(null);
	const { setNodes, setEdges } = useReactFlow();
	const { is_dark_mode } = useThemeStore();

	const name_input_ref = useRef(null);
	useClickAway(name_input_ref, () => {
		setShowUpdateName(false);
		setNameError(null);
		setNodes((prev) => {
			const newNodes = prev.map((node) => {
				if (node.id === props.data.state_id) {
					return {
						...node,
						data: {
							...node.data,
							item: {
								...node.data.item,
								name: name,
							},
						},
					};
				}
				return node;
			});
			return newNodes;
		});
	});

	// handle Set Starting State function
	const handleSetStartingState = () => {
		setNodes((prev) => {
			const newNodes = prev.map((node) => {
				if (node.id === props.data.state_id) {
					return {
						...node,
						data: {
							...node.data,
							initial: true,
						},
					};
				} else {
					return {
						...node,
						data: {
							...node.data,
							initial: false,
						},
					};
				}
			});
			return newNodes;
		});
	};

	// handle delete node function
	const handleDeleteNode = () => {
		const nodeId = props.data.state_id;

		setNodes((prev) => {
			const newNodes = prev.filter((node) => node.id !== nodeId);
			return newNodes;
		});

		setEdges((prev) => {
			const newEdges = prev.filter((edge) => edge.source !== nodeId && edge.target !== nodeId);
			return newEdges;
		});
	};

	return (
		<Drawer
			title={
				<div
					className={cn('flex flex-wrap items-center gap-3 px-1', {
						'justify-end min-[520px]:justify-between': !props.initial,
						'justify-between': props.initial,
					})}
				>
					<div className="flex items-center gap-3">
						{showUpdateName ? (
							<div className="flex flex-col">
								<input
									ref={name_input_ref}
									autoFocus
									type="text"
									value={name}
									onChange={(e) => {
										setName(e.target.value);
										setNameError(null);
									}}
									onKeyDown={(e) => {
										if (e.key === ' ') {
											e.preventDefault();
											setNameError('No spaces allowed. Allowed characters: a-z, A-Z, 0-9, _, and -.');
										}
									}}
									onFocus={(e) => {
										e.target.select();
									}}
									className="w-fit-content border-b border-gray-300 pb-px pl-1 text-lg focus:border-primary focus:outline-none dark:bg-[#1b1827] dark:focus:border-transparent"
								/>
								{nameError && <div className="pt-2 text-xs text-red-500">{nameError}</div>}
							</div>
						) : (
							<h3 className="text-lg font-medium text-black-7 dark:text-white">{props.data.name}</h3>
						)}

						{showUpdateName === false && (
							<button
								onClick={() => {
									setShowUpdateName(true);
								}}
							>
								<img
									src={is_dark_mode ? '/images/ai-assistant/edit-icon-dark.svg' : '/images/ai-assistant/edit-icon.svg'}
									alt="edit-icon"
								/>
							</button>
						)}
					</div>

					<div className="flex items-center gap-2">
						{props.initial ? null : (
							<Button
								onClick={handleSetStartingState}
								icon={
									<img
										src={is_dark_mode ? '/images/ai-assistant/plus-icon-dark.svg' : '/images/ai-assistant/plus-icon.svg'}
										alt="plus-icon"
									/>
								}
								size="large"
								className="font-medium"
							>
								Set as starting state
							</Button>
						)}

						<img
							src={is_dark_mode ? '/images/ai-assistant/delete-icon-dark.svg' : '/images/ai-assistant/delete-icon.svg'}
							alt="delete-icon"
							title="Delete AI Assistant"
							onClick={() => {
								handleDeleteNode();
							}}
							role="button"
						/>
					</div>
				</div>
			}
			onClose={props.close}
			open={props.open}
			destroyOnClose
			closeIcon={
				<img
					src={
						is_dark_mode
							? '/images/ai-assistant/multi-task-tree-drawer-back-icon-dark.svg'
							: '/images/ai-assistant/multi-task-tree-drawer-back-icon-light.svg'
					}
					alt="close"
				/>
			}
			size="large"
		>
			<div className="text-base font-bold text-black-7 dark:text-white">Task</div>

			<div className="pt-3" />

			<Input.TextArea
				rows={14}
				required
				placeholder="Type in details of the prompt here..."
				value={prompt}
				autoSize={{ maxRows: 14 }}
				styles={{
					textarea: {
						fontSize: 15,
						scrollbarWidth: 'thin',
					},
				}}
				onChange={(e) => {
					setPrompt(e.target.value);

					setNodes((prev) => {
						const newNodes = prev.map((node) => {
							if (node.id === props.data.state_id) {
								return {
									...node,
									data: {
										...node.data,
										item: {
											...node.data.item,
											prompt: e.target.value,
										},
									},
								};
							}
							return node;
						});
						return newNodes;
					});
				}}
			/>

			<div className="py-3" />

			<LLMStatesTools llm_states={props.data} />

			{/* TODO: will change by this later */}
			{/* <Tools for="state" /> */}
		</Drawer>
	);
};

export default CustomNodeDrawer;
