import { Card } from 'antd';
import { FC, useState } from 'react';
import toast from 'react-hot-toast';
import { FaRegCopy } from 'react-icons/fa6';
import { GoCheckCircleFill } from 'react-icons/go';
import { CallLog } from '../../views/call-logs.tsx';

type Props = {
	history: CallLog;
};

const CallInformationCard: FC<Props> = ({ history }) => {
	const [copied, setCopied] = useState<boolean>(false);
	const [copiedId, setCopiedId] = useState<string | null>(null);

	return (
		<Card styles={{ body: { padding: 16 } }}>
			<h2 className="text-xl font-semibold text-black-7 dark:text-white">Call Information</h2>

			<div className="pt-3" />

			<div className="space-y-3">
				{[
					{
						label: 'Call ID',
						value: history.call_id,
					},
					{
						label: 'Agent ID',
						value: history.assistant_id,
					},
					{
						label: 'Assistant Name',
						value: history.assistant_name,
					},

					{
						label: 'Voice Name',
						value: history.voice_name,
					},
					{
						...(history.from_number && {
							label: 'From',
							value: history.from_number,
						}),
					},
					{
						...(history.to_number && {
							label: 'To',
							value: history.to_number,
						}),
					},
				].map((item, i) => (
					<div
						key={i}
						className="space-y-0.5"
					>
						<div className="text-base font-medium text-black-7 dark:text-white">{item.label}</div>
						<div className="flex items-center gap-2">
							<div className="text-sm font-medium text-black-3">{item.value}</div>
							{['Call ID', 'Agent ID', 'From', 'To'].includes(item.label as string) ? (
								<div
									onClick={async () => {
										await navigator.clipboard.writeText(item.value as string);
										setCopied(true);
										setCopiedId(item.value as string);
										toast.success('Copied to clipboard.');
									}}
									role="button"
								>
									<div className="text-sm text-gray-500">
										{copied && copiedId === item.value ? <GoCheckCircleFill color="green" /> : <FaRegCopy />}
									</div>
								</div>
							) : null}
						</div>
					</div>
				))}
			</div>
		</Card>
	);
};

export default CallInformationCard;
