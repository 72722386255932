import { Fragment, useState } from 'react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, getBezierPath } from 'reactflow';
import { cn } from '../../config/cn';
import CustomEdgeDrawer from './custom-edge-drawer';

const CustomEdge = ({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style = {}, markerEnd, data }: EdgeProps) => {
	const [edgePath, labelX, labelY] = getBezierPath({
		sourceX,
		sourceY,
		sourcePosition,
		targetX,
		targetY,
		targetPosition,
	});
	const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
	const [description, setDescription] = useState(() => data.description);

	return (
		<Fragment>
			<BaseEdge
				path={edgePath}
				markerEnd={markerEnd}
				style={style}
			/>
			<EdgeLabelRenderer>
				<div
					style={{
						position: 'absolute',
						transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
						pointerEvents: 'all',
					}}
					className={cn(
						'nodrag nopan rounded-full border bg-white px-2 py-1 text-xs text-gray-600 dark:border-[#2d2b38] dark:bg-[#161422] dark:text-gray-300',
						{
							'border-rose-600': description === '',
						},
					)}
				>
					<button
						onClick={() => {
							setDrawerOpen(true);
						}}
					>
						Edge
					</button>
				</div>
			</EdgeLabelRenderer>

			<CustomEdgeDrawer
				open={drawerOpen}
				close={() => setDrawerOpen(false)}
				data={data}
				id={id}
				description={description}
				setDescription={setDescription}
			/>
		</Fragment>
	);
};

export default CustomEdge;
