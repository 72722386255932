import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Space } from 'antd';
import { AxiosError } from 'axios';
import { FC, useState } from 'react';
import toast from 'react-hot-toast';
import axios from '../../config/axios';
import { AIAssistant, PostAnalysis } from '../../types/ai-assistant.types';
import { getDescriptionPlaceholder, getNamePlaceholder } from './post-analysis-utils';

type Props = {
	assistant: AIAssistant;
	analysis: PostAnalysis;
	index: number;
	refetchAssistant: () => void;
};

const UpdatePostAnalysis: FC<Props> = (props) => {
	const { analysis, index, assistant } = props;
	const [loading, setLoading] = useState(false);

	return (
		<Form
			layout="vertical"
			onFinish={async (values) => {
				// prepare object
				const postAnalysis: PostAnalysis = {
					type: analysis.type,
					name: values.name,
					description: values.description,
					choices: values.choices,
				};

				if (analysis.type !== 'enum') delete postAnalysis.choices;

				// get post analysis array
				const postAnalysisArray = [...(assistant.post_analysis as PostAnalysis[])];

				// update the selected post analysis
				postAnalysisArray[index] = postAnalysis;

				// update the assistant with the new post analysis array
				try {
					setLoading(true);
					await axios.patch(
						`/ai-assistants/${assistant.assistant_id}`,
						{
							post_analysis: postAnalysisArray,
						},
						{ withCredentials: true },
					);
					Modal.destroyAll();
					props.refetchAssistant();
					toast.success('Post Analysis Updated Successfully.');
				} catch (error) {
					if (error instanceof AxiosError && error.response) {
						toast.error(error.response.data.msg);
					} else {
						toast.error('Internal Server Error.');
					}
				} finally {
					setLoading(false);
				}
			}}
			requiredMark={false}
			initialValues={analysis}
		>
			<Form.Item
				label={<div className="font-medium">Name</div>}
				name="name"
				hasFeedback
			>
				<Input
					required
					placeholder={getNamePlaceholder(analysis.type)}
					size="large"
				/>
			</Form.Item>

			<Form.Item
				label={<div className="font-medium">Description</div>}
				name="description"
				hasFeedback
			>
				<Input.TextArea
					required
					placeholder={getDescriptionPlaceholder(analysis.type)}
					rows={4}
				/>
			</Form.Item>

			{analysis.type === 'enum' && (
				<Form.Item
					label={<div className="font-medium">Choices</div>}
					name="choices"
					className="mb-0"
				>
					<Form.List name="choices">
						{(fields, { add, remove }) => (
							<>
								{fields.map((field) => {
									const { key, ...restField } = field;
									return (
										<Form.Item
											required={false}
											key={key}
											className="mb-4 w-full"
										>
											<div className="flex items-center gap-3">
												<Form.Item
													{...restField}
													validateTrigger={['onChange', 'onBlur']}
													noStyle
													hasFeedback
												>
													<Input
														placeholder="first_time_customer"
														required
														size="large"
														className="w-full"
													/>
												</Form.Item>

												{fields.length > 1 ? (
													<DeleteOutlined
														className="dynamic-delete-button text-xl text-red-600"
														onClick={() => remove(field.name)}
													/>
												) : null}
											</div>
										</Form.Item>
									);
								})}

								<Form.Item>
									<Button
										onClick={() => add()}
										className="w-fit"
										icon={<PlusOutlined />}
									>
										Add
									</Button>
								</Form.Item>
							</>
						)}
					</Form.List>
				</Form.Item>
			)}

			<Form.Item className="mb-0 flex justify-end">
				<Space>
					<Button
						onClick={() => Modal.destroyAll()}
						size="large"
					>
						Cancel
					</Button>

					<Button
						type="primary"
						htmlType="submit"
						size="large"
						loading={loading}
						disabled={loading}
					>
						Update
					</Button>
				</Space>
			</Form.Item>
		</Form>
	);
};

export default UpdatePostAnalysis;
