import { Divider, Drawer } from 'antd';
import { FC } from 'react';
import { ChatLog } from '../../views/chat-logs';
import dayjs from '../../config/dayjs';
import ChatInformationCard from './chat-information-card';
import ChatCountCard from './chat-count-card.tsx';
import ChatTranscriptCard from './chat-transcript-card.tsx';

type Props = {
	open: boolean;
	close: () => void;
	history: ChatLog;
};

const ChatLogsDrawer: FC<Props> = (props) => {
	return (
		<Drawer
			open={props.open}
			onClose={props.close}
			destroyOnClose={true}
			title={`${props.history.assistant_name} - ${dayjs(props.history.created_at).format('YYYY-MM-DD hh:mm')}`}
			size={'large'}
		>
			<div className="flex items-center justify-between gap-1">
				<section>
					<h2 className="text-xl font-bold capitalize dark:text-white">{props.history.assistant_name}</h2>

					<div className="text-sm font-medium text-black-3">{dayjs(props.history.created_at).format('YYYY-MM-DD hh:mm')}</div>
				</section>
			</div>

			<Divider />

			<ChatInformationCard history={props.history} />

			<div className="pt-6" />

			<ChatCountCard history={props.history} />

			<div className="pt-6" />

			<ChatTranscriptCard history={props.history} />
		</Drawer>
	);
};

export default ChatLogsDrawer;
