import { useMutation, useQuery } from '@tanstack/react-query';
import { Button, Input, message } from 'antd';
import { AxiosError } from 'axios';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { IoMdRadioButtonOn } from 'react-icons/io';
import { MdOutlineEvent } from 'react-icons/md';
import axios from '../../config/axios';
import { useRuthConnectStore } from '../../store/ruth-connect';
import { Integration } from './ruth-connect-types';

type EventType = {
	id: string;
	title: string;
};

const CalConnectUI = () => {
	const { setOpenModalId } = useRuthConnectStore();
	const [formData, setFormData] = useState({
		api_key: '',
		username: '',
		event_type_id: '',
	});
	const [step, setStep] = useState(1);
	const [loading, setLoading] = useState({
		eventTypes: false,
		connect: false,
	});
	const [eventTypes, setEventTypes] = useState<EventType[]>([]);

	const { refetch } = useQuery<Integration[]>({
		queryKey: ['connected-apps'],
	});

	// connect cal.com mutation
	const { mutate, isPending } = useMutation({
		mutationFn: async (payload: unknown) => {
			await axios.post('/ruth-connect/integrations', payload, { withCredentials: true });
		},
		onSuccess: () => {
			message.success('Cal.com connected successfully.');
			setOpenModalId(null);
			refetch();
		},
		onError: (error) => {
			if (error instanceof AxiosError && error.response) {
				return toast.error(error.response.data.msg);
			}
			toast.error('An error occurred. Please try again.');
		},
	});

	return (
		<>
			{step === 1 && (
				<form
					className="flex flex-col gap-4"
					onSubmit={async (e) => {
						e.preventDefault();

						try {
							setLoading({ ...loading, eventTypes: true });
							const { data } = await axios.get('/ruth-connect/integrations/cal-dot-com/event-types', {
								withCredentials: true,
								params: {
									api_key: formData.api_key,
									username: formData.username,
								},
							});
							setEventTypes(data.results);
							setStep(2);
						} catch (error) {
							if (error instanceof AxiosError && error.response) {
								return toast.error(error.response.data.msg);
							}
							toast.error('An error occurred. Please try again.');
						} finally {
							setLoading({ ...loading, eventTypes: false });
						}
					}}
				>
					<div className="flex flex-col gap-2">
						<label htmlFor="api_key">Cal.com API Key</label>
						<Input.TextArea
							autoSize
							size="large"
							value={formData.api_key}
							required
							onChange={(e) => setFormData({ ...formData, api_key: e.target.value })}
						/>
					</div>

					<div className="flex flex-col gap-2">
						<label htmlFor="username">Cal.com Username</label>
						<Input
							size="large"
							value={formData.username}
							required
							onChange={(e) => setFormData({ ...formData, username: e.target.value })}
						/>
					</div>

					<Button
						type="primary"
						htmlType="submit"
						className="mt-1"
						size='large'
						loading={loading.eventTypes}
						disabled={loading.eventTypes}
					>
						Next
					</Button>
				</form>
			)}

			{step === 2 && (
				<div>
					{eventTypes.length === 0 && (
						<div className="flex h-40 items-center justify-center text-center">
							<p>No event types found.</p>
						</div>
					)}

					{eventTypes.length > 0 && (
						<>
							<h2 className="text-base font-medium">Select Event Type</h2>

							<div className="pt-3" />

							<div className="flex flex-col gap-4">
								{eventTypes.map((eventType, i) => (
									<div
										key={i}
										className="flex items-center justify-between gap-4 rounded-md border px-3 py-2 dark:border-dark-border"
										onClick={() => setFormData({ ...formData, event_type_id: eventType.id })}
										role="button"
									>
										<div className="flex items-center gap-2">
											<div>
												<MdOutlineEvent size={24} />
											</div>
											<div>
												<div className="text-base font-medium dark:text-white">{eventType.title}</div>
												<div className="text-sm text-gray-600 dark:text-gray-400">Event ID: {eventType.id}</div>
											</div>
										</div>

										{eventType.id === formData.event_type_id && (
											<div>
												<IoMdRadioButtonOn size={20} />
											</div>
										)}
									</div>
								))}
							</div>

							<div className="pt-4" />

							<Button
								type="primary"
								loading={isPending}
								disabled={isPending}
								block
								size="large"
								onClick={() => {
									if (!formData.event_type_id) {
										return toast.error('Please select an event type.');
									}

									// submit form
									const payload = {
										app_name: 'cal.com',
										friendly_app_name: 'cal.com',
										logo: 'https://puretalkstorageprod.blob.core.windows.net/puretalk-public/puretalk-b366280a-ce3b-4296-b6bb-64b83e3737dc-svg',
										data: formData,
										tools: ['cal_dot_com_book_on_calendar'],
									};

									// mutate integration
									mutate(payload);
								}}
							>
								Connect
							</Button>
						</>
					)}
				</div>
			)}
		</>
	);
};

export default CalConnectUI;
