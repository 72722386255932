import { CaretRightOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Divider, Form, Input, Skeleton } from 'antd';
import EmojiPicker from 'emoji-picker-react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useClickAway } from 'react-use';
import axios from '../config/axios';
import { cn } from '../config/cn';
import { colors } from '../theme/colors';
import { AIAssistant } from '../types/ai-assistant.types';

type Message = {
	role: string;
	content: string | null;
};

type Config = Pick<AIAssistant, 'chatbot_logo' | 'chatbot_accent_color' | 'chatbot_faq' | 'name'>;

const ChatBot = () => {
	const { id } = useParams<{ id: string }>();
	const [send_data_form] = Form.useForm();
	const [messages, setMessages] = useState<Message[]>([]);
	const messagesEndRef = useRef<HTMLDivElement | null>(null);
	const [assistantMessageStream, setAssistantMessageStream] = useState<string>('');
	const [step, setStep] = useState<number>(1);
	const [filteredFAQ, setFilteredFAQ] = useState<Config['chatbot_faq'] | null>(null);
	const [openEmojiPicker, setOpenEmojiPicker] = useState<boolean>(false);
	const [followUpQuestions, setFollowUpQuestions] = useState<string[]>([]);
	const ws = useRef<WebSocket | null>(null);

	// close emoji picker when clicked outside
	const emojiPickerRef = useRef(null);
	useClickAway(emojiPickerRef, () => {
		setOpenEmojiPicker(false);
	});

	useEffect(() => {
		setTimeout(() => {
			if (messagesEndRef.current) {
				messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
			}
		}, 0); // Timeout set to 0 will execute after any running script is finished
	}, [messages, assistantMessageStream]);

	//websocket
	useEffect(() => {
		// Create a new WebSocket connection
		const websocket = new WebSocket(
			import.meta.env.VITE_ENV === 'production'
				? `wss://core.puretalk.ai/chatbot/${id}`
				: `wss://puretalk-ai-agent-server-hllad.ondigitalocean.app/chatbot/${id}`,
		);

		// Set the WebSocket connection to state
		ws.current = websocket;

		let messageStream: string = '';

		// Handle incoming messages
		websocket.onmessage = (event) => {
			const message = JSON.parse(event.data);

			if (message.event === 'chatSessionId') return;

			if (message.data !== null) {
				setAssistantMessageStream((assistantMessageStream) => assistantMessageStream + message.data);
				messageStream += message.data;
			} else {
				const msg: string = messageStream;
				setMessages((prev) => [
					...prev,
					{
						role: 'assistant',
						content: msg,
					},
				]);
				setAssistantMessageStream('');
				messageStream = '';
			}
		};

		// Clean up the WebSocket connection when the component unmounts
		return () => {
			websocket.close();
		};
	}, [id]);

	// send message to ws server
	const sendMessage = (msg: string) => {
		if (ws.current) {
			ws.current.send(msg);
		}
	};

	// get chatbot config
	const { data: config, isLoading: configLoading } = useQuery<Config>({
		queryKey: ['config', id],
		queryFn: async () => {
			const { data } = await axios.get(`/public/ai-assistants/chatbot-config/${id}`);
			setFilteredFAQ(data.results.chatbot_faq);
			return data.results;
		},
	});

	// follow up questions
	useEffect(() => {
		if (config?.chatbot_faq && step === 2) {
			const questions = config.chatbot_faq.map((faq) => faq.question);
			const randomQuestions = questions.sort(() => Math.random() - Math.random()).slice(0, 2);
			setFollowUpQuestions((prev) => (messages.length < 4 ? [...prev, ...randomQuestions.filter((q) => !prev.includes(q))].slice(-2) : []));
		}
	}, [config, step, messages]);

	return (
		<div className="bg-gray-100">
			<div
				className={cn('mx-auto h-dvh max-w-screen-lg bg-gray-50', {
					'bg-white': step === 1,
				})}
			>
				{step === 1 && (
					<section className="px-4 pt-4">
						{configLoading ? (
							<Skeleton.Button
								active
								size="large"
								className="!h-14 !w-36"
							/>
						) : (
							config && (
								<div className="flex items-center justify-between">
									<img
										src={config.chatbot_logo as string}
										alt="logo"
										className="!h-14"
									/>

									<img
										src="/images/chatbot/info-icon.svg"
										alt="info"
										role="button"
									/>
								</div>
							)
						)}
					</section>
				)}

				{step === 2 && (
					<div
						className={'flex items-center gap-2 p-4 text-white'}
						style={{
							backgroundColor: config?.chatbot_accent_color || colors.primary,
						}}
					>
						<img
							src="/images/chatbot/back.svg"
							alt="back"
							role="button"
							onClick={() => setStep(1)}
						/>
						<div className="font-bold">{config?.name}</div>
					</div>
				)}

				{step === 1 && (
					<div className="px-4">
						<Divider className="!my-4" />
					</div>
				)}

				<div className="h-auto overflow-y-auto">
					<div
						className={cn('flex h-full flex-col gap-2', {
							'gap-0': step === 1,
						})}
					>
						<div
							className={cn('h-[calc(100dvh-9rem)] overflow-hidden', {
								'h-[calc(100dvh-12.1rem)] bg-gray-50': step === 1,
							})}
						>
							{step === 1 && (
								<section>
									<div className="bg-white px-4">
										<h3 className="text-lg font-medium lg:text-xl">Hi There,</h3>

										<h2 className="text-xl font-bold">How can we help you?</h2>

										<div className="pt-4" />

										<div className={'relative w-full'}>
											<input
												className={'w-full rounded-full bg-gray-100 px-4 py-3 outline-none'}
												placeholder={'Search for help'}
												onChange={(e) => {
													const filtered = config?.chatbot_faq?.filter((faq) =>
														faq.question.toLowerCase().includes(e.target.value.toLowerCase()),
													);
													setFilteredFAQ(filtered || []);
												}}
											/>

											<img
												src="/images/chatbot/search.svg"
												alt={'search'}
												className={'absolute right-5 top-1/2 -translate-y-1/2 transform'}
											/>
										</div>

										<div className="pt-4" />
									</div>

									<div className="p-4">
										<h2 className="text-lg font-medium lg:text-xl">Quick Chat</h2>

										<div className="pt-2" />

										{configLoading ? (
											<div className="flex flex-col gap-3">
												{Array.from({ length: 5 }).map((_, index) => (
													<Skeleton.Button
														key={index}
														active
														size="large"
														className="!h-12"
														block
													/>
												))}
											</div>
										) : filteredFAQ && filteredFAQ.length > 0 ? (
											<div className="h-[calc(100dvh-23.9rem)] overflow-y-auto">
												{filteredFAQ.map((faq, index) => (
													<div
														key={index}
														className="mb-3 flex items-center justify-between rounded-xl bg-white px-4 py-3"
														role="button"
														onClick={() => {
															setStep(2);
															setMessages((prev) => [
																...prev,
																{
																	role: 'user',
																	content: faq.question,
																},
															]);
															sendMessage(faq.question);
														}}
													>
														<div className="text-lg font-medium">{faq.question}</div>
														<div>
															<CaretRightOutlined className="text-lg" />
														</div>
													</div>
												))}
											</div>
										) : (
											<div>No FAQs found.</div>
										)}
									</div>
								</section>
							)}

							{step === 2 && (
								<div
									className="h-full overflow-y-auto"
									ref={messagesEndRef}
								>
									<div className="space-y-4 pb-1 pt-3">
										{messages.map((message, index) => (
											<div
												key={index}
												className={`flex gap-2 px-4 ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}
											>
												<div className="space-y-2">
													{message.role === 'assistant' ? (
														<div className="flex items-center gap-1.5 text-sm capitalize text-gray-700">
															<div>
																<img
																	src="/images/chatbot/puretalk-icon.svg"
																	alt="puretalk-icon"
																/>
															</div>
															<div className="font-semibold">{config?.name}</div>
														</div>
													) : null}

													<div
														className={`rounded-lg p-2`}
														style={{
															backgroundColor:
																message.role === 'user' ? config?.chatbot_accent_color || colors.primary : '#e5e7eb',
															color: message.role === 'user' ? 'white' : 'black',
														}}
													>
														{message.content}
													</div>
												</div>
											</div>
										))}
										{assistantMessageStream && (
											<div className={`flex justify-start gap-1.5 px-4`}>
												<div className="space-y-2">
													<div className="flex items-center gap-2 text-sm capitalize text-gray-700">
														<div>
															<img
																src="/images/chatbot/puretalk-icon.svg"
																alt="puretalk-icon"
															/>
														</div>
														<div className="font-semibold">{config?.name}</div>
													</div>
													<div className={`rounded-lg bg-gray-200 p-2`}>{assistantMessageStream}</div>
												</div>
											</div>
										)}
									</div>

									{followUpQuestions.length > 0 && (
										<div className="space-y-2 px-4 pt-2">
											{followUpQuestions.map((question, index) => (
												<div
													key={index}
													className="flex w-fit rounded-lg border border-primary bg-white p-2"
													role="button"
													onClick={() => {
														setMessages((prev) => [
															...prev,
															{
																role: 'user',
																content: question,
															},
														]);
														sendMessage(question);
													}}
												>
													<div className="text-base font-medium text-primary">{question}</div>
												</div>
											))}
										</div>
									)}
								</div>
							)}
						</div>

						<div className="mt-auto">
							{step === 1 && (
								<div
									className="bg-gray-50 p-4"
									role="button"
									onClick={() => setStep(2)}
								>
									<div className="flex items-center justify-between rounded-full bg-white p-4">
										<div className="text-base font-medium">Chat with us</div>
										<div>
											<CaretRightOutlined className="!text-lg !text-primary" />
										</div>
									</div>
								</div>
							)}

							{step === 2 && (
								<Fragment>
									<div className="border-b shadow" />

									<Form
										onFinish={(values: { message: string }) => {
											if (!values.message) return;
											setMessages((prev) => [
												...prev,
												{
													role: 'user',
													content: values.message,
												},
											]);

											// send to socket
											sendMessage(values.message);

											// reset form
											send_data_form.resetFields();
										}}
										className="w-full p-4"
										form={send_data_form}
									>
										<div className="flex items-center justify-between gap-4">
											<div className="flex-1">
												<Form.Item
													name="message"
													className="mb-0"
												>
													<Input
														placeholder="Write a message..."
														className="w-full rounded-full [&>input]:placeholder-gray-400"
														size="large"
														autoFocus
														styles={{
															affixWrapper: {
																backgroundColor: 'white',
																color: 'black',
															},
														}}
														suffix={
															<section
																ref={emojiPickerRef}
																className="relative"
															>
																<img
																	src="/images/chatbot/emoji-picker.svg"
																	alt="emoji-picker"
																	role="button"
																	onClick={() => setOpenEmojiPicker(!openEmojiPicker)}
																/>

																<EmojiPicker
																	open={openEmojiPicker}
																	previewConfig={{ showPreview: false }}
																	searchDisabled
																	width={300}
																	style={{
																		position: 'absolute',
																		bottom: 45,
																		right: '0',
																	}}
																	lazyLoadEmojis
																	onEmojiClick={(event) => {
																		send_data_form.setFieldsValue({
																			message: send_data_form.getFieldValue('message')
																				? send_data_form.getFieldValue('message') + event.emoji
																				: event.emoji,
																		});
																	}}
																/>
															</section>
														}
													/>
												</Form.Item>
											</div>
										</div>
									</Form>
								</Fragment>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChatBot;
