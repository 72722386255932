import { useQuery } from '@tanstack/react-query';
import { Card, Select } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';
import axios from '../../config/axios';
import Loader from '../Loader';

const SentimentChart = () => {
	const [dateRange, setDateRange] = useState<[string, string]>(() => [
		dayjs().subtract(7, 'days').startOf('day').format('YYYY-MM-DD'),
		dayjs().endOf('day').format('YYYY-MM-DD'),
	]);

	const [activeIndex, setActiveIndex] = useState(0);
	const onPieEnter = useCallback(
		(_: string, index: number) => {
			setActiveIndex(index);
		},
		[setActiveIndex],
	);

	// get the sentiment data
	const { data, isLoading } = useQuery({
		queryKey: ['sentiment', dateRange],
		queryFn: async () => {
			const { data } = await axios.get('/reports/sentiment-report', {
				withCredentials: true,
				params: {
					start: dateRange[0],
					end: dateRange[1],
				},
			});
			return [
				{ name: 'Positive', value: data.results.positive },
				{ name: 'Neutral', value: data.results.neutral },
				{ name: 'Negative', value: data.results.negative },
				{ name: 'Not Available', value: data.results.not_available },
				{ name: 'Generating', value: data.results.generating },
			];
		},
	});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const renderActiveShape = (props: any) => {
		const RADIAN = Math.PI / 180;
		const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
		const sin = Math.sin(-RADIAN * midAngle);
		const cos = Math.cos(-RADIAN * midAngle);
		const sx = cx + (outerRadius + 10) * cos;
		const sy = cy + (outerRadius + 10) * sin;
		const mx = cx + (outerRadius + 30) * cos;
		const my = cy + (outerRadius + 30) * sin;
		const ex = mx + (cos >= 0 ? 1 : -1) * 22;
		const ey = my;
		const textAnchor = cos >= 0 ? 'start' : 'end';

		return (
			<g>
				<text
					x={cx}
					y={cy}
					dy={8}
					textAnchor="middle"
					fill={fill}
				>
					{payload.name}
				</text>
				<Sector
					cx={cx}
					cy={cy}
					innerRadius={innerRadius}
					outerRadius={outerRadius}
					startAngle={startAngle}
					endAngle={endAngle}
					fill={fill}
				/>
				<Sector
					cx={cx}
					cy={cy}
					startAngle={startAngle}
					endAngle={endAngle}
					innerRadius={outerRadius + 6}
					outerRadius={outerRadius + 10}
					fill={fill}
				/>
				<path
					d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
					stroke={fill}
					fill="none"
				/>
				<circle
					cx={ex}
					cy={ey}
					r={2}
					fill={fill}
					stroke="none"
				/>
				<text
					x={ex + (cos >= 0 ? 1 : -1) * 12}
					y={ey}
					textAnchor={textAnchor}
					fill="#666"
				>{`${payload.name} (${value})`}</text>
				<text
					x={ex + (cos >= 0 ? 1 : -1) * 12}
					y={ey}
					dy={18}
					textAnchor={textAnchor}
					fill="#999"
				>
					{`(${(percent * 100).toFixed(2)}%)`}
				</text>
			</g>
		);
	};

	return (
		<Card
			className="h-full"
			styles={{ body: { padding: 15 } }}
		>
			<div className="flex items-center justify-between gap-4">
				<h2 className="text-xl font-semibold text-black-7 dark:text-white">Call Sentiment Analysis</h2>
				<Select
					options={[
						{
							label: 'Last 7 days',
							value: 'last-7-days',
						},
						{
							label: 'Last 30 days',
							value: 'last-30-days',
						},
						{
							label: 'Last 90 days',
							value: 'last-90-days',
						},
					]}
					placeholder="Select date range"
					className="w-40"
					defaultValue={'last-7-days'}
					onChange={(value) => {
						switch (value) {
							case 'last-7-days':
								setDateRange([
									dayjs().subtract(7, 'days').startOf('day').format('YYYY-MM-DD'),
									dayjs().endOf('day').format('YYYY-MM-DD'),
								]);
								break;
							case 'last-30-days':
								setDateRange([
									dayjs().subtract(30, 'days').startOf('day').format('YYYY-MM-DD'),
									dayjs().endOf('day').format('YYYY-MM-DD'),
								]);
								break;
							case 'last-90-days':
								setDateRange([
									dayjs().subtract(90, 'days').startOf('day').format('YYYY-MM-DD'),
									dayjs().endOf('day').format('YYYY-MM-DD'),
								]);
								break;
							default:
								break;
						}
					}}
				/>
			</div>

			{isLoading && (
				<div className="flex h-72 items-center justify-center">
					<Loader />
				</div>
			)}

			{!isLoading && data && data.every((item) => item.value === 0) && (
				<div className="flex h-72 items-center justify-center">No data available.</div>
			)}

			{!isLoading && data && data.some((item) => item.value > 0) && (
				<ResponsiveContainer
					width="100%"
					height={300}
				>
					<PieChart>
						<Pie
							activeIndex={activeIndex}
							activeShape={renderActiveShape}
							data={data}
							cx="50%"
							cy="50%"
							innerRadius={60}
							outerRadius={80}
							fill="#9a88ff"
							dataKey="value"
							onMouseEnter={onPieEnter}
						/>
					</PieChart>
				</ResponsiveContainer>
			)}
		</Card>
	);
};

export default SentimentChart;
