import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type ThemeState = {
	is_dark_mode: boolean;
	toggleDarkMode: () => void;
};

export const useThemeStore = create<ThemeState>()(
	persist(
		(set) => ({
			is_dark_mode: false,
			toggleDarkMode: () => set((state) => ({ is_dark_mode: !state.is_dark_mode })),
		}),
		{
			name: 'puretalk-theme',
			storage: createJSONStorage(() => localStorage),
		},
	),
);
