import { Button, Calendar, Card, Input, Modal } from 'antd';
import type { Dayjs } from 'dayjs';
import { useState } from 'react';
import dayjs from '../config/dayjs';
import AppLayout from '../layouts/app.layout';

const CalendarSync = () => {
	const [selectedDate, setSelectedDate] = useState<string | null>(null);
	const [addEventModalOpen, setAddEventModalOpen] = useState(false);

	const renderEvents = (value: Dayjs) => {
		if (value.month() !== new Date().getMonth()) return null;
		switch (value.date()) {
			case 1:
			case 5:
				return (
					<div
						className="truncate rounded-md bg-primary px-2 py-0.5 text-white"
						title="Meeting with Developer Team"
					>
						Meeting with Developer Team
					</div>
				);
			case 8:
			case 12:
				return (
					<div
						className="truncate rounded-md bg-red-600 px-2 py-0.5 text-white"
						title="Meeting with Designer Team"
					>
						Meeting with Designer Team
					</div>
				);
			case 10:
			case 19:
			case 23:
				return (
					<div className="space-y-1">
						<div
							className="truncate rounded-md bg-cyan-500 px-2 py-0.5 text-white"
							title="Meeting with Marketing Team"
						>
							Meeting with Marketing Team
						</div>
						<div
							className="truncate rounded-md bg-primary px-2 py-0.5 text-white"
							title="Social SEO"
						>
							Social SEO
						</div>
					</div>
				);
			default:
				return null;
		}
	};

	return (
		<>
			<AppLayout
				title="Calendar Sync"
				subtitle="Add Events and Sync Calender"
			>
				<div className="h-[calc(100dvh-9rem)] overflow-hidden">
					<Card
						className="h-full overflow-y-auto"
						styles={{ body: { padding: 15 } }}
					>
						<Calendar
							onSelect={(date, selectInfo) => {
								if (selectInfo.source === 'date') {
									setSelectedDate(dayjs(date).format('YYYY-MM-DD'));
									setAddEventModalOpen(true);
								}
							}}
							cellRender={(value: Dayjs) => renderEvents(value)}
						/>
					</Card>
				</div>
			</AppLayout>

			{selectedDate && (
				<Modal
					title={<div className="text-xl">Add New Event</div>}
					open={addEventModalOpen}
					onCancel={() => setAddEventModalOpen(false)}
					footer={null}
					centered
					destroyOnClose
				>
					<div className="flex flex-col gap-3">
						<Input
							placeholder="Event Title"
							size="large"
						/>
						<Input
							value={selectedDate}
							readOnly
							size="large"
						/>
					</div>
					<div className="flex justify-end gap-2 pt-4">
						<Button
							danger
							size="large"
							onClick={() => setAddEventModalOpen(false)}
						>
							Cancel
						</Button>
						<Button
							type="primary"
							size="large"
						>
							Add New Event
						</Button>
					</div>
				</Modal>
			)}
		</>
	);
};

export default CalendarSync;
