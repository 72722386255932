import { Avatar, Button, Switch } from 'antd';
import { FC, Fragment, ReactNode, useContext, useState } from 'react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import SidebarDrawer from '../components/layouts/sidebar-drawer';
import { cn } from '../config/cn';
import { AuthContext } from '../context/auth.context.tsx';
import { SidebarContext } from '../context/sidebar.context.tsx';
import { useThemeStore } from '../store/theme';
import SidebarBottom from './sidebar-bottom';

type Props = {
	title: string;
	subtitle: string;
	children: ReactNode;
};

const AppLayout: FC<Props> = (props) => {
	const location = useLocation();
	const { isCollapsed, setIsCollapsed } = useContext(SidebarContext);
	const { user } = useContext(AuthContext);
	const { is_dark_mode, toggleDarkMode } = useThemeStore();
	const { width } = useWindowSize();
	const [sidebarDrawerOpen, setSidebarDrawerOpen] = useState(false);

	return (
		<Fragment>
			<div className="flex min-h-dvh">
				<div
					className={cn('sticky top-0 z-20 h-dvh bg-gray-50 transition-all dark:bg-dark-sidebar', {
						'w-24': isCollapsed,
						'w-72': !isCollapsed,
						hidden: width && width < 1100,
					})}
				>
					<div
						className={cn('relative flex h-24 items-center px-4 md:px-6', {
							'justify-center': isCollapsed,
						})}
					>
						{isCollapsed && user ? (
							<div className="flex items-center justify-center">
								{user.dp ? (
									<Avatar
										shape="circle"
										size="large"
										src={user.dp}
									/>
								) : (
									<Avatar>{user.name.charAt(0).toUpperCase()}</Avatar>
								)}
							</div>
						) : user ? (
							<div className="flex w-full items-center gap-2 rounded-md border px-3 py-2 dark:border-[#FFFFFF0D] dark:bg-[#1F1D2B]">
								<div>
									{user.dp ? (
										<Avatar
											shape="circle"
											size="large"
											src={user.dp}
										/>
									) : (
										<Avatar>{user.name.charAt(0).toUpperCase()}</Avatar>
									)}
								</div>

								<div>
									<p
										className="max-w-40 truncate text-base font-bold dark:text-white"
										title={user.name}
									>
										{user.name}
									</p>
									<p
										className="max-w-40 truncate text-sm text-gray-400"
										title={user.email}
									>
										{user.email}
									</p>
								</div>
							</div>
						) : null}

						{/*sidebar collapse*/}
						<div
							className="absolute -right-3 top-10 dark:-right-3.5"
							role={'button'}
							onClick={() => setIsCollapsed(!isCollapsed)}
						>
							<div className="flex items-center justify-center rounded-full border bg-white p-1.5 dark:border-primary dark:bg-primary dark:text-white">
								{isCollapsed ? <BsChevronRight size={13} /> : <BsChevronLeft size={13} />}
							</div>
						</div>
					</div>

					<div className="mx-5 -mt-px border-b dark:border-b-[#FFFFFF1A]" />

					<SidebarBottom />
				</div>
				<div className="relative flex-1 dark:bg-dark-bg">
					<div
						className={cn(
							'sticky top-0 z-10 flex h-20 items-center border-b bg-white min-[1100px]:h-24 dark:border-b-[#2d2b38] dark:bg-dark-bg',
							{
								'border-b-0': ['/dashboard'].includes(location.pathname),
							},
						)}
					>
						<div className="flex w-full items-center justify-between px-4 md:px-6">
							<div
								className="flex items-center gap-2 rounded-lg border p-2 min-[1100px]:hidden dark:border-[#2d2b38] dark:bg-[#1f1d2b] dark:text-white"
								onClick={() => {
									setSidebarDrawerOpen(true);
								}}
								role="button"
							>
								<Avatar
									shape="circle"
									src={user && user.dp}
									size="small"
								/>
								<img
									src={`/images/layout/menu-${is_dark_mode ? 'dark' : 'light'}.svg`}
									alt={`menu-${is_dark_mode ? 'dark' : 'light'}`}
								/>
							</div>
							<div className="hidden flex-col min-[1100px]:flex">
								<div className="w-fit text-2xl font-semibold text-black-7 dark:text-white">{props.title}</div>
								<div className="text-base font-medium text-gray-500 dark:text-gray-400">{props.subtitle}</div>
							</div>
							<div className="flex items-center gap-4">
								<div
									className="flex items-center gap-2"
									onClick={toggleDarkMode}
									role="button"
								>
									<Switch
										checked={is_dark_mode}
										className="hidden sm:block"
									/>
									<p className="hidden text-base font-semibold text-gray-400 sm:block dark:text-white">Dark Mode</p>
									<img
										src={`/images/layout/${is_dark_mode ? 'sun' : 'moon'}.svg`}
										alt={is_dark_mode ? 'sun' : 'moon'}
										className="size-5"
									/>
								</div>

								<Link
									to={'https://docs.puretalk.ai/introduction'}
									target={'_blank'}
								>
									<Button
										icon={
											<img
												src={`/images/layout/documentation-${is_dark_mode ? 'white' : 'black'}-icon.svg`}
												alt={is_dark_mode ? 'documentation-white-icon' : 'documentation-black-icon'}
												className="size-5"
											/>
										}
										size="large"
										className="text-base font-semibold text-black-7 dark:border-[#FFFFFF0D] dark:text-white"
									>
										<span className="!hidden sm:!block">Documentation</span>
									</Button>
								</Link>
							</div>
						</div>
					</div>

					{/*children*/}
					<div
						className={cn('px-4', {
							'pt-4': ['/dashboard'].includes(location.pathname) === false,
						})}
					>
						<div className="pb-5 min-[1100px]:hidden">
							<div className="w-fit text-2xl font-semibold text-black-7 dark:text-white">{props.title}</div>
							<div className="text-base font-medium text-gray-500 dark:text-gray-400">{props.subtitle}</div>
						</div>
						{props.children}
					</div>
				</div>
			</div>

			{/* sidebar drawer */}
			<SidebarDrawer
				open={sidebarDrawerOpen}
				close={() => setSidebarDrawerOpen(false)}
			/>
		</Fragment>
	);
};

export default AppLayout;
