import { Card } from 'antd';
import { FC } from 'react';
import { useThemeStore } from '../../store/theme.ts';
import { CallLog } from '../../views/call-logs.tsx';

type Props = {
	history: CallLog;
};

const CallTranscriptCard: FC<Props> = ({ history }) => {
	const { is_dark_mode } = useThemeStore();

	const downloadTranscript = (transcript: string, call_id: string) => {
		// Create a new Blob object from transcript
		const file = new Blob([transcript], { type: 'text/plain' });

		// Create a URL for the Blob object
		const url = URL.createObjectURL(file);

		// Create a new 'a' element
		const element = document.createElement('a');
		element.href = url;

		// Set the name of the file
		element.download = `transcript-${call_id}.txt`;

		// Append the 'a' element to the body (required for Firefox)
		document.body.appendChild(element);

		// Simulate a click on the 'a' element
		element.click();

		// Remove the 'a' element from the body
		document.body.removeChild(element);
	};

	return (
		<Card styles={{ body: { padding: 15 } }}>
			<div className="flex items-center justify-between gap-3">
				<h2 className="text-xl font-semibold text-black-7 dark:text-white">Transcript</h2>

				{history.transcript_object && history.transcript_object.length > 0 && (
					<img
						src={is_dark_mode ? '/images/call-logs/download-icon-dark.svg' : '/images/call-logs/download-icon.svg'}
						alt="download-icon"
						onClick={() => downloadTranscript(history.transcript, history.call_id)}
						title={'Download Transcript'}
						role="button"
					/>
				)}
			</div>

			<div className="pt-3" />

			{history.transcript_object && history.transcript_object.length > 0 ? (
				<div className="space-y-3">
					{history.transcript_object.map((item, i) => (
						<div key={i}>
							{['user', 'assistant', 'function'].includes(item.role) && (
								<>
									{item.role === 'function' ? (
										<div className={'text-base font-medium text-primary'}>Tool Invocation: {item.name}</div>
									) : (
										<div className="space-y-0.5">
											<div className="text-base font-medium capitalize text-black-7 dark:text-white">{item.role}</div>
											<div className="text-sm font-medium text-black-3">{item.content}</div>
										</div>
									)}
								</>
							)}
						</div>
					))}
				</div>
			) : (
				<div className="text-base text-black-7 dark:text-white">No transcript found.</div>
			)}
		</Card>
	);
};

export default CallTranscriptCard;
