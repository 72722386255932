import { useMutation } from '@tanstack/react-query';
import { Alert, Button, Form, Input } from 'antd';
import { AxiosError } from 'axios';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../config/axios.ts';
import AuthLayout from '../layouts/auth.layout.tsx';

type FieldType = {
	email: string;
	otp: string;
	hashed_otp: string;
	new_password: string;
};

const ForgotPassword = () => {
	const navigate = useNavigate();
	const [values, setValues] = useState<FieldType | null>(null);
	const [hashedOtp, setHashedOtp] = useState('');
	const [step, setStep] = useState(2);
	const [alertMessage, setAlertMessage] = useState<string | null>(null);

	// create a counter for the timer to resend the code
	const [counter, setCounter] = useState(0);

	// resend code timer
	useEffect(() => {
		const timer = setTimeout(() => {
			if (counter > 0) {
				setCounter(counter - 1);
			}
		}, 1000);
		return () => clearTimeout(timer);
	}, [counter]);

	// get otp by email mutation
	const { mutate: getOTP, isPending: getOTPPending } = useMutation({
		mutationFn: async (email: string) => {
			const { data } = await axios.post('/users/send-otp-email?reset_check=true', { email });
			return data;
		},
		onSuccess: (data) => {
			setHashedOtp(data.results.hashed_otp);
			setStep(2);
			setAlertMessage(null);
		},
		onError: (error) => {
			if (error instanceof AxiosError && error.response && error.response.status === 429) {
				return setAlertMessage(error.response.data.detail);
			}

			if (error instanceof AxiosError && error.response && error.response.status === 404) {
				return setAlertMessage(error.response.data.msg);
			}

			setAlertMessage('Internal server error');
		},
	});

	// reset password mutation
	const { mutate: resetPassword, isPending: resetPasswordPending } = useMutation({
		mutationFn: async (values: FieldType) => {
			await axios.post('/users/reset-password', values);
			return true;
		},
		onSuccess: () => {
			toast.success('Password reset successful. Please login!');
			navigate('/login');
			setAlertMessage(null);
		},
		onError: (error: AxiosError<{ msg: string }>) => {
			if (error.response) {
				setAlertMessage(error.response.data.msg);
			}
		},
	});

	// hide the error message after 3 seconds
	useEffect(() => {
		const timer = setTimeout(() => {
			setAlertMessage(null);
		}, 3000);
		return () => clearTimeout(timer);
	}, [alertMessage]);

	return (
		<AuthLayout>
			<div className="h-full">
				<div className="flex flex-col items-center justify-center">
					<Link to={'/'}>
						<img
							src="/images/logo.png"
							alt="logo"
							className="h-12"
						/>
					</Link>

					<div className="pt-10" />

					{step === 1 ? (
						<h1 className="text-center text-3xl font-bold text-black-7 sm:text-4xl dark:text-white">Forgot Your Password?</h1>
					) : (
						<h1 className="text-center text-3xl font-bold text-black-7 sm:text-4xl dark:text-white">Verify your email</h1>
					)}

					<div className="pt-4" />

					{step === 1 ? (
						<p className="text-center text-base font-medium text-gray-400 dark:text-gray-200">
							Enter your email address and we'll send you an OTP
						</p>
					) : (
						values && (
							<p className="text-center text-base font-medium text-gray-400 dark:text-gray-200">
								A verification code was sent to <span className={'text-primary'}>{values.email}</span>
							</p>
						)
					)}

					<div className="pt-3" />

					{alertMessage && (
						<Alert
							message={alertMessage}
							type="error"
							className="w-full"
							showIcon
						/>
					)}

					{step === 2 && <div className="pt-3" />}

					{step === 1 && (
						<Form
							onFinish={(values) => {
								getOTP(values.email);
								setValues(values);
							}}
							layout="vertical"
							className="w-full pt-4"
							requiredMark={false}
						>
							<Form.Item<FieldType>
								label={<div className="pl-1 text-base font-medium text-black-7 dark:text-white">Email</div>}
								name="email"
							>
								<Input
									size="large"
									placeholder="Enter your email"
									required
									type="email"
									className="h-11"
								/>
							</Form.Item>

							<Form.Item className="mb-0">
								<Button
									htmlType="submit"
									block
									size="large"
									shape="round"
									className="h-11 font-bold"
									type="primary"
									loading={getOTPPending}
									disabled={getOTPPending}
								>
									Send Verification Code
								</Button>
							</Form.Item>
						</Form>
					)}

					{step === 2 && (
						<Form
							onFinish={(val) => {
								if (values) {
									val.email = values.email;
								}
								val.hashed_otp = hashedOtp;
								resetPassword(val);
							}}
							layout="vertical"
							requiredMark={false}
							className="w-full"
						>
							<Form.Item
								label={<div className="pl-1 text-base font-medium text-black-7 dark:text-white">Verification Code</div>}
								name="otp"
								className="mb-5"
							>
								<Input
									size="large"
									placeholder="Enter verification code"
									required
									type="number"
									className="h-11"
									inputMode="numeric"
								/>
							</Form.Item>

							<Form.Item
								label={<div className="pl-1 text-base font-medium text-black-7 dark:text-white">New Password</div>}
								name="new_password"
								className="mb-5"
							>
								<Input
									size="large"
									placeholder="Enter new password"
									required
									type="password"
									className="h-11"
								/>
							</Form.Item>

							<Form.Item
								label={<div className="pl-1 text-base font-medium text-black-7 dark:text-white">Confirm New Password</div>}
								name="confirm_new_password"
								className="mb-5"
							>
								<Input
									size="large"
									placeholder="Confirm new password"
									required
									type="password"
									className="h-11"
								/>
							</Form.Item>

							<div className="flex flex-wrap items-center justify-between gap-2">
								<div className="text-base font-medium text-black-7 dark:text-white">
									Can’t find the code? Check your spam folder or
								</div>

								{counter > 0 ? (
									<motion.div
										key={counter}
										className={'text-base font-medium text-black-7 dark:text-white'}
										initial={{ opacity: 0.5 }}
										animate={{ opacity: 1 }}
										exit={{ opacity: 0.5 }}
										transition={{ duration: 1 }}
									>
										00:{counter.toString().padStart(2, '0')}
									</motion.div>
								) : (
									<button
										className={'text-base font-medium text-[#6E5DED]'}
										type="button"
										onClick={() => {
											setCounter(59);
											if (values) {
												getOTP(values.email);
											}
										}}
									>
										Resend Code
									</button>
								)}
							</div>

							<div className="pt-6" />

							<Form.Item className="mb-0">
								<Button
									htmlType="submit"
									block
									size="large"
									shape="round"
									className="h-11 font-bold"
									type="primary"
									loading={resetPasswordPending}
									disabled={resetPasswordPending}
								>
									Reset Password
								</Button>
							</Form.Item>
						</Form>
					)}

					<div className="pt-3" />

					<div className="text-center">
						<span className="txt-base text-gray-400 dark:text-gray-200">Remember your password?</span>
						<Link
							to={'/login'}
							className="ml-1 font-medium text-[#6E5DED]"
						>
							Sign in
						</Link>
					</div>
				</div>
			</div>
		</AuthLayout>
	);
};

export default ForgotPassword;
