import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
	return (
		<section className="flex h-dvh items-center justify-center dark:bg-dark-bg">
			<Result
				status="404"
				title="404"
				subTitle="Sorry, the page you visited does not exist."
				extra={
					<Link to={'/dashboard'}>
						<Button type="primary">Back Home</Button>
					</Link>
				}
			/>
		</section>
	);
};

export default NotFoundPage;
