import { Divider, Drawer } from 'antd';
import { Dispatch, FC, SetStateAction } from 'react';
import { cn } from '../../config/cn.ts';
import dayjs from '../../config/dayjs.ts';
import { CallLog } from '../../views/call-logs.tsx';
import CallAnalysisCard from './call-analysis-card.tsx';
import CallDurationCard from './call-duration-card.tsx';
import CallInformationCard from './call-information-card.tsx';
import CallTranscriptCard from './call-transcript-card.tsx';
import LogDetailsTop from './log-details-top.tsx';

type Props = {
	open: boolean;
	close: () => void;
	history: CallLog;
	wavePercent: number;
	setWavePercent: Dispatch<SetStateAction<number>>;
};

const CallLogsDrawer: FC<Props> = (props) => {
	return (
		<Drawer
			open={props.open}
			onClose={props.close}
			destroyOnClose={true}
			title={`${dayjs(props.history.start_time).format('YYYY-MM-DD hh:mm')} - ${['twilio', 'telnyx'].includes(props.history.protocol) ? 'VOIP Call' : 'Web Call'}`}
			size={'large'}
		>
			<LogDetailsTop
				history={props.history}
				wavePercent={props.wavePercent}
				setWavePercent={props.setWavePercent}
			/>

			<Divider />

			<CallInformationCard history={props.history} />

			<div className="pt-6" />

			<CallDurationCard history={props.history} />

			<div
				className={cn('pt-6', {
					'pt-0': !props.history.call_analysis,
				})}
			/>

			<CallAnalysisCard history={props.history} />

			<div className="pt-6" />

			<CallTranscriptCard history={props.history} />
		</Drawer>
	);
};

export default CallLogsDrawer;
