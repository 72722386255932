import { FC } from 'react';
import { Card } from 'antd';
import { ChatLog } from '../../views/chat-logs.tsx';
import { cn } from '../../config/cn.ts';

type Props = {
	history: ChatLog;
};

const ChatCountCard: FC<Props> = ({ history }) => {
	return (
		<>
			<Card styles={{ body: { padding: 16 } }}>
				<h2 className="text-xl font-semibold text-black-7 dark:text-white">Chat Counts</h2>

				<div className="pt-3" />

				<div className="space-y-3">
					{[
						{
							label: 'User Message',
							value: history.user_message_count,
						},
						{
							label: 'Assistant Message',
							value: history.assistant_message_count,
						},
						{
							label: 'Tool Invocation',
							value: history.tool_invocation_count,
						},
					].map((item, i) => (
						<div
							key={i}
							className="space-y-0.5"
						>
							<div className="text-base font-medium text-black-7 dark:text-white">{item.label}</div>
							<div className={cn('text-sm font-medium text-black-3')}>{item.value}</div>
						</div>
					))}
				</div>
			</Card>
		</>
	);
};

export default ChatCountCard;
