import { Edge, Node } from 'reactflow';
import { LlmStates } from '../../types/ai-assistant.types';

export const generateNodesAndEdges = (data: LlmStates[], parentId: string | null = null, level = 0, overallIndex = 0) => {
	let nodes: Node[] = [];
	let edges: Edge[] = [];

	for (let i = 0; i < data.length; i++) {
		const item = data[i];
		const node: Node = {
			id: item.state_id,
			position: {
				x: item.position.x,
				y: item.position.y,
			},
			data: { item, initial: overallIndex === 0 },
			type: 'CustomNode',
		};
		nodes.push(node);
		overallIndex++;

		if (parentId) {
			const edge: Edge = {
				id: `${parentId}-${item.state_id}`,
				source: parentId,
				target: item.state_id,
				label: 'Edge',
				animated: true,
				type: 'CustomEdge',
				data: item,
			};
			edges.push(edge);
		}

		if (item.children) {
			const childrenNodesAndEdges = generateNodesAndEdges(item.children, item.state_id, level + 1, overallIndex);
			nodes = [...nodes, ...childrenNodesAndEdges.nodes];
			edges = [...edges, ...childrenNodesAndEdges.edges];
			overallIndex += childrenNodesAndEdges.nodes.length;
		}
	}

	return { nodes, edges };
};

export const formatDataForAPI = (nodes: Node[], edges: Edge[]) => {
	// Create a map of nodeId to node data for quick lookup
	const nodeMap = new Map();
	nodes.forEach((node) => {
		nodeMap.set(node.id, {
			name: node.data.item.name,
			description: node.data.item.description,
			prompt: node.data.item.prompt,
			parameters: node.data.item.parameters,
			children: [],
			position: node.position,
			state_id: node.data.item.state_id.length > 6 ? node.data.item.state_id : null,
			tools: node.data.item.tools,
		});
	});

	// Create a map to store parent-child relationships
	const childrenMap = new Map();
	edges.forEach((edge) => {
		if (!childrenMap.has(edge.source)) {
			childrenMap.set(edge.source, []);
		}
		childrenMap.get(edge.source).push(edge.target);
	});

	// Recursively build the tree structure
	function buildTree(nodeId: string) {
		const node = nodeMap.get(nodeId);
		const children = childrenMap.get(nodeId) || [];
		node.children = children.map((childId: string) => buildTree(childId));
		return node;
	}

	// Find the initial node
	const initialNode = nodes.find((node) => node.data.initial);

	// Build the tree starting from the initial node
	let tree;
	if (initialNode) {
		tree = buildTree(initialNode.id);
	}

	// Format the tree according to the API's requirements
	return {
		llm_states: tree ? [tree] : [],
	};
};
