import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import CallsAnalytics from '../components/dashboard/calls-analytics';
import ChatsAnalytics from '../components/dashboard/chats-analytics';
import { cn } from '../config/cn';
import AppLayout from '../layouts/app.layout';

type Tab = {
	label: string;
	value: string;
	activeIcon: string;
	inactiveIcon: string;
};

const tabs: Tab[] = [
	{
		label: 'Calls',
		value: 'calls',
		activeIcon: '/images/dashboard/calls-active-icon.svg',
		inactiveIcon: '/images/dashboard/calls-inactive-icon.svg',
	},
	{
		label: 'Chats',
		value: 'chats',
		activeIcon: '/images/dashboard/chats-active-icon.svg',
		inactiveIcon: '/images/dashboard/chats-inactive-icon.svg',
	},
];

const Dashboard = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [dateRange, setDateRange] = useState<[string, string]>(() => [dayjs().startOf('month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]);

	// set first page as default
	useEffect(() => {
		window.scrollTo(0, 0);
		if (!searchParams.get('page')) {
			setSearchParams({
				page: 'calls',
			});
		}
	}, [searchParams, setSearchParams]);

	return (
		<AppLayout
			title="Dashboard"
			subtitle="Manage your activities and view statistics here."
		>
			<div className="sticky top-20 z-10 flex items-center gap-3 border-b-2 bg-white min-[1100px]:top-24 dark:border-b-[#2d2b38] dark:bg-dark-bg">
				{tabs.map((tab, index) => (
					<div
						key={index}
						className={cn('relative flex items-center justify-between gap-4 rounded-md rounded-b-none px-3 py-2', {
							'transition-all duration-500': searchParams.get('page') === tab.value,
						})}
						onClick={() => {
							setSearchParams({
								page: tab.value,
							});
						}}
						role="button"
					>
						<div className="flex items-center gap-2">
							<div>
								{searchParams.get('page') === tab.value ? (
									<img
										src={tab.activeIcon}
										alt={tab.label}
										className="size-6"
									/>
								) : (
									<img
										src={tab.inactiveIcon}
										alt={tab.label}
										className="size-6"
									/>
								)}
							</div>
							<div
								className={cn('text-base font-semibold', {
									'text-link-active': searchParams.get('page') === tab.value,
									'text-black-7 dark:text-[#8a8990]': searchParams.get('page') !== tab.value,
								})}
							>
								{tab.label}
							</div>
						</div>

						{tab.value === searchParams.get('page') && (
							<motion.div
								className="absolute -bottom-0.5 left-0 right-0 h-0.5 bg-primary"
								layoutId="underline"
							/>
						)}
					</div>
				))}
				<div className="ml-auto hidden md:block">
					<DatePicker.RangePicker
						presets={[
							{ label: 'Last 7 Days', value: [dayjs().add(-7, 'd'), dayjs()] },
							{ label: 'Last 14 Days', value: [dayjs().add(-14, 'd'), dayjs()] },
							{ label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
							{ label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
						]}
						format="YYYY-MM-DD"
						onChange={(_, dateStrings) => {
							setDateRange([dateStrings[0], dateStrings[1]]);
						}}
						allowClear={false}
						inputReadOnly
						value={[dayjs(dateRange[0]), dayjs(dateRange[1])]}
						placement="bottomRight"
					/>
				</div>
			</div>

			<AnimatePresence mode="wait">
				<motion.div
					key={searchParams.get('page')}
					initial={{ y: 10, opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					exit={{ y: -10, opacity: 0 }}
					transition={{ duration: 0.2 }}
					className="pt-6"
				>
					{searchParams.get('page') === 'calls' && <CallsAnalytics dateRange={dateRange} />}
					{searchParams.get('page') === 'chats' && <ChatsAnalytics dateRange={dateRange} />}
				</motion.div>
			</AnimatePresence>
		</AppLayout>
	);
};

export default Dashboard;
